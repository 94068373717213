import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'


const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              
              <i style={{paddingTop: "1px"}} className="fa fa-phone"></i>{"    Ayuda telefónica: "}
              <a href="tel:6003001515"> 600 300 1515</a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              <a href="mailto:erasmoescala@liche.cl">erasmoescala@liche.cl</a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className="off-canvas-widget-social">
        {/* <a href="//twitter.com" title="Twitter">
          <i className="fa fa-twitter"></i>
        </a>
        <a href="//instagram.com" title="Instagram">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="//facebook.com" title="Facebook">
          <i className="fa fa-facebook"></i>
        </a>
        <a href="//pinterest.com" title="Pinterest">
          <i className="fa fa-pinterest"></i>
        </a> */}
                        <a target="_blank"  rel="noreferrer" href="https://www.facebook.com/LigaChilenacontralaEpilepsiaOficial" className="icono"> <FontAwesomeIcon icon={faFacebook} className="icono" /></a>   
        <a target="_blank"  rel="noreferrer" href="https://twitter.com/LigaEpilepsia" className="icono"><FontAwesomeIcon icon={faXTwitter} className="icono" /></a>
        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ligaepilepsia/" className="icono"><FontAwesomeIcon icon={faInstagram} className="icono"/></a>
        <a target="_blank"  rel="noreferrer" href="https://www.youtube.com/channel/UC4F78IJRTuh3kcRtgzjGUDg" className="icono"><FontAwesomeIcon icon={faYoutube} className="icono" /></a>
        <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@ligaepilepsia" className="icono"><FontAwesomeIcon  icon={faTiktok} className="icono" /></a>
       
      </div>
      {/*Off Canvas Widget Social End*/}

    </div>
  );
};

export default MobileWidgets;
