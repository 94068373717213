import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";

const Logo = ({ imageUrl, logoClass }) => {
  return (
    <div className={clsx(logoClass) + " text-center"}>
      <Link to={process.env.PUBLIC_URL + "/"} >
        <img alt="" src={imageUrl} className="text-center"/>
      
        {/* <img alt="" src={"https://www.ligaepilepsia.cl/wp-content/uploads/2021/11/70anos-BLANCO-4.png"} height={90} /> */}

     
      </Link>
      
    </div>
  );
};

Logo.propTypes = {
  imageUrl: PropTypes.string,
  logoClass: PropTypes.string
};

export default Logo;
