import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDiscountPrice } from "../../../helpers/product";
import { deleteAllFromCart, deleteFromCart, updateCart } from "../../../store/slices/cart-slice"
import { useEffect, useState } from "react";
import { updatePedidoEnCursoFirebase } from "../../../firebase/firebase-methods";
import { updateCarroUser } from "../../../store/slices/user-slice";
import { validaStock } from "../../../helpers/validators";
import { Modal } from "react-bootstrap";

const MenuCart = () => {
  const dispatch = useDispatch();
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { modalidadSeleccionada } = useSelector((state) => state.modalidad);
  const {productsFull} = useSelector((state)=> state.product)

  const [modalidadStore, setModalidadStore] = useState(modalidadSeleccionada)
  let cartTotalPrice = 0;
  // console.log("carro desde menucart")
  // console.log(cartItems)
  const [totalCarro, setTotalCarro] = useState(0)

  useEffect(() => {
    //console.log("se monta menu cart")
    //console.log(cartItems)
    //asigna a totalCarro el total del carro multiplicando cantidad por precioFinal
    let total = 0
    cartItems.forEach(item => {
      total += item.cantidad * item.precioFinal
    });
    setTotalCarro(total)

    // const updateCarroFirebase = async () => {
    //   //console.log("se actualiza cartitems")
    //   try {
    //     //console.log("se ejecuta updateCarroFirebase")
    //   dispatch(updateCarroUser(cartItems));
    //   } catch (error) {
    //     //console.log(error)
    //   }
     

    //   // if (user) {
    //   //   //console.log("uid")
    //   //   //console.log(user.uid)
    //   //   const resultado = await updatePedidoEnCursoFirebase(user.uid, cartItems)
    //   //   //console.log(resultado)

    //   // }
    // }

    // updateCarroFirebase()
    return () => {
      //console.log("se desmonta menu cart")
    };
  }, [cartItems])

  // useEffect(() => {
  //   //console.log("se monta menu cart modalidad seleccionada")
  //   //console.log(modalidadSeleccionada)
  //   if (modalidadSeleccionada != modalidadStore) {
  //     //console.log("modalidad seleccionada cambió")
  //     dispatch(updateCart([]))


  //   }
  //   return;
  // }, [modalidadSeleccionada])
const [mensajes, setMensajes] = useState([])
const [showModalMensajes, setShowModalMensajes] = useState(false)
  useEffect(() => {
    //console.log("detecto cambios en produucts full")
    //console.log(cartItems)
    if(cartItems.length>0){
    const response = validaStock(cartItems, productsFull, "carro")
    //console.log("respuesta de valida carro")
    //console.log(response)
    dispatch(updateCart(response.carro))
    if(response.mensajes.length>0){
      setMensajes(response.mensajes)
      setShowModalMensajes(true)
    }
    else{
      setMensajes([])
      setShowModalMensajes(false)
    }
  }
    return;
  }, [productsFull])

  return (
    <div className="shopping-cart-content">
      {
        mensajes.length>0  ?

        <Modal show={showModalMensajes}
        onHide={()=>setShowModalMensajes(false)}
        >
          <Modal.Header closeButton>
            <h3>Han habido cambios en el carro</h3>
          </Modal.Header>
          <Modal.Body>
            {
              mensajes.map((item, index)=> (
                <p key={index}>{item.medicamento+ ": "+item.mensaje}</p>
              ))
            }
          </Modal.Body>
        </Modal>

        : null

      }
      {cartItems && cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((item, index) => {
              // console.log("item")
              // console.log(item)


              return (
                <li className="single-shopping-cart" key={index}>
                  <div className="shopping-cart-img">
                    <Link to={process.env.PUBLIC_URL + "/product/" + item.id}>
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + item.medicamento.image[0]}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link
                        to={process.env.PUBLIC_URL + "/product/" + item.id}
                      >
                        {" "}
                        {item.nombre}{" "}
                      </Link>
                    </h4>
                    <h6>Cantidad: {item.cantidad}</h6>
                    <span>
                    
                      $ {(item.cantidad * item.precioFinal).toLocaleString("es-CL")}

                    </span>
                    
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => dispatch(deleteFromCart(item))}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Total :{" "}
              <span className="shop-total">
                {"$" + totalCarro.toLocaleString("es-CL")}
              </span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/carrito"}>
              Ver carro
            </Link>
            {/* <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              Ir a pagar
            </Link> */}
          </div>
        </Fragment>
      ) : (
        <p className="text-center">Sin medicamentos en el carro</p>
      )}
    </div>
  );
};

export default MenuCart;
