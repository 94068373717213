//crea un slice para los documentos, que contenga un arreglo de objeto documentos
import { createSlice } from "@reduxjs/toolkit";

const documentosSlice = createSlice({
    name: "documentos",
    initialState: {
        arrDocumentos: [],
    },
    reducers: {
        setDocumentos(state, action) {
            state.arrDocumentos = action.payload;
        }
    }
});

export const { setDocumentos } = documentosSlice.actions;

export default documentosSlice.reducer;