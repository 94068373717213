function calcularDigitoVerificador(rutSinDV) {
    const rutArray = Array.from(String(rutSinDV), Number).reverse();
    let suma = 0;
    let coeficiente = 2;
    for (let i = 0; i < rutArray.length; i++) {
      suma += rutArray[i] * coeficiente;
      coeficiente = coeficiente === 7 ? 2 : coeficiente + 1;
    }
    const residuo = suma % 11;
    const digitoVerificador = 11 - residuo;
    return digitoVerificador === 11 ? 0 : digitoVerificador === 10 ? "K" : String(digitoVerificador);
  }
export default calcularDigitoVerificador  