import React, { useEffect, useState } from "react";
import { Tracking } from "./Tracking";
import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";

import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { validateEmail } from "../../helpers/validators";
import { getPedidoTracking } from "../../firebase/firebase-functions";
import { Spinner } from "react-bootstrap";

export const TrackingPage = () => {


    let { pathname } = useLocation();
    const [numPedido, setNumPedido] = useState("")
    const [email, setEmail] = useState("")
    const [trackingNumber, setTrackingNumber] = useState("")
    const [error, setError] = useState("")
    const [trackingEmail, setTrackingEmail] = useState("")
    const [trackingVisible, setTrackingVisible] = useState(false)
    const [loadingTracking, setLoadingTracking] = useState(false)

    const [estado, setEstado] = useState("sin datos")
    const [loading, setLoading] = useState(false)
    const [datosEntrega, setDatosEntrega] = useState({})

    const [pedido, setPedido] = useState({existe: false})

    const handleTracking = async () => {
        setLoading(true)
        //console.log("handleTracking")
        //console.log(trackingEmail)
        //console.log(trackingNumber)
        //pregunta si email es un email valido y que tiene punto despues del @
        if (!validateEmail(trackingEmail)) {
            setError("Ingrese un correo electrónico válido")
            return
        }
        //pregunta si numPedido es un numero
        if (isNaN(trackingNumber)) {
            setError("Ingrese un número de pedido válido")
            return
        }

       

        setError("")
        const pedidoData = await getPedidoTracking(trackingNumber, trackingEmail)
        //console.log(pedidoData)
        if (pedidoData.encontrado) {
            //console.log("data recibida")
            //console.log(pedido)
        }
        if (pedidoData.data.length > 0) {
            //console.log("pedido encontrado en firebase")
            //console.log("asigno este estado")
            //console.log(pedidoData.data[0].estado)
            setPedido(pedidoData.data[0])
            setEstado(pedidoData.data[0].estado)
            //setDatosEntrega(pedido.data[0])
        } else {
            //console.log("pedido no encontrado en firebase")
            setError("El número de pedido no existe")
            setEmail("")
            setNumPedido("")
            setEstado("")
            setPedido({existe: false})
            setTrackingVisible(false)
           return  setLoading(false)
        }
        setEmail(trackingEmail)
        setNumPedido(trackingNumber)
        setTrackingVisible(true)
        setLoading(false)
    }


    return (
        <Fragment>
            <SEO
                titleTemplate="Seguimiento de pedidos"
                description="seguimiento de pedidos de farmacia online"
            />
            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Seguimiento de Pedidos", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                <div className="container pt-50 pb-70 text-center">
                    <div className="seguimiento">


                        <h1>Seguimiento de Pedidos</h1>
                        <p>Ingrese el número de pedido que desea rastrear</p>
                        <div className="row">
                            <div className="col-3 text-center">
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="form-group pb-3 pt-3 ">
                                    <label>Número de pedido</label>
                                    <input
                                        type="text"
                                        className="text-box form-control"
                                        placeholder="Ingrese el número de pedido"
                                        value={trackingNumber}
                                        onChange={(e) => setTrackingNumber(e.target.value)}
                                    />
                                    <label className="mt-3">Correo asociado</label>
                                    <input
                                        type="email"
                                        className="text-box form-control"
                                        value={trackingEmail}
                                        placeholder="Ingrese el correo electrónico asociado a la compra"
                                        onChange={(e) => setTrackingEmail(e.target.value)}
                                    />
                                    <p className="text-danger">{error}</p>
                                </div>
                                <button className="boton pt-2 pb-2 ps-3 pe-3" onClick={() => handleTracking()}>Rastrear</button>
                            </div>
                            <div className="col-3 text-center">
                            </div>
                            {/* <p>En esta sección podrás ver el estado de tus pedidos.</p> */}

                            {/* {trackingVisible && error === "" ? <Tracking numPedido={trackingNumber} email={trackingEmail} /> : null} */}
                            <div className="text-start seguimiento pt-5">
                                {/* <h1 className="text-center">Seguimiento de pedido</h1> */}



                                {
                                    trackingVisible ?
                                        <div className="row">
                                            <label className="col-12">{"Numero de pedido: " + numPedido}</label>
                                            <label className="col-12">{"Email: " + email}</label>
                                        </div>
                                        : null
                                }


                                {/* ESTADOS AMBAS MODALIDADES */}

                                {
                                    estado === "PAGADO" ?
                                        <div className="row">
                                            <label className="col-sm-12 col-xs-12 col-6">{"El pedido se ha recibido exitosamente."}</label>
                                        </div> : null
                                }

                                {
                                    estado === "VALIDADO" || estado === "ENVIADO CON PROVEEDOR" ?
                                        <div className="row">
                                            <label className="col-sm-12 col-xs-12 col-6">{"El pedido se encuentra validado y se está procesando."}</label>
                                        </div> : null
                                }


                                {/* //RETIRO EN TIENDA */}


                                {
                                    estado === "LISTO-RETIRO" ?
                                        <div className="row">
                                            <label className="col-sm-12 col-xs-12 col-6">{"El pedido se encuentra disponible para ser retirado en la sucursal " + pedido.modalidad.nombre_su + "."}</label>
                                        </div> : null
                                }

                                {
                                    estado === "ENTREGADO" && pedido.modalidad.tipo === "retiro" ?
                                        <div className="row">
                                            <label className="col-sm-12 col-xs-12 col-6">{"Pedido retirado en sucursal"}</label>
                                        </div> : null
                                }


                                {/* //despacho a domicilio */}

                                {
                                    estado === "EN RUTA" ?
                                        <div className="row">
                                            <label className="col-sm-12 col-xs-12 col-6">{"El pedido se encuentra en tránsito al destino"}</label>
                                            
                                        </div> : null
                                }
                                
                                {
                                    estado === "ENTREGADO" && pedido.modalidad.tipo === "despacho" ?
                                        <div className="row">
                                            <label className="col-sm-12 col-xs-12 col-6">{"El pedido ha sido entregado en domicilio"}</label>
                                            {/* <label className="col-sm-12 col-xs-12 col-6">Direccion: {datosEntrega.contact_address}</label>
                                            <label className="col-sm-12 col-xs-12 col-6">Fecha de entrega: {datosEntrega.arrived_at}</label> */}

                                        </div> : null
                                }
                                


                                {loading ? <p><Spinner animation="border" role="status" size="sm" />{" Cargando..."}</p> : null}



                                {
                                pedido.existe===false ? null :    pedido.modalidad.tipo === "despacho" ?

                                        <div className="card-body pt-4">
                                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                                <div className={estado === "ENTREGADO" || estado === "EN RUTA" || estado === "PAGADO" || estado === "VALIDADO" || estado === "ENVIADO CON PROVEEDOR" ? "step completed" : "step"}>
                                                    <div className="step-icon-wrap">
                                                        <div className="step-icon"><i className="pe-7s-cart"></i></div>
                                                    </div>
                                                    <h4 className="step-title">Pedido Recibido</h4>
                                                </div>
                                                <div className={estado === "ENTREGADO" || estado === "EN RUTA" || estado === "VALIDADO" || estado === "ENVIADO CON PROVEEDOR" ? "step completed" : "step"}>

                                                    <div className="step-icon-wrap">
                                                        <div className="step-icon"><i className="pe-7s-config"></i></div>
                                                    </div>
                                                    <h4 className="step-title">En proceso</h4>
                                                </div>

                                                <div className={estado === "ENTREGADO" || estado === "EN RUTA" ? "step completed" : "step"}>

                                                    <div className="step-icon-wrap">
                                                        <div className="step-icon"><i className="pe-7s-car"></i></div>
                                                    </div>
                                                    <h4 className="step-title">En tránsito</h4>
                                                </div>
                                                <div className={estado === "ENTREGADO" ? "step completed" : "step"}>

                                                    <div className="step-icon-wrap">
                                                        <div className="step-icon"><i className="pe-7s-home"></i></div>
                                                    </div>
                                                    <h4 className="step-title">Entregado</h4>
                                                </div>
                                            </div>


                                        </div>

                                        :

                                        <div className="card-body pt-4">
                                            <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                                                <div className={estado === "ENTREGADO" || estado === "EN RUTA" || estado === "PAGADO" || estado === "VALIDADO" || estado === "LISTO-RETIRO" ? "step completed" : "step"}>
                                                    <div className="step-icon-wrap">
                                                        <div className="step-icon"><i className="pe-7s-cart"></i></div>
                                                    </div>
                                                    <h4 className="step-title">Pedido Recibido</h4>
                                                </div>
                                                <div className={estado === "ENTREGADO" || estado === "VALIDADO" || estado === "LISTO-RETIRO" ? "step completed" : "step"}>

                                                    <div className="step-icon-wrap">
                                                        <div className="step-icon"><i className="pe-7s-config"></i></div>
                                                    </div>
                                                    <h4 className="step-title">En proceso</h4>
                                                </div>

                                                <div className={estado === "ENTREGADO" || estado === "LISTO-RETIRO" ? "step completed" : "step"}>

                                                    <div className="step-icon-wrap">
                                                        <div className="step-icon"><i className="pe-7s-check"></i></div>
                                                    </div>
                                                    <h4 className="step-title">Listo para retiro</h4>
                                                </div>
                                                <div className={estado === "ENTREGADO" ? "step completed" : "step"}>

                                                    <div className="step-icon-wrap">
                                                        <div className="step-icon"><i className="pe-7s-shopbag"></i></div>
                                                    </div>
                                                    <h4 className="step-title">Entregado</h4>
                                                </div>
                                            </div>


                                        </div>

                                }


                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    )
}