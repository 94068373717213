export const TerminosYCondiciones = () => (
    <div className="container terminos pb-5">
        <h1>TÉRMINOS Y CONDICIONES DE FARMACIA LICHE ONLINE</h1>

        <h2>1.- Información General</h2>

        <p>Toda transacción que se realice a través de nuestro sitio web www.ligaepilepsia.cl estará sujeta a los Términos y Condiciones establecidos en el presente documento, bajo la normativa legal vigente en el territorio de la República de Chile, con especial atención en las Leyes 19.496 Protección de los derechos del Consumidor, N° 20.584 Regula los Derechos y deberes que tienen las personas en relación con acciones vinculadas a su atención en salud y 19.628 Protección de la Vida Privada.</p>
        <p>Con ello, garantizamos el pleno respeto de los derechos de nuestros clientes y consumidores en cada compra que realice a través de nuestro portal.</p>
        <p>La Liga Chilena contra la Epilepsia no se hace responsable por los efectos que puedan tener los fármacos vendidos por la plataforma, ni tampoco por la mala administración de estos que los usuarios y/o pacientes puedan hacer.</p>
        <p>La descripción de los medicamentos indicada en el portal no es una guía de medicación, sino que es meramente informativa para el solo efecto de facilitar y permitir realizar el proceso de compra.</p>
        <p>El expendio y dispensación de recetas se realizará de acuerdo a los señalado en la modificación del decreto 466, publicado el 7 de mayo de 2020:</p>
        <p>“Artículo 87º E.- Del expendio según condición de venta. Los establecimientos autorizados para el expendio electrónico de medicamentos podrán dispensar medicamentos de Venta Directa sin exigir la receta médica.</p>
        <p>En el caso de aquellos medicamentos cuya condición de venta es receta simple, el expendio se hará previa comunicación de la receta al establecimiento. Para cumplir con este requisito podrá ponerse a disposición del establecimiento una receta médica electrónica o una copia digitalizada de una receta emitida por medios físicos. En el caso de la receta médica electrónica, el expendedor deberá verificar que se cumplan con los requisitos del artículo 101 del Código Sanitario. Respecto a la copia digitalizada de una receta emitida por medios físicos, además de los requisitos del artículo señalado, quien expende el medicamento deberán verificar la correspondencia entre el soporte físico y la digitalización de este.</p>
        <p>En los casos de los medicamentos que requieren receta retenida, el expendio podrá efectuarse a través de receta médica electrónica, o bien, a través de una copia digitalizada de una receta física. En el caso que se trate de una copia digitalizada, la receta física deberá entregarse al momento de la recepción del producto farmacéutico por parte del consumidor.</p>
        <p>Los medicamentos cuya condición de venta es “receta-cheque” no están disponibles entre aquellos que pueden expenderse por medios electrónicos.”</p>
        <p>Art. 27 DS.405. Las recetas médicas retenidas en que se prescriben drogas o productos psicotrópicos deberán ser despachadas personalmente por el Director Técnico de la farmacia o laboratorio de producción, siempre que ellas se presenten por una persona mayor de dieciocho años quien deberá exhibir su cédula de identidad.</p>
        <p>La seguridad y eficacia de estos productos se encuentra avalada por su registro sanitario en el Instituto de Salud Pública de Chile.</p>
        
        <h2>2.- Comunicación con nuestros Clientes</h2>
        
        <p>La Liga Chilena contra la Epilepsia solicitará, a través de la plataforma online nuestros clientes, información personal (nombre, rut, dirección, correo electrónico, teléfono de contacto) y la receta médica para cerrar el proceso de venta.&nbsp; Esta información es de carácter absolutamente confidencial.</p>
        <p>Confirmada la compra, el cliente recibirá un correo electrónico de confirmación de la compra junto con el número de pedido, con el que podrá realizar seguimiento de su transacción.</p>
        
        
        <h2>3.- Registro Del Cliente y Seguridad de la Información</h2>
        
        <p>Cualquier persona mayor de edad (18 años cumplidos) puede adquirir medicamentos en nuestros establecimientos farmacéuticos en la medida que se respete la condición de venta de los mismos, optando por su entrega a domicilio o retiro en alguno de nuestros establecimientos.</p>
        <p>Toda persona que desee adquirir productos farmacéuticos por medio de nuestra página web, debe previamente registrarse indicando su (s) nombre (s), apellidos, teléfono de contacto, correo electrónico y dirección. Debe, además, aceptar en forma expresa las presentes bases de contratación. Toda la información entregada, será tratada en forma confidencial.</p>
        <p>Toda persona que se haya registrado tendrá derecho a solicitar la información de su registro y modificar, eliminar o cancelar aquella existente. en nuestro sitio web www.ligaepilepsia.cl. Para esto deberá comunicarse a farmacia.online@liche.cl.</p>
        <p>El envío de información se efectuará por medio de correo electrónico, a menos que el cliente especifique que no desea ser contactado, basado en su derecho descrito en el artículo 28 B de la Ley 19.496 Protección de los Derechos del Consumidor o por cualquier otra razón.</p>
        <p>Recomendamos a nuestros clientes que deben ser los primeros en proteger la confidencialidad de sus datos, por lo que solicitamos no divulgarlos, y así evitar fraudes o algún uso malicioso por parte de terceros.</p>
       
        <h2>4.- Información de precios</h2>
       
        <p>En nuestra página se informa de manera clara, oportuna, transparente y veraz, los precios de los productos que se encuentran disponibles para su expendio, mediante la siguiente información para cada uno de los productos exhibidos:</p>
        <p>.- Fotografía del envase secundario del producto farmacéutico.</p>
        <p>.- Nombre del producto farmacéutico.</p>
        <p>.- Nombre del principio activo bajo su denominación común internacional.</p>
        <p>.- Forma farmacéutica.</p>
        <p>.- Dosis por forma farmacéutica.</p>
        <p>.- Precio por producto fraccionado.</p>
        <p>.- Precio.</p>
        <p>Además, nuestro sitio cuenta con la opción de CONTACTO para solicitar cotizaciones por medicamentos no comercializados en Chile con posibilidad de importar los mismos para los tratamientos de los pacientes que lo requieran.</p>
        <p>Para ello, es necesario que el cliente nos envíe sus datos de contacto y prescripción médica, para ver las posibilidades de responder a su requerimiento.</p>
        <p>Las Cotizaciones tendrán una vigencia de 30 días corridos a partir de la fecha de envío.</p>
        <p>Si el medicamento es de aquellos que deben demostrar bioequivalencia, el sitio web te ofrecerá además, la alternativa de comprar cualquiera de aquellos que son bioequivalentes al que busques, antes de que finalice la compra del producto.</p>
        <p>Los precios informados no contemplan el valor de entrega de productos al domicilio del adquirente. Este valor, se indica en el carro de compra y dependerá del lugar al cual serán despachados los productos adquiridos. Los gastos de envío son por cuenta del comprador y se adicionarán al valor final de la compra.</p>
        
        <h2>5.-Material de consulta y registro de reclamos</h2>
        
        <p>En nuestra página además, se dispone de accesos directos al siguiente material de consulta:</p>
        <p>.- Reglamento de Farmacias, Droguerías, Almacenes Farmacéuticos, Botiquines y Depósitos Autorizados, aprobado mediante Decreto Supremo Nº 466, de 1984, del Ministerio de Salud, o el que lo reemplace.</p>
        <p>.- Reglamento del Sistema Nacional de Control de Productos Farmacéuticos de Uso Humano, aprobado mediante Decreto Supremo Nº 3, de 2010, del Ministerio de Salud, o el que lo reemplace.</p>
        <p>Información relacionada con números telefónicos de líneas existentes que provean información toxicológica de forma gratuita, como el centro de asistencia remota del Ministerio de Salud.</p>
        <p>Además, se dispone de Registro de quejas y reclamos, destinado a que el público deje constancia de sus observaciones, y que al momento de enviarlo le entregará un N° de caso que le permitirá realizar seguimiento.</p>
        
        <h2>6.- Condición de venta</h2>
        
        <p>En caso de optar por la entrega del producto a domicilio, se entenderá para todos los efectos que el consumidor está otorgando un mandato remunerado a la Liga, para que ésta, por medio de la persona que unilateralmente designe, le haga entrega del producto adquirido, bajo las condiciones aquí consignadas.</p>
        <p>La modalidad de compra online, con entrega del producto al domicilio del adquirente, exige que, tratándose de medicamentos sujetos a receta simple y sujetos a receta retenida sujeta y no control legal, la prescripción contenida en dichas recetas médicas sea previamente comunicada a nuestros profesionales, por medio de una receta médica electrónica o una copia digitalizada de una receta emitida por medios físicos.</p>
        <p>Nuestros profesionales, revisarán que las recetas cumplan todos los requisitos establecidos en la legislación y reglamentos para poder ser despachadas, acción que deben realizar en forma previa a la compra.</p>
        <p>Los productos además, sólo podrán ser entregados a una persona mayor de edad, previa verificación de correspondencia entre la receta original y aquella que nos fue enviada cuando se trate de medicamentos con condición de venta bajo receta simple, y previa entrega de la receta original, tratándose de medicamentos cuya condición de venta sea receta retenida.</p>
        <p>Es obligación del adquirente, guardar copia o fotografía de toda receta que entregue para de esta manera poder cumplir la prescripción en ella contenida.</p>
        <p>Para la adquisición de medicamentos sujetos a receta retenida, se exigirá además, copia de la cédula nacional de identidad del adquirente.</p>
        
        <h2>7.- Plazos de entrega bajo la modalidad de entrega a domicilio</h2>
        
        <p>Los plazos de entrega de los productos, serán informados en el correo de respuesta a la solicitud de compra que se haga, junto con la confirmación de la compra que se efectúe, por medio de correo electrónico junto con el número de pedido, con el que se podrá realizar seguimiento de su transacción.</p>
        <p>Los plazos dependerán del domicilio al que deben ser entregados los productos y de la disponibilidad de los medios de transportes del producto.</p>
        <p>El expendio y dispensación a domicilio, solo aplica a la ciudad de Santiago, y no a toda la región metropolitana. El servicio estará disponible en las comunas de 
        Cerrillos, 	Buin, 	Calera de Tango, 	Cerro navia, 	Colina, 	Conchali, 	El Bosque, 	Estacion Central, 	Huechuraba, 	Independencia, 	La Cisterna, 	La Florida, 	La Granja, 	La Reina, 	Lampa, 	Las Condes, 	Lo Barnechea, 	Lo Espejo, 	Lo Prado, 	Macul, 	Maipú, 	Ñuñoa, 	Paine, 	Pedro Aguirre Cerda, 	Peñalolen, 	Pirque, 	Providencia, 	Pudahuel, 	Puente Alto, 	Quilicura, 	Quinta Normal, 	Recoleta, 	Renca, 	San Bernardo, 	San Joaquin, 	San Jose de Maipo, 	San Miguel, 	San Ramon, 	Santiago, 	Vitacura,
            </p>
            <p>El expendio y dispensación a domicilio en la región del Biobío solo aplica a las comunas de Concepción, San Pedro de la Paz, Hualpén y Talcahuano.</p>
        <p>Si por alguna razón, no se pudiere despachar el producto por existir algún impedimento legal o reglamentario para aquello, dicha situación será informada.</p>
        <p>Los horarios de despachos y envíos definidos son:</p>
        <p>Lunes a Sábado desde las 08:30 a las 21:00 horas</p>
        <p>Los domingos y festivos no se encontrará disponible el servicio de entrega a domicilio.</p>
        <p>Si a la fecha y momento de la entrega el cliente no se encuentra en su domicilio, o lugar de indicado para el despacho y envío, se dejará un aviso de visita y se acudirá sólo una segunda vez. Si no es habido una segunda vez, el medicamento será devuelto a local de farmacia, con un plazo máximo de 30 días para retiro en tienda.</p>
        <p>La recepción debe ser realizada por una persona mayor de edad debidamente individualizada y comprobada con su cédula de identidad, para que de esa manera se pueda acreditar la recepción conforme. En caso de que el producto sea recibido por un tercero debidamente identificado (familiares, asesora del hogar, conserjes, mayordomo, etc.), y éste dé conformidad a la recepción del producto, entenderemos para todos los efectos que el envío fue recibido conforme.</p>
        <p>Es de responsabilidad del cliente o del receptor revisar el producto al momento de la recepción. Cualquier situación posterior a la recepción conforme, será de su exclusiva responsabilidad. En caso de disconformidad, el cliente debe rechazar el medicamento en el momento de la entrega, indicando sus razones, además de su nombre y RUT en la boleta.</p>
        <p>Al retirar en tienda el cliente declara que el producto recibido corresponde al que se compró y en caso de no ser así deberá dejarlo registrado en el momento del retiro y se gestionará en la misma Farmacia la solución dependiendo del caso.</p>
        <p>Una vez recibido conforme el producto por el cliente o destinatario, cesa toda responsabilidad para La Liga.</p>
        
        <h2>8.- Medios de pago</h2>
        
        <p>Los adquirentes podrán efectuar compras en nuestro sitio web www.ligaepilepsia.cl con los siguientes medios de pagos:</p>
        <p>a. Tarjetas de crédito bancarias; Visa, MasterCard, Diners Club International o American Express, que se encuentren suscritas a Webpay.</p>
        <p>b. Tarjetas de débito bancarias acogidas al sistema Red Compra®, que se encuentren suscritas a Webpay.</p>
        <p>Los aspectos relativos al funcionamiento de las tarjetas anteriormente señaladas no son de responsabilidad de La Liga, sino que están sujetos a los contratos mantenidos entre el cliente y el Banco emisor, sin ningún tipo de injerencia, obligación ni responsabilidad para La Liga.</p>
        <p>Los datos de pago quedarán almacenados en Transbank de forma segura y sólo estarán asociados a la cuenta del cliente.</p>
        <p>www.ligaepilepsia.cl no dejará registro de los datos de las tarjetas bancarias.</p>
        
        <h2>9.- Anulaciones.</h2>
        
        <p>La Liga Chilena contra la Epilepsia se reserva el derecho de anular la transacción comercial por falta de confirmación de Transbank del pago de la compra, siendo responsabilidad del cliente realizar las consultas respectivas con el ejecutivo de su Banco. La Liga no es responsable del no pago por parte de Transbank o por cualquier otra operación que el cliente haga a través de Transbank.</p>
        <p>En el caso que la compra no se realice o sea rechazada La Liga tendrá un plazo de 5 días hábiles para el reembolso.</p>
        
        <h2>10.- Devoluciones</h2>
        
        <p>Los adquirentes tienen el derecho de garantía sobre los medicamentos adquiridos que presentan fallas de calidad o si no corresponde al medicamento prescrito en términos de principio activo y/o presentación.</p>
        <p>El cliente cuenta con 90 días a partir de la fecha de recepción de la compra, según lo indicado en la Ley 19.496 Protección de los Derechos de los Consumidores. Todo cambio o devolución de producto debe realizarse con el documento que acredite su compra (boleta, factura o guía de despacho). Para hacer efectiva la devolución o cambio del medicamento, el cliente se acercará a la farmacia presencialmente, donde se emitirá la Nota de Crédito en caso de devolución o emisión de la nueva boleta en caso de cambio</p>
        <p>No se aceptarán devoluciones ni cambio de medicamentos en caso de contenido abierto e incompleto, daño en el envase, vencimiento fuera de plazo.</p>
        <p>Los medicamentos que, su expendio y dispensación se encuentran sujetos a control legal y con control de stock no están sujetos a cambio ni devolución.</p>
        <p>Los Medicamentos que, su expendio y dispensación y que para su transporte requieran conservación de cadena de frío (refrigerados) no se encuentran sujetos ni a cambio ni a devolución.</p>
        <p>Medicamentos que no le hizo efecto y no existe fallas de calidad desde la fabricación</p>
        <p>Medicamentos si el medicamento no le sirve por un cambio de tratamiento</p>
        <p>Medicamentos si le causa una reacción adversa (se debe reportar al Instituto de Salud Pública (ISP)</p>
        <p>Medicamentos consumidos de forma parcial</p>
        <p>El lote que figura en el envase no corresponde a los declarados en los inventarios disponibilizados para la venta.</p>
        <p>Medicamentos Fraccionados</p>
        
        <h2>11.- Canales de atención</h2>
        
        <p>La Liga cuenta con los siguientes canales de atención para el caso de dudas o información</p>
        <p>Mail: farmacia.online@liche.cl.</p>
        <p>Call Center: 600 3001515 Anexo 573</p>
        <p>Para información sobre la Liga Chilena contra la Epilepsia dirigirse a : https://www.ligaepilepsia.cl/contacto/</p>
        
        <h2>12.- Por la sola circunstancia de contratar los servicios ofrecidos, se entiende que el cliente acepta irrevocablemente los términos y condiciones del contrato celebrado.</h2>
        
        <p>Una vez efectuado el pago, se enviará confirmación escrita de esta circunstancia por el mismo medio de comunicación utilizada por el cliente o cualquier otro que garantice su debido y oportuno conocimiento remitiendo a su vez una copia íntegra, clara y legible del contrato. En los contratos celebrados por medio de la página web de la sociedad, no podrá ejercerse el derecho de retracto.</p>
        
        <h2>13.- Solución de controversias</h2>
        <p>Cualquier controversia que pudiere suscitarse entre las partes con motivo de la celebración del presente contrato, será resuelta por los Tribunales Ordinarios de Justicia de la ciudad de Santiago.</p>
    </div>



)