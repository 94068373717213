import { Fragment, useState } from "react";
import PropTypes, { object } from "prop-types";
import { EffectFade, Thumbs } from 'swiper';
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addPaciente, deletePaciente, setPacienteSeleccionado, updatePacientes } from "../../store/slices/paciente-slice";
import { useRut, isValid , unformat, format, } from 'react-rut';
import calcularDigitoVerificador from "../../helpers/rut";

import { updatePacientesFirebase } from "../../firebase/firebase-methods";
import cogoToast from "cogo-toast";
// import Rating from "./sub-components/ProductRating";
// import Swiper, { SwiperSlide } from "../../components/swiper";
// import { getProductCartQuantity } from "../../helpers/product";
// import { addToCart } from "../../store/slices/cart-slice";

function ModalPaciente({ showPacienteModal, onHidePacienteModal, objPaciente }) {
    const dispatch = useDispatch();
    const { arrPacientes } = useSelector((state) => state.paciente);
    const {user} = useSelector((state) => state.user);
    const { pacienteSeleccionado } = useSelector((state) => state.paciente);
   //console.log("se obtiene arreglo pacientes");
   //console.log("se recibe showpac" + showPaciente);
   //console.log([arrPacientes])
    const onCloseModal = () => {
        onHidePacienteModal()
    }
    const [nombre, setNombre] = useState('');
    const [apellidos, setApellidos] = useState('');
    
    const [errorRut, setErrorRut] = useState("Debe ingresar un rut")
    const [errorNombre, setErrorNombre] = useState("Debe ingresar un nombre")
    const [errorApellidos, setErrorApellidos] = useState("Debe ingresar un apellido")
    
    const [{ formattedValue }, setRut] = useRut();
    const handleSubmit = async (event) => {
        event.preventDefault();
       //console.log("parse rut");
        
       //console.log(unformat(formattedValue))
       //console.log("sin digito"+unformat(formattedValue).slice(0, unformat(formattedValue).length - 1));
       //console.log(calcularDigitoVerificador(unformat(formattedValue).slice(0, unformat(formattedValue).length - 1)));
       //console.log(format(unformat(formattedValue)))

       //pregunta si el rut ya existe
         //console.log("existe rut");
        let objPaciente = {
            nombre: nombre,
            apellidos: apellidos,
            
            rut: unformat(formattedValue).slice(0, unformat(formattedValue).length - 1)
        }

        //console.log(objPaciente);
        let arrayPacientes = [...arrPacientes];
        arrayPacientes.push(objPaciente);
        //console.log(arrayPacientes);
        //console.log("se envia");
        if(user.estado==="invitado"){
            dispatch(updatePacientes(arrayPacientes))
            dispatch(setPacienteSeleccionado(objPaciente))
            setTimeout(() => {
                cogoToast.success("Paciente añadido", { position: "bottom-left" });
                clean()
                return onCloseModal()
            }, 100);
        }
        else{
            const resultado =await  updatePacientesFirebase(user.uid, arrayPacientes);
            //console.log(resultado)
            if(resultado.actualizado){
                dispatch(updatePacientes(arrayPacientes))
                dispatch(setPacienteSeleccionado(objPaciente))
                setTimeout(() => {
                    cogoToast.success("Paciente añadido", { position: "bottom-left" });
                    clean()
                    return onCloseModal()
                }, 100);
            }
            else{
                setTimeout(() => {
                    cogoToast.error("Error al agregar paciente", { position: "bottom-left" });
                    return onCloseModal()
                }, 100);
            }
        }



       //console.log(formattedValue)


        // Aquí puede agregar la lógica de envío del formulario
    }

    const clean = () => {
        setNombre("")
        setErrorNombre("Debe ingresar un nombre")
        setApellidos("")
        setErrorApellidos("Debe ingresar un apellido")
   
        setRut("")
        setErrorRut("Debe ingresar un rut valido")
        onCloseModal()
    }
    const handleClean = () => {
        setRut('');
    };
    const handleNombre = (nombre) => {
        setNombre(nombre)
        if (nombre.length > 0 && nombre.length <= 3) {
            setErrorNombre("Debe ingresar un nombre válido")
        }
        if (nombre.length === 0) {
            setErrorNombre("Debe ingresar un nombre")
        }
        if (nombre.length >= 3) {
            setErrorNombre("")
        }
    }
    const handleApellidos = (apellidos) => {
        setApellidos(apellidos)
        if (apellidos.length > 0 && apellidos.length <= 3) {
            setErrorApellidos("Debe ingresar un apellido válido")
        }
        if (apellidos.length === 0) {
            setErrorApellidos("Debe ingresar un apellido")
        }
        if (apellidos.length >= 3) {
            setErrorApellidos("")
        }
    }
 
    const validaRut = (rut) => {
      
       //console.log("se recibe")
       //console.log(rut);
        //existeRut(unformat(rut).slice(0, unformat(rut).length - 1))
        setRut(rut)
        if (!isValid(rut) || rut==="19") {
            setErrorRut("Debe ingresar un rut valido")
        } else {
            setErrorRut("")
        }
          if(existeRut(unformat(rut).slice(0, unformat(rut).length - 1))){
            setErrorRut("Rut ya existe")
        }
    }

    const existeRut = (rut) => {
       //console.log("entra a existe rut   "+rut);
        // if(!arrPacientes.filter((paciente) => paciente.rut === rut)){
        //     return false
        // }
       //console.log(arrPacientes.filter((paciente) => paciente.rut === rut));
       return arrPacientes.filter((paciente) => paciente.rut === rut).length===0 ? false : true
    }

    return (
        <Modal show={showPacienteModal}
            onHide={onCloseModal}
            contentClassName="bg-light "
            dialogClassName="p-0 my-2 "
            className="nuevo-paciente"
        >
            <Modal.Header
            className=" col-12"
            closeButton><p className="titulo mb-0" >Agregar paciente</p></Modal.Header>
            <Modal.Body className="p-20">
                <div className="">
                    <form onSubmit={handleSubmit}
                    // className=""
                    >
                        <div className="modal-body form-group row">
                            <h5 className="sub-titulo pb-4">Ingresa los datos del nuevo paciente</h5>
                            <label>
                                Nombre:
                                <input type="text" value={nombre} onChange={(event) => handleNombre(event.target.value)} />
                                {
                                    errorNombre ? <p className="fs-10 text-danger">{errorNombre}</p>
                                        : ""
                                }
                            </label>

                            <br />
                            <label>
                                Apellidos:
                                <input type="text" value={apellidos} onChange={(event) => handleApellidos(event.target.value)} />
                                {
                                    errorApellidos ? <p className="fs-10 text-danger">{errorApellidos}</p>
                                        : ""
                                }
                            </label>
                            <br />
                            
                            <br />

                            <label>
                                Rut:
                                <input
                                maxLength={12}
                                    value={formattedValue}
                                    onChange={(e) => validaRut(e.target.value)}
                                />
                                {errorRut ? <p className="fs-10 text-danger">{errorRut}</p> : ""}

                                {/* <RutCleaner value={formattedValue} onChange={setRut} /> */}
                                {/* <button onClick={()=> handleClean}>Limpiar</button> */}
                            </label>
                            <br />
                            <div className="pt-4 pb-2 text-center nuevo-paciente" >
                                 <Button className="boton"  type="submit" disabled={errorRut || errorNombre || errorApellidos ? true : false}>Agregar paciente</Button>
                    
                            </div>
                               </div>
                    </form>

                </div>
            </Modal.Body>


        </Modal>
    )

}

ModalPaciente.propTypes = {
    //paciente: PropTypes.shape({}),
    showPaciente: PropTypes.bool,
    onHidePaciente: PropTypes.func,
    objPaciente: PropTypes.shape({}),
    //arrPacientes: PropTypes.shape([])

}

export default ModalPaciente;