import { Fragment } from "react";
import { Link, useLocation } from "react-router-dom"; 
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { TerminosYCondiciones } from "./TerminosYCondiciones";

 const PageTerminosYCondiciones = () => {
  let { pathname } = useLocation();

  return (
    <Fragment>
      <SEO
        titleTemplate="Términos y condiciones"
        description="Términos y condiciones de uso de la plataforma de venta de medicamentos de la Liga Chilena contra la Epilepsia"
      />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb 
          pages={[
            {label: "Home", path: process.env.PUBLIC_URL + "/" },
            {label: "Términos y Condiciones", path: process.env.PUBLIC_URL + pathname }
          ]} 
        />
        <div className=" pt-40 pb-100">
            <TerminosYCondiciones />
        </div>
        </LayoutOne>
    </Fragment>
    );
}

export default PageTerminosYCondiciones;
