import { arrGrupoBioequivalentes } from "../data/grupos_bioequivalente";
import { store } from "../store/store";

function compararPrecios(a, b) {
  return a.precio - b.precio; // Orden ascendente (de menor a mayor)
}
export const getGrupoBioequivalentes = (kinf2) => {
 //console.log(kinf2)
const arrMedFull = store.getState().product.productsFull
  //reviso si existe el medciamento en el arreglo bioequivalente
  const arr = arrGrupoBioequivalentes.filter(item => item.kinf2 === kinf2)

  //si no existe devuelvo arreglo bioequivalente vacio
  if (arr.length === 0) {
    return []
  }

  //genero arreglo con el grupo bio equivalente
 //console.log("grupo "+ arr[0].grupo_bio)
  const arrBio = arrGrupoBioequivalentes.filter(item => item.grupo_bio === arr[0].grupo_bio && item.kinf2 !== kinf2)

 //console.log(store.getState().product.productsFull)

  //genero arreglo a devolver
  let arrResp = []

  arrBio.forEach(item => {
    let p = arrMedFull.filter(product => product.kinf2 === item.kinf2)
    arrResp.push(p[0])
  })

  try {
     arrResp = arrResp.filter(product => product.kinf2 !== kinf2 && product.bioequivalente === 'BE')
  arrResp = arrResp.sort(compararPrecios)

  } catch (error) {
   //console.log(error)
  }
 
  return arrResp
}


export const actualizaPrecio = (arrMed, arrFull) => {
  //console.log("recibo")
  //console.log(arrMed)
  let arrResp = []
  arrMed.forEach(element => {
    const medicamento = arrFull.filter(item => item.kinf2 === element.kinf2)
    arrResp.push(medicamento[0])
  });
  return arrResp;
}

export const validaStock = (arrMed, arrFull, tipo) => {
  ////console.log("recibo")
  ////console.log(arrMed)
  let responseMensajes = []
  let response = []
  //console.log(arrFull)
  if (tipo === "carro") {
    let arrCarro = [...arrMed]
    try {
      //console.log("tipo carro")
      for (let index = 0; index < arrCarro.length; index++) {

        const item = arrCarro[index];

        const arrFiltrado = arrFull.filter(itemArrFull => itemArrFull.kinf2 === item.id)
        //console.log(arrFiltrado[0])
        if (arrCarro[index].cantidad <= arrFiltrado[0].stock) {
          //console.log("tiene stock")
          arrCarro[index] = { ...arrCarro[index], medicamento: arrFiltrado[0] }

        }
        else {
          responseMensajes.push({
            medicamento: arrCarro[index].nombre,
            mensaje: "Stock insuficiente en Sucursal " + arrFiltrado[0].sede
          })
          //elimino medicamento del carro
          //en caso de error puedo agregar los indices que voy a borrar en un arreglo y despues del for los elimino
          delete (arrCarro[index])
        }


      }
      //console.log("pinto carro")

      if (arrCarro[0] === undefined) {
        //console.log("carro invalido detectado")
        arrCarro = []
      }
      //console.log("response")
      //console.log(arrCarro)
    } catch (error) {
      //console.log(error)
    }

    return {
      carro: arrCarro,
      mensajes: responseMensajes
    }
  }

}

export const validateEmail = (email) => {
  // Expresión regular para validar un correo electrónico.
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Validar correo electrónico.
  if (regexEmail.test(email)) {
    return true;
  } else {
    return false;
  }
}

export const isOnlyNumbers = (str) => {
  return /^\d+$/.test(str);
}


export const contieneMayus = (text) => {
  let mayuscula = /[A-Z]/;

  if (mayuscula.test(text)) {
    return true;
  } else {
    return false;
  }
}

export const contieneNumeros = (text) => {
  let numeros = /[0-9]/;

  if (numeros.test(text)) {
    return true;
  }
  else {
    return false;
  }
}

export const extraeNumeros = (text) => {
  const patron = /[0-9]+/g;
  const resultado = text.match(patron);
  return resultado ? resultado.join("") : "";
}