import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
import { useState, useEffect } from "react";
import { cerrarSesion } from "../../firebase/auth-firebase";
import { useDispatch } from "react-redux";
import { limpiaUsuario } from "../../store/slices/user-slice";
import { useNavigate } from "react-router-dom";
import cogoToast from "cogo-toast";
import { clearDirecciones, updateDirecciones } from "../../store/slices/direcciones-slice";
import { deleteAllFromCart } from "../../store/slices/cart-slice";
import { updatePacientes } from "../../store/slices/paciente-slice";
import LogRocket from "logrocket";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
const IconGroup = ({ iconWhiteClass }) => {
  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  const { compareItems } = useSelector((state) => state.compare);
  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const [usuarioActivo, setUsuarioActivo] = useState(false)


  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {

    //e.preventDefault();
    cerrarSesion().then(() => {
      navigate("/")
      //     //console.log("sesion cerrada");
      dispatch(limpiaUsuario())
      dispatch(clearDirecciones())
      dispatch(deleteAllFromCart())
      dispatch(updatePacientes([]))

      cogoToast.success("Sesión cerrada correctamente")
    })
  }

  useEffect(() => {
     //console.log("se recibe user icon group");
     //console.log(user);

    try {
       //console.log("intento identificar en logrocket")
       //console.log(user);
      if (user.estado === "invitado") {
        LogRocket.identify("invitado", {
          name: "Invitado",

        })
      }
      else {
        LogRocket.identify(user.uid, {
          name: user.nombre + " " + user.apellidos,
          email: user.email
        })
      }
    } catch (error) {
       //console.log("error de logrocket")
       //console.log(error)
    }

    try {
      if (user.nombre) {
         //console.log("existe usuario lo asigno como usuario activo true");


        setUsuarioActivo(true)
        if (user.pacientes) {
          dispatch(updatePacientes(user.pacientes))
          //console.log("set pacientes si existe")
        }
        if(user.direcciones){
          dispatch(updateDirecciones(user.direcciones))
          //console.log("set direcciones si existe")
        }

      } else {
        setUsuarioActivo(false)
      }
    } catch (error) {
       //console.log(error);
      setUsuarioActivo(false)
    }

  }, [user])

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)} >
      {/* <div className="same-style header-search  d-lg-block">
        <button className="search-active" onClick={e => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div> */}
      <div className="same-style d-md-none d-lg-none d-xl-none d-xxl-none d-block">
        <a href={process.env.PUBLIC_URL+"/"}><img src="https://www.ligaepilepsia.cl/wp-content/uploads/2021/11/logo-blanco.svg" height={35} />
     </a>
       </div>
      <div className="same-style account-setting">

        {user ?
          user.photoURL ?
            <button
              className={"account-setting-active"}
              onClick={e => handleClick(e)}
            >
              <img src={user.photoURL} style={{ height: "44px", borderRadius: "50%" }} />
            </button>
            :

            <button
              className={"account-setting-active  iconos-menu "}
              onClick={e => handleClick(e)}
            >
              <i className={"pe-7s-user"} />
            </button>
          :
          <button
            className={"account-setting-active  iconos-menu "}
            onClick={e => handleClick(e)}
          >
            <i className={"pe-7s-user"} />
          </button>

        }

        <div className="account-dropdown">
          <ul>
            {
              !usuarioActivo ?
                <li>
                  <Link to={process.env.PUBLIC_URL + "/login"}>Login</Link>
                </li>
                : null
            }
            {
              !usuarioActivo ?
                <li>
                  <Link to={process.env.PUBLIC_URL + "/registro"}>
                    Registro
                  </Link>
                </li>
                : null
            }

            {
              usuarioActivo === true ?
                <li>
                  <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}>
                    Mis datos
                  </Link>
                </li>

                :

                null
            }
            {
              usuarioActivo ?
                <li>
                  <Link onClick={() => handleLogout()}>
                    Cerrar sesion
                  </Link>

                </li>

                :

                null
            }

          </ul>
        </div>
      </div>
      <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/comparacion"} className="iconos-menu">
          <i className="pe-7s-note2" style={{ paddingInline: "10px" }} />
          <span className="count-style">
            {compareItems && compareItems.length ? compareItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/favoritos"} className="iconos-menu">
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistItems && wishlistItems.length ? wishlistItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart iconos-menu" onClick={e => handleClick(e)}>
          <i className="pe-7s-cart" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart iconos-menu" to={process.env.PUBLIC_URL + "/carrito"}>
          <i className="pe-7s-cart" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none d-md-block">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <FontAwesomeIcon icon={faBars} style={{marginTop: "3px", padding: "10px", fontSize: "25px", width: "25px",  color: "#694b99", backgroundColor: "white", borderRadius: "30px" }} />
          {/* <i className="fa-bars" style={{ padding: "10px", fontSize: "25px", fontWeight: 600, color: "#545482", backgroundColor: "white", borderRadius: "30px" }} /> */}
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};



export default IconGroup;
