import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const userSlice = createSlice({
    name: "user",
    initialState: {
        usuario: {
            apellidos: "",
            direcciones: [],
            displayName: "",
            email: "",
            fono: "",
            nombre: "",
            origen: "",
            photoURL: "",
            rut: "",
            tipo: "",
            uid: "",
        }
        
    },
    reducers: {
        setUsuario(state, action){
            //console.log("se ejecuta setUser")
            //console.log(action.payload);
            state.user=action.payload
          
        },
        limpiaUsuario(state, action){
            //console.log("se ejecuta limpia usuario")
            //console.log(action.payload);
            //state.user = {}
            state.user = null;
        },
        updateCarroUser(state, action){
            //console.log("se ejecuta UpdateCarroUser")
            //console.log(action.payload);
            state.user.pedido_en_curso.carro = action.payload
        }

    
    },
});

export const {setUsuario, limpiaUsuario, updateCarroUser} = userSlice.actions;
export default userSlice.reducer;
