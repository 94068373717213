import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, FormCheck, FormSelect, Modal, Spinner } from "react-bootstrap";
import ModalDirecciones from "./ModalDirecciones";
import cogoToast from "cogo-toast";
import calcularDigitoVerificador from "../../helpers/rut";
import { useRut, isValid, unformat, format, } from 'react-rut';
import { isOnlyNumbers, validateEmail } from "../../helpers/validators";
import { generaPedido } from "../../firebase/firebase-methods";
import { creaPagoTbk, getNumeroPedido } from "../../firebase/firebase-functions";
import { updateDirecciones } from "../../store/slices/direcciones-slice";
import { connectStorageEmulator } from "firebase/storage";
import ModalidadModal from "../../components/modalidad/modalidadModal";
const Despacho = () => {
    const dispatch = useDispatch();
    const { arrDirecciones } = useSelector((state) => state.direcciones);
    const [direccionSeleccionada, setDireccionSeleccionada] = useState({})
    const [nuevaDireccionVisible, setNuevaDireccionVisible] = useState(false)
    const { cartItems } = useSelector((state) => state.cart);
    const [modalModalidadShow, setModalModalidadShow] = useState(false)

    const { user } = useSelector((state) => state.user)

    const [aceptaTerminos, setAceptaTerminos] = useState(false)
    const [mayorDeEdad, setMayorDeEdad] = useState(false)

    let { pathname } = useLocation();
    const [totalCarro, setTotalCarro] = useState(0)
    const [totalCarroSinDescuentos, setTotalCarroSinDescuentos] = useState(0)

    const [errorModalidadCiudad, setErrorModalidadCiudad] = useState("");

    const { modalidadSeleccionada } = useSelector((state) => state.modalidad)

    const [nombre, setNombre] = useState(user && user.nombre ? user.nombre : '')
    const [apellidos, setApellidos] = useState(user && user.apellidos ? user.apellidos : '')
    const [fono, setFono] = useState("+56 9 ")
    const [rut, setRut] = useState(user && user.rut ? user.rut : '')
    const [email, setEmail] = useState(user && user.email ? user.email : '')

    const handleCloseModalDireccion = () => {
        setNuevaDireccionVisible(false)
    }

    useEffect(() => {
        //setNombre(user.nombre)
        //setApellidos(user.apellidos)
        //setRut(user.rut)
        setFono(user && user.fono ? "+56 9 " + user.fono.slice(-8) : "+56 9 ")
        //setEmail(user.email)

    }, [user])


    const seleccionaDireccion = (direccion) => {
        //console.log("registraDireccion")


        setDireccionSeleccionada(direccion)

    }

    const [error, setError] = useState("");

    const procesarPago = async (modalidad, totalCarro) => {
        setError("")
        if (totalCarro === 0) {
            return cogoToast.error("Debes tener medicamentos en tu carro para continuar")
        }
        setLoading(true)
        //console.log("enviar pago")
        //console.log(modalidad)
        // //console.log(cartItems)
        // //console.log(totalCarro)
        //separar pedidos por rut, se debe extraer los rut diferentes del arreglo
        let arrRuts = []
        cartItems.forEach(cartItem => {
            //console.log("cada elemento del carro")
            //console.log(cartItem)
            arrRuts.push({ rut: cartItem.rut, nombre_pac: cartItem.pac_nombre, apellidos_pac: cartItem.pac_apellidos })
        })
        //console.log(arrRuts)
        //eliminar elementos repetidos del arregloRuts
        const setUnico = [...new Set(arrRuts.map(JSON.stringify))]
        arrRuts = Array.from(setUnico).map(JSON.parse)
        //console.log(arrRuts)
        let arrPedidos = []
        arrRuts.forEach((itemRut, index) => {
            //console.log("index--> " + index)
            //console.log(itemRut)
            let arrCarro = cartItems.filter(item => item.rut === itemRut.rut)
            if (index === arrRuts.length - 1 && modalidadSeleccionada.tipo === "despacho") {
                //agrego costo de despacho al primer carro del arreglo de ruts
                arrCarro.push({ cantidad: 1, precioFinal: modalidadSeleccionada.costo, precio: modalidadSeleccionada.costo, medicamento: { kinf: "70032", pres: 1, nombre_medicamento: modalidadSeleccionada.nombre } })
            }
            let totalCarroUnitario = 0
            arrCarro.forEach(item => totalCarroUnitario = totalCarroUnitario + (item.cantidad * item.precioFinal))
            let pedido = {
                rut: itemRut.rut,
                nombre: itemRut.nombre_pac,
                apellidos: itemRut.apellidos_pac,
                carro: arrCarro,
                total_carro: totalCarroUnitario
            }
            arrPedidos.push(pedido)
        })

        // //console.log("pedidos")
        // //console.log(arrPedidos)
        // //console.log(user.uid)
        //console.log(user)
        const numPedido = await getNumeroPedido()
        //console.log("numero pedido: " + numPedido.id)

        if (numPedido.id) {
            //console.log("numero recibido")
            //console.log(numPedido)
            let pedido = {
                fecha_pedido: new Date(),
                estado: "IMPAGO",
                total_pedido: totalCarro + modalidadSeleccionada.costo,
                idPedido: numPedido.id,
                email: email,
                datos_facturacion: {
                    rut: user.rut ? user.rut : unformat(rut).slice(0, -1),
                    nombre: nombre,
                    apellidos: apellidos,
                    telefono: fono,
                },
                direccion: direccionSeleccionada,
                pedidos: [...arrPedidos],
                uid: user.uid ? user.uid : 'invitado',
                modalidad: modalidadSeleccionada
            }

            //console.log(pedido)


            await generaPedido(pedido)
                .then(resultPedido => {
                    //console.log("result id")
                    //console.log(resultPedido.id)
                    if (resultPedido.id) {
                        creaPago(numPedido.id, totalCarro + modalidadSeleccionada.costo + (retiraReceta ? 3990 : 0), user.uid ? user.uid : "invitado", resultPedido.id)
                        //
                    }
                    else {
                        setError("Ocurrió un error al crear el pedido, intente nuevamente mas tarde")
                        setLoading(false)
                    }
                })
                .catch(error => {
                    //console.log(error)
                    setError("Ocurrió un error al crear el pedido, intente nuevamente mas tarde")
                    setLoading(false)
                })
        }
        else {
            setError("Ocurrió un error al crear el pedido, intente nuevamente mas tarde")
            setLoading(false)
        }




        // //console.log("result id")
        // //console.log(resultPedido.id)
        // creaPago(numPedido.id, totalCarro + modalidadSeleccionada.costo, user.uid ? user.uid : "invitado", resultPedido.id)


        //setLoading(false)
        // if (!user.uid) {
        //     //console.log("pedido como invitado")
        //     const resultPedidoInvitado = await generaPedidoInvitado(pedido)
        //     //console.log(result.id)
        //     creaPago(numPedido.id, totalCarro + modalidadSeleccionada.costo, "invitado", resultPedidoInvitado.id)

        //     //setLoading(false)
        // }
        // else {
        //     //console.log("procesar con uid: " + user.uid)
        //    const resultPedidoRegistrado = await generaPedidoRegistrado(user.uid, pedido)
        //    creaPago(numPedido.id, totalCarro + modalidadSeleccionada.costo, user.uid, resultPedidoRegistrado.id)


        // }


    }

    const creaPago = async (numPedido, totalPagar, uid, uidPedido) => {

        // console.log(numPedido)
        // console.log(totalPagar)
        // console.log(uid)
        // console.log("uid pedido")
        // console.log(uidPedido)

        let order = {
            buy_order: numPedido,
            session_id: uid,
            amount: totalPagar,
            return_url:
                'https://us-central1-farmstore-744f9.cloudfunctions.net/ResposePagoTbk2?idpedido=' +
                uidPedido +
                '&uuid=' +
                uid +
                '&amb=ventaonline.ligaepilepsia.cl',

        }
        await creaPagoTbk(order).then(response => {
            let url = response.data.url
            let token = response.data.token
            const enlacePago = `${url}?token_ws=${token}`;
            // console.log(enlacePago)
            // console.log(response.data.token)
            // console.log(response.data.url)
            setLoading(false)
           window.location.href = enlacePago
        })





    }

    const [loading, setLoading] = useState(false)


    const [retiraReceta, setRetiraReceta] = useState(false);

    const revisaRetiraReceta = () => {
        let retira = false;
        //console.log("reviso retira")
        try {
            cartItems.forEach(item => {
                //console.log(item)
                if (item.solicitaRetiro) {
                    retira = true
                }
            })
        } catch (error) {
            //console.log(error)
        }
        setRetiraReceta(retira)

    }

    useEffect(() => {
        setModalModalidadShow(false)
        console.log("reviso si modalidad seleccionada coincide con direccion seleccionada")
        console.log(modalidadSeleccionada)
        console.log(direccionSeleccionada)
        if (modalidadSeleccionada.tipo === "retiro") {
            //console.log("tipo retiro, return")
            setErrorModalidadCiudad("")

            return;
        }
        if (!direccionSeleccionada.direccion) {
            setErrorModalidadCiudad("Debe indicar la dirección de despacho")
            return;
        }

        if (modalidadSeleccionada && modalidadSeleccionada.ciudad !== direccionSeleccionada.ciudad) {
            if (modalidadSeleccionada.ciudad === "Santiago" && direccionSeleccionada.ciudad === "Maipo") {
                return setErrorModalidadCiudad("")
            }
            if (modalidadSeleccionada.ciudad === "Santiago" && direccionSeleccionada.ciudad === "Cordillera") {
                return setErrorModalidadCiudad("")
            }
            if (modalidadSeleccionada.ciudad === "Santiago" && direccionSeleccionada.ciudad === "Chacabuco") {
                return setErrorModalidadCiudad("")
            }
            console.log(direccionSeleccionada.ciudad)
            console.log(modalidadSeleccionada.ciudad)
            console.log("La modalidad de compra no coincide con la dirección de despach")
            setErrorModalidadCiudad("La modalidad de compra no coincide con la dirección de despacho")
            return;
        }
        setErrorModalidadCiudad("")

        return;
    }, [modalidadSeleccionada, direccionSeleccionada]);


    useEffect(() => {
        // Inicializar variables para el total con descuento y el total sin descuento
        let totalConDescuento = 0;
        let totalSinDescuento = 0;
        //console.log(modalidadSeleccionada)

        // Iterar sobre los elementos del carrito
        cartItems.forEach(item => {
            // Calcular el total con descuento y el total sin descuento para cada elemento
            const subtotalConDescuento = item.cantidad * item.precioFinal;
            const subtotalSinDescuento = item.cantidad * item.precio;

            // Acumular los subtotales en las variables totales
            totalConDescuento += subtotalConDescuento;
            totalSinDescuento += subtotalSinDescuento;
        });
        //revisaRetiraReceta()

        setTotalCarro(totalConDescuento)
        setTotalCarroSinDescuentos(totalSinDescuento)
    }, [cartItems])

    useEffect(() => {
        //console.log(arrDirecciones)

        try {
            console.log("recorro arreglo direcciones")
            console.log(arrDirecciones)
            arrDirecciones.forEach(element => {
                if (element.predeterminada) {
                    setDireccionSeleccionada(element)
                }
            })
        } catch (error) {
            console.log("reviso error direcciones")
            console.log(error)
            dispatch(updateDirecciones([]))
        }




    }, [arrDirecciones])

    return (
        <Fragment>
            <SEO
                titleTemplate="Despacho"
                description="Pagina de despacho de ecommerce liga chilena contra la epilepsia."
            />
            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Despacho", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                {
                    loading ?
                        <Modal
                            show={loading}
                            onHide={() => setLoading(false)}
                        >
                            <Modal.Header>
                                <Modal.Title> <Spinner style={{ color: "#545482 !important" }} animation="border" className="text-center text-primary" role="status" /> Generando pedido, por favor espere un momento...</Modal.Title>
                            </Modal.Header>

                        </Modal>
                        : null

                }
                <div className="compare-main-area pt-90 pb-100">
                    <div className="container">



                        <div className="row">

                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-8 col-xl-8">
                                {
                                    error ?
                                        <div className="mb-3" style={{ backgroundColor: "#fadddd" }}>
                                            <p className="text-danger fs-5 text-center">{error}</p>
                                        </div>
                                        : null
                                }

                                {modalidadSeleccionada.tipo === "despacho" ?
                                    modalidadSeleccionada.nombre_suc === "ERASMO ESCALA" ?
                                        <div>
                                            <p
                                                style={{ lineHeight: "16px", marginBottom: "10px" }}
                                            >
                                                {"El reparto de medicamentos a domicilio se hará efectivo en 48 horas a partir del día hábil siguiente de realizar la compra"}
                                            </p>
                                            <p
                                                style={{ lineHeight: "16px", marginBottom: "10px" }}
                                            >
                                                {"Para reparto en las comunas de Buin, Calera de tango, Paine, Pirque y San José de Maipo, este se realizará los días Lunes y Jueves"}
                                            </p>
                                            <p
                                                style={{ lineHeight: "16px", marginBottom: "10px" }}
                                            >
                                                <strong>
                                                    {"Sin cobertura en comuna La Pintana"}
                                                </strong>

                                            </p>
                                        </div>
                                        :
                                        <div>
                                            <p
                                                style={{ lineHeight: "16px", marginBottom: "10px" }}
                                            >
                                                {"El reparto de medicamentos a domicilio se hará efectivo en 48 horas habiles a partir del día hábil siguiente de realizar la compra. Las comunas sujetas a reparto corresponden a San Pedro de la Paz, Concepción, Hualpén y Talcahuano"}
                                            </p>

                                        </div>
                                    :
                                    <div>
                                        <p
                                            style={{ lineHeight: "16px", marginBottom: "10px" }}
                                        >{"Las compras realizadas antes de las 13 hrs, podrán ser  retiradas en la farmacia seleccionada a partir de las 15 hrs. del mismo día, compras efectuadas después de las 13 hrs. podrán estar disponibles al día hábil siguiente, a más tardar a las 12 hrs."}</p>
                                        <p
                                            style={{ lineHeight: "16px", marginBottom: "10px" }}
                                        >{"A su correo notificaremos cuando su pedido este listo para retiro."}</p>

                                    </div>

                                }
                                <div className="pb-4">
                                    <h3 className="cart-page-title">Datos del pedido</h3>
                                    <div className="row">
                                        <div className="col-6">
                                            <label>Nombre</label>
                                            <input
                                                type="text"

                                                value={nombre}
                                                onChange={text => setNombre(text.target.value)}
                                                //disabled={user && user.nombre ? true : false}
                                                style={nombre.length < 3 ? { borderColor: "red", borderStyle: "solid" } : {}}
                                            />

                                        </div>
                                        <div className="col-6">
                                            <label>Apellidos</label>
                                            <input
                                                type="text"
                                                value={apellidos}
                                                onChange={text => setApellidos(text.target.value)}
                                                //disabled={user.apellidos ? true : false}
                                                style={apellidos.length <= 3 ? { borderColor: "red", borderStyle: "solid" } : {}}
                                            />

                                        </div>
                                        <div className="col-6">
                                            <label>Rut</label>
                                            <input type="text"
                                                //value={format(rut + calcularDigitoVerificador(rut))}
                                                value={user && user.rut ? format(rut + (calcularDigitoVerificador(rut))) : format(rut)}
                                                //value={rut === user.rut && user.rut != "" ? format(rut + calcularDigitoVerificador(rut)) : format(rut)} 
                                                onChange={text => setRut(text.target.value)}
                                                //disabled={user.rut ? true : false}
                                                style={!isValid(rut + (calcularDigitoVerificador(rut) === '11' ? 0 : calcularDigitoVerificador(rut))) && !isValid(rut) ? { borderColor: "red", borderStyle: "solid" } : {}}
                                            />

                                        </div>

                                        <div className="col-6">
                                            <label>Teléfono</label>
                                            <input type="text"
                                                maxLength={14}
                                                defaultValue={"+ 56 9 "}
                                                value={fono}
                                                onChange={text => setFono("+56 9 " + text.target.value.substring(6))}
                                                style={fono.length !== 14 ? { borderColor: "red", borderStyle: "solid" } : {}}
                                            //disabled={user.fono ? true : false}
                                            />

                                        </div>
                                        <div className="col-12">
                                            <label>Email</label>
                                            <input type="email"
                                                className="text-secondary"
                                                value={email}
                                                onChange={text => setEmail(text.target.value)}
                                                disabled={user && user.email ? true : false}
                                                style={!validateEmail(email) ? { borderColor: "red", borderStyle: "solid" } : {}}
                                            />

                                        </div>
                                    </div>
                                </div>

                                {
                                    modalidadSeleccionada.tipo === "despacho" ?
                                        <div>

                                            <h3 className="cart-page-title">Selecciona tu dirección de despacho</h3>

                                            {
                                                nuevaDireccionVisible ? <ModalDirecciones showModalDireccion={nuevaDireccionVisible} handleClose={handleCloseModalDireccion} objDireccion={{}} /> : <div></div>
                                            }
                                            <div className="text-center pb-30">
                                                <Button

                                                    onClick={() => {
                                                        //console.log("nueva direccion")
                                                        setNuevaDireccionVisible(true)
                                                    }}
                                                    className="boton"
                                                >Agregar nueva direccion</Button>
                                            </div>

                                            {
                                                arrDirecciones && arrDirecciones.length === 0 ? <p>No tienes direcciones registradas</p> :

                                                    arrDirecciones.map((element, index) => (
                                                        <div key={index} className={direccionSeleccionada === element ? "box-direccion row  seleccionada" : "box-direccion row "} >
                                                            <div className="col-2 check" onClick={() => seleccionaDireccion(element)}>
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="direccion"
                                                                    value={index}
                                                                    checked={direccionSeleccionada === element}
                                                                    onClick={() => seleccionaDireccion(element)}
                                                                />
                                                            </div>

                                                            <div className="col-9" onClick={() => seleccionaDireccion(element)}>
                                                                <p className="principal">{element.alias}{element.predeterminada ? <span className="predeterminado">{" (Predeterminado)"}</span> : null}</p>
                                                                <p className="secundaria">{element.direccion + " " + element.dir2 + ", " + element.comuna + ", " + element.ciudad + ", " + element.region}</p>

                                                                <p className="terciario">{element.referencia}</p>
                                                            </div>

                                                        </div>
                                                    ))
                                            }
                                        </div>

                                        :
                                        <div>
                                            <h3 className="cart-page-title">{"Retiro en sucursal: "}{modalidadSeleccionada.nombre_suc}</h3>
                                            <div>
                                                <p>{"Tipo: "}{modalidadSeleccionada.nombre}</p>
                                                <p>{"Dirección: "}{modalidadSeleccionada.direccion}</p>
                                                <p>{"Horario de atención: "}{modalidadSeleccionada.horario}</p>
                                                <p>{"Teléfono: "}{modalidadSeleccionada.telefono}</p>
                                            </div>
                                        </div>

                                }


                            </div>

                            <div className="col-sm-12 col-xs-12 col-md-12 col-lg-4 col-xl-4">
                                <div className="box-carro pb-3 pt-3 ps-3 pe-3">
                                    {
                                        modalidadSeleccionada.tipo === "despacho" ?
                                            <div className="direccion pb-15">
                                                <h3 className="cart-page-title">Enviar pedido a:</h3>

                                                {!direccionSeleccionada.alias ?
                                                    <p>Sin dirección seleccionada</p>
                                                    :
                                                    <div>
                                                        <p>{"Alias: "}{direccionSeleccionada.alias}</p>
                                                        <p>{"Dirección: "}{direccionSeleccionada.direccion + " " + direccionSeleccionada.dir2 + ", " + direccionSeleccionada.comuna + ", " + direccionSeleccionada.ciudad + ", " + direccionSeleccionada.region}</p>
                                                        <p>{"Referencia: "}{direccionSeleccionada.referencia}</p>
                                                    </div>
                                                }
                                            </div>

                                            :

                                            <div className="direccion pb-15">
                                                <h3 className="cart-page-title">Retirar pedido en</h3>


                                                <div>
                                                    <p>{"Sucursal: "}{modalidadSeleccionada.nombre_suc}</p>
                                                    <p>{"Dirección: "}{modalidadSeleccionada.direccion}</p>
                                                    <p>{"Teléfono: "}{modalidadSeleccionada.telefono}</p>
                                                </div>

                                            </div>

                                    }



                                    <div className="carro">
                                        <h3 className="cart-page-title">Carro de compras</h3>
                                        {
                                            cartItems.map((element, index) => {
                                                //console.log(element)

                                                return (
                                                    <div key={index}>
                                                        <p>
                                                            {element.medicamento.nombre_medicamento} {" x "}{element.cantidad}
                                                            <span>{" $" + (element.cantidad * element.precioFinal).toLocaleString("es-CL")}</span></p>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                        {totalCarroSinDescuentos > totalCarro ?
                                            <p>{"Total descuentos: "}<span>{"$"}{(totalCarroSinDescuentos - totalCarro).toLocaleString("es-CL")}</span></p>
                                            : null}
                                        <p className="pb-0">{"Total medicamentos: "}<span>{"$" + totalCarro.toLocaleString("es-CL")}</span></p>
                                        <p className="pb-0">
                                            <strong>
                                                {modalidadSeleccionada.tipo === "despacho" ? "Valor despacho: " : "Retiro en sucursal: "}

                                            </strong>
                                            <span>{"$" + modalidadSeleccionada.costo.toLocaleString("es-CL")}</span></p>
                                        {/* {
                                        retiraReceta ? 
                                        <p className="pb-0">{"Retiro receta: "}<span>{"$ 3.990"}</span></p>
                                        : null

                                       } */}


                                        <p className="pb-0"><strong>{"Total a pagar: "}</strong><span style={{ fontWeight: 600, fontSize: "16px" }}>{"$" + (totalCarro + modalidadSeleccionada.costo + (retiraReceta ? 3990 : 0)).toLocaleString("es-CL")}</span></p>

                                        <p><FormCheck

                                            type="checkbox"
                                            inline
                                            style={{ marginRight: "4px", display: "inline-grid" }}
                                            checked={aceptaTerminos}
                                            onChange={() => setAceptaTerminos(!aceptaTerminos)}

                                        /> {"Acepto los "}
                                            <Link style={{ color: "#e58f2f" }}
                                                to={process.env.PUBLIC_URL + "/terminos-y-condiciones"}
                                                target="_blank"
                                            >
                                                {"términos y condiciones."}
                                            </Link>
                                        </p>

                                        <p><FormCheck

                                            type="checkbox"
                                            inline
                                            style={{ marginRight: "4px", display: "inline-grid", borderWidth: "2px", borderColor: "#694b99" }}
                                            checked={mayorDeEdad}
                                            onChange={() => setMayorDeEdad(!mayorDeEdad)}

                                        /> {"Confirmo que soy mayor de 18 años."}
                                        </p>


                                        <div className="text-center">
                                            {
                                                errorModalidadCiudad ?
                                                    <div className="text-start pb-3"

                                                    >

                                                        <p className="text-danger"
                                                            style={{ lineHeight: "20px" }}
                                                        >{errorModalidadCiudad}
                                                            <Button className="boton ms-0"
                                                                onClick={() => setModalModalidadShow(!modalModalidadShow)}
                                                            >Cambiar ubicación</Button>
                                                            <ModalidadModal
                                                                showModalidad={modalModalidadShow}
                                                                onHideModalidad={() => setModalModalidadShow(false)}
                                                            />
                                                        </p>
                                                        <p
                                                            style={{ lineHeight: "0px" }}
                                                        >{"Tipo: " + modalidadSeleccionada.nombre}</p>
                                                        <p
                                                            style={{ lineHeight: "20px" }}
                                                        >{"Ciudad de despacho: "}{direccionSeleccionada.ciudad}</p>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            <Button
                                                className="boton pb-3 pt-3 ps-3 pe-3"
                                                onClick={() => {


                                                    if (!nombre) {
                                                        return cogoToast.error("Debes ingresar un nombre")
                                                    }
                                                    if (!apellidos) {
                                                        return cogoToast.error("Debes ingresar un apellido")
                                                    }
                                                    if (!isValid(rut + calcularDigitoVerificador(rut)) && !isValid(rut)) {
                                                        return cogoToast.error("Debes ingresar un rut valido")
                                                    }
                                                    if (!isOnlyNumbers(fono.slice(-8)) || fono.length < 14) {
                                                        return cogoToast.error("Debes ingresar un teléfono válido")
                                                    }

                                                    if (!validateEmail(email)) {
                                                        return cogoToast.error("Debes ingresar un Email válido")
                                                    }
                                                    if (aceptaTerminos === false) {
                                                        return cogoToast.error("Debes aceptar los términos y condiciones para continuar")
                                                    }

                                                    if (mayorDeEdad === false) {
                                                        return cogoToast.error("Debes confirmar ser mayor de 18 años para continuar.")
                                                    }

                                                    if (modalidadSeleccionada.tipo === "retiro") {
                                                        return procesarPago(modalidadSeleccionada, totalCarro)
                                                    }
                                                    if (!direccionSeleccionada.alias) {
                                                        return cogoToast.error("Debes seleccionar una dirección para continuar")
                                                    }


                                                    //console.log("pagar " + (totalCarro + modalidadSeleccionada.costo))
                                                    procesarPago(modalidadSeleccionada, totalCarro)
                                                }}
                                                disabled={errorModalidadCiudad ? true : false}
                                            >{"Pagar ahora $:" + (totalCarro + modalidadSeleccionada.costo + (retiraReceta ? 3990 : 0)).toLocaleString("es-CL")}</Button>


                                        </div>

                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    )


}

export default Despacho;