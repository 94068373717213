import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button,  Modal, Spinner } from "react-bootstrap";
import { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { getDatosPedido } from "../../firebase/firebase-methods";
//import { useRut, isValid, unformat, format, } from 'react-rut';
//import calcularDigitoVerificador from "../../helpers/rut";
import { creaPagoTbk} from "../../firebase/firebase-functions";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faFacebook, faInstagram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { deleteAllFromCart } from "../../store/slices/cart-slice";




const Pago = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    let { pathname } = useLocation();
    const [loading, setLoading] = useState(false)
    const { user } = useSelector((state) => state.user)
    const [uidPedido, setUidPedido] = useState(null)
    const [pedido, setPedido] = useState({})
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const reintentarPago = () => {
        setLoading(true)
        console.log(pedido)
        console.log(user && user.uid ? user.uid : 'invitado')
        console.log(uidPedido)
        creaPago(pedido.idPedido, pedido.total_pedido, user && user.uid ? user.uid : 'invitado', uidPedido)
    }


    const creaPago = async (numPedido, totalPagar, uid, uidPedido) => {
        let order = {
            buy_order: numPedido,
            session_id: uid,
            amount: totalPagar,
            return_url:
                'https://us-central1-farmstore-744f9.cloudfunctions.net/ResposePagoTbk2?idpedido=' +
                uidPedido +
                '&uuid=' +
                uid +
                '&amb=ventaonline.ligaepilepsia.cl',
        }
        const response = await creaPagoTbk(order)

        let url = response.data.url
        let token = response.data.token
        const enlacePago = `${url}?token_ws=${token}`;
        console.log(enlacePago)
        setLoading(false)
        window.location.href = enlacePago
        // console.log(response.data.token)
        // console.log(response.data.url)



    }

    const [errorPago, setErrorPago] = useState(false);

    useEffect(() => {
        setLoading(true)
        console.log("params")

        //setUidPedido(params.get('pos'))
        const getDatos = async () => {
            console.log("get datos pedido")
            console.log("params")
            setLoading(true)

            let idPedidoObtenido = params.get('idPedido');
            console.log(idPedidoObtenido)
            if (idPedidoObtenido) {
                setUidPedido(idPedidoObtenido)
                let objPedido;
                objPedido = await getDatosPedido(idPedidoObtenido)
                if (objPedido.obtenido) {
                    try {
                        console.log("datos obtenidos")
                        setPedido(objPedido.data)

                        if (objPedido.data.estado === "PAGADO") {
                            //borro carro
                            dispatch(deleteAllFromCart())
                        }
                    } catch (error) {
                        console.log("error")
                        console.log(error)
                    }



                }
                else {
                    setErrorPago(true)
                }

                console.log(objPedido)
                setLoading(false)
            }
            else {
                setLoading(false)
                navigate("/")
            }

        }
        getDatos()
    }, [])

    return (
        <Fragment>
            <SEO
                titleTemplate="Pago"
                description="Pagina de pago de ecommerce liga chilena contra la epilepsia."
            />
            <LayoutOne headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Pago", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />
                {
                    loading ?
                        <Modal
                            show={loading}
                            onHide={() => setLoading(false)}
                        >
                            <Modal.Header>
                                <Modal.Title> <Spinner style={{ color: "#545482 !important" }} animation="border" className="text-center text-primary" role="status" /> Obteniendo datos de pedido</Modal.Title>
                            </Modal.Header>

                        </Modal>
                        : null

                }
                <div className="container pago">
                    <div>
                        {
                            pedido && pedido.idPedido ?
                                <div>
                                    {
                                        pedido.estado === "PAGADO" ?
                                            <div className="text-center d-flex col-12 justify-content-center pb-5">
                                                <div className="pago-exitoso text-center align-self-center col-xl-6 col-lg-6 col-md-10 col-sm-12 col-xs-12 col-12 pt-4">
                                                    <FontAwesomeIcon icon={faCircleCheck} className="icono" />
                                                    <h3 className="pt-2">{"Compra exitosa"}</h3>
                                                    <h5>{"Tu pedido ha sido recibido con éxito"}</h5>
                                                    <div className="text-start pt-3">
                                                        <div className="justify-content-center d-flex pb-4">
                                                            <div className="col-12">

                                                                <p><span>{"N° de pedido: "}{pedido.idPedido}</span></p>
                                                                <p><span>{"Email: "}</span>{pedido.email}</p>
                                                                <p><span>{"Forma de pago: "}</span>{pedido.detalle_pago.voucher.payment_type_code === "VD" ? "Tarjeta de Débito" : "Tarjeta de Crédito"}</p>
                                                                <p><span>{"Total: "}{"$" + pedido.total_pedido.toLocaleString("es-CL")}</span></p>
                                                                <p><span>{"Tipo de compra: "}</span>{pedido.modalidad.nombre}</p>

                                                                {pedido.modalidad.id === 1 ?
                                                                    <div>
                                                                        <p>
                                                                            {"El reparto de medicamentos a domicilio se hará efectivo en 48 horas a partir del día hábil siguiente de realizar la compra"}
                                                                        </p>
                                                                        <p>
                                                                            {"Para reparto en las comunas de Buin, Calera de tango, Paine, Pirque y San José de Maipo, este se realizará los días Lunes y Jueves"}
                                                                        </p>
                                                                        <p className="bold">
                                                                            {"Sin cobertura en comuna La Pintana"}
                                                                        </p>
                                                                    </div> :
                                                                    null
                                                                }
                                                                {
                                                                    pedido.modalidad.tipo === 'retiro' ?
                                                                        <div>
                                                                            <p>{"Las compras realizadas antes de las 13 hrs, podrán ser  retiradas en la farmacia seleccionada a partir de las 15 hrs. del mismo día, compras efectuadas después de las 13 hrs. podrán estar disponibles al día hábil siguiente, a más tardar a las 12 hrs."}</p>
                                                                            <p>{"A su correo notificaremos cuando su pedido este listo para retiro."}</p>

                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    pedido.modalidad.id === 3 ?
                                                                        <div>
                                                                            <p> {"El reparto de medicamentos a domicilio se hará efectivo en 48 horas habiles a partir del día hábil siguiente de realizar la compra. Las comunas sujetas a reparto corresponden a San Pedro de la Paz, Concepción, Hualpén y Talcahuano"}
                                                                            </p>

                                                                        </div>

                                                                        : null
                                                                }



                                                            </div>
                                                        </div>
                                                        <div className="carro">
                                                            <h3 className="cart-page-title">Carro de compras</h3>
                                                            {
                                                                pedido.pedidos.map((element, index) => {
                                                                    console.log(element)

                                                                    return (
                                                                        <div key={index}>
                                                                            {/* <p>{"Para paciente: "}{format(element.rut + calcularDigitoVerificador(element.rut)) + " " + element.nombre + " " + element.apellidos}</p>
                                                      
                                                       */}
                                                                            {
                                                                                element.carro.map((elementCarro, indexCarro) => {
                                                                                    console.log(elementCarro)
                                                                                    console.log(indexCarro)
                                                                                    return (

                                                                                        elementCarro.medicamento.kinf === "70032" ?
                                                                                            <div className="row pb-3">
                                                                                                <div className="col-9">
                                                                                                    <p className="text-capitalize detalle">{elementCarro.medicamento.nombre_medicamento.toLowerCase()} </p>

                                                                                                </div>
                                                                                                <div className="col-3 text-end">
                                                                                                    <p><span>{" $" + (elementCarro.precioFinal).toLocaleString("es-CL")}</span></p>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div className="row pb-3">
                                                                                                <div className="col-9">
                                                                                                    <p className="text-capitalize detalle">{elementCarro.medicamento.nombre_medicamento.toLowerCase() + " (" + elementCarro.medicamento.contenido.toLowerCase() + ")"}<span>{" x " + elementCarro.cantidad}</span> </p>
                                                                                                </div>
                                                                                                <div className="col-3 text-end">
                                                                                                    <p><span>{" $" + (elementCarro.precioFinal * elementCarro.cantidad).toLocaleString("es-CL")}</span></p>
                                                                                                </div>
                                                                                            </div>



                                                                                    )
                                                                                })
                                                                            }
                                                                            <p>

                                                                            </p>
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                            {/* {totalCarroSinDescuentos > totalCarro ?
                                            <p>{"Total descuentos: "}<span>{"$"}{(totalCarroSinDescuentos - totalCarro).toLocaleString("es-CL")}</span></p>
                                            : null}
                                        <p>{"Total medicamentos: "}<span>{"$" + totalCarro.toLocaleString("es-CL")}</span></p>
                                        <p>{"Valor despacho: "}<span>{"$" + modalidadSeleccionada.costo.toLocaleString("es-CL")}</span></p>
                                        <p>{"Total a pagar: "}<span>{"$" + (totalCarro + modalidadSeleccionada.costo).toLocaleString("es-CL")}</span></p> */}


                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            :
                                            <div className="text-center d-flex col-12 justify-content-center pb-5">
                                                <div className="pago-fallido text-center align-self-center col-xl-6 col-lg-6 col-md-10 col-sm-12 col-xs-12 col-12 pt-4">
                                                    <FontAwesomeIcon icon={faCircleXmark} className="icono" />
                                                    <h3 className="pt-2">{"Pago fallido"}</h3>
                                                    <h5>{"¿Necesitas ayuda? Contáctanos al "}<a href="tel:6006001515">600 300 1515</a></h5>
                                                    <div>
                                                        <p className="pt-3 " style={{ fontSize: 16, fontWeight: 600 }}>{"No se completó el proceso de pago"}</p>
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    reintentarPago()
                                                                }
                                                            }
                                                            title="Reintentar pago"
                                                            className="boton "
                                                        >
                                                            {"Reintentar pago"}
                                                        </Button>
                                                    </div>
                                                </div>

                                            </div>
                                    }





                                </div>

                                :

                                <div className="text-center d-flex col-12 justify-content-center pb-5">
                                    <div className="pago-fallido text-center align-self-center col-xl-6 col-lg-6 col-md-10 col-sm-12 col-xs-12 col-12 pt-4">
                                        {/* <FontAwesomeIcon icon={faCircleXmark} className="icono" /> */}
                                        <h3 className="pt-2">{"Pago fallido"}</h3>
                                        <h5>{"¿Necesitas ayuda? Contáctanos al "}<a href="tel:6006001515">600 300 1515</a></h5>
                                        <div>
                                            <p className="pt-3 " style={{ fontSize: 16, fontWeight: 600 }}>{"No se completó el proceso de pago"}</p>
                                            {/* <Button
                                                            onClick={
                                                                () => {
                                                                    reintentarPago()
                                                                }
                                                            }
                                                            title="Reintentar pago"
                                                            className="boton "
                                                        >
                                                            {"Reintentar pago"}
                                                        </Button> */}
                                        </div>
                                    </div>

                                </div>

                        }


                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    )

}

export default Pago;