import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const wishlistSlice = createSlice({
    name: "wishlist",
    initialState: {
        wishlistItems: []
    },
    reducers: {
        addToWishlist(state, action) {
            const isInWishlist = state.wishlistItems.findIndex(item => item.kinf2 === action.payload.kinf2);
            if(isInWishlist > -1){
                cogoToast.info("Medicamento ya en favoritos", {position: "bottom-left"});
            } else {
                state.wishlistItems.push(action.payload);
                cogoToast.success("Añadido a favoritos", {position: "bottom-left"});
            }
            
        },
        deleteFromWishlist(state, action){
            state.wishlistItems = state.wishlistItems.filter(item => item.kinf2 !== action.payload);
            cogoToast.error("Eliminado de favoritos", {position: "bottom-left"});
        },
        deleteAllFromWishlist(state){
            state.wishlistItems = []
        },
        replaceWishlist(state, action){
            state.wishlistItems = action.payload
        }
    },
});

export const { addToWishlist, deleteFromWishlist, deleteAllFromWishlist, replaceWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
