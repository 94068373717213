//import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';
//import { updatePedidoEnCursoFirebase } from '../../firebase/firebase-methods';

const { createSlice } = require('@reduxjs/toolkit');

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: []
    },
    reducers: {
        addToCart(state, action) {

            console.log("recibe")
            console.log(action.payload)
            //console.log(state.cartItems)
            // let carro = state.cartItems;
            // console.log("carro")
            // console.log(carro)
            const product = action.payload.medicamento;
            const paciente = action.payload.paciente;
            const cantidad = action.payload.cantidad;
            const precioFinal = action.payload.precioFinal ? action.payload.precioFinal : product.precio;
            const descuentoPac = action.payload.descuentoPac;
            const solicitaRetiro = action.payload.retiroReceta ? action.payload.retiroReceta : false
//console.log("paciente")
//console.log(paciente)

            //FALTA REVISAR MODALIDAD Y PACIENTE QUE COMPRA + PRECIO
            // console.log("producto");
            // console.log(product)
            // console.log("carro")
            // console.log(state.cartItems)


            //valida que el producto no esté en el carro
            const cartItem = state.cartItems.find(item => item.id === product.kinf2 || item.id === product.id);

            if (!cartItem) {
                //console.log("carro vacío")
                state.cartItems.push({
                    id: product.kinf2,
                    rut: paciente.rut,
                    pac_nombre: paciente.nombre,
                    pac_apellidos: paciente.apellidos,
                    nombre: product.nombre_medicamento,
                    cantidad: cantidad,
                    //quantity: 1,
                    precio: product.precio,
                    price: product.precio,
                    porc_descuento: descuentoPac ? descuentoPac : 0,
                    precioFinal: precioFinal,
                    //precio_desc: product.precio - product.precio * (descuentoPac / 100),
                    medicamento: product,
                    solicitaRetiro: solicitaRetiro
                    //stock: stockMed,
                })

                // si el medicamento esta en el carro debe validar que el paciente sea el mismo, si es el mismo, que aumente la cantidad, de lo contrario que lo agregue como otro elemento al carro
            } else if (cartItem !== undefined && cartItem.rut === paciente.rut) {
                //console.log("carro con producto")
                state.cartItems = state.cartItems.map(item => {
                    if (item.id === cartItem.id || item.id === cartItem.kinf2) {
                        return {
                            ...item,
                            cantidad: item.cantidad + cantidad,
                            quantity: item.quantity + 1,
                            price: item.price + product.precio,
                            precio: product.precio,
                            porc_descuento: descuentoPac ? descuentoPac: 0,
                    precioFinal: precioFinal,
                            //precio_desc: item.precio_desc + (product.precio - product.precio * (descuentoPac / 100)),
                        }
                    }
                    return item;
                })
            } else {
                //console.log("carro con producto de otro paciente")
                state.cartItems.push({
                    id: product.kinf2,
                    rut: paciente.rut,
                    pac_nombre: paciente.nombre,
                    pac_apellidos: paciente.apellidos,
                    nombre: product.nombre_medicamento,
                    cantidad: cantidad,
                    //quantity: 1,
                    precio: product.precio,
                    price: product.precio,
                    porc_descuento: descuentoPac ? descuentoPac : 0,
                    precioFinal: precioFinal,
                    solicitaRetiro: solicitaRetiro,
                    //precio_desc: product.precio - product.precio * (descuentoPac / 100),
                    medicamento: product,
                    //stock: stockMed,
                })
            }


            // console.log("carro actual")
            // console.log([state.cartItems])




            // if (!product.variation) {
            //     const cartItem = state.cartItems.find(item => item.id === product.id);
            //     if (!cartItem) {
            //         state.cartItems.push({
            //             ...product,
            //             quantity: product.quantity ? product.quantity : 1,
            //             cartItemId: uuidv4()
            //         });
            //     } else {
            //         state.cartItems = state.cartItems.map(item => {
            //             if (item.cartItemId === cartItem.cartItemId) {
            //                 return {
            //                     ...item,
            //                     quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1
            //                 }
            //             }
            //             return item;
            //         })
            //     }

            // } else {
            //     const cartItem = state.cartItems.find(
            //         item =>
            //             item.id === product.id &&
            //             product.selectedProductColor &&
            //             product.selectedProductColor === item.selectedProductColor &&
            //             product.selectedProductSize &&
            //             product.selectedProductSize === item.selectedProductSize &&
            //             (product.cartItemId ? product.cartItemId === item.cartItemId : true)
            //     );
            //     if (!cartItem) {
            //         state.cartItems.push({
            //             ...product,
            //             quantity: product.quantity ? product.quantity : 1,
            //             cartItemId: uuidv4()
            //         });
            //     } else if (cartItem !== undefined && (cartItem.selectedProductColor !== product.selectedProductColor || cartItem.selectedProductSize !== product.selectedProductSize)) {
            //         state.cartItems = [
            //             ...state.cartItems,
            //             {
            //                 ...product,
            //                 quantity: product.quantity ? product.quantity : 1,
            //                 cartItemId: uuidv4()
            //             }
            //         ]
            //     } else {
            //         state.cartItems = state.cartItems.map(item => {
            //             if (item.cartItemId === cartItem.cartItemId) {
            //                 return {
            //                     ...item,
            //                     quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
            //                     selectedProductColor: product.selectedProductColor,
            //                     selectedProductSize: product.selectedProductSize
            //                 }
            //             }
            //             return item;
            //         });
            //     }
            // }

            cogoToast.success("Añadido al carro", { position: "bottom-left" });
        },
        deleteFromCart(state, action) {
            const product = action.payload;
            //console.log(product)

            //elimina el objeto completo del carro
            state.cartItems = state.cartItems.filter(item => item.id !== product.id || item.rut !== product.rut);

            //state.cartItems = state.cartItems.filter(item => item.id !== product.id  && item.rut !== product.rut);

            cogoToast.error("Eliminado del carro", { position: "bottom-left" });

        },
        decreaseQuantity(state, action) {
            // console.log("disminuye")
            // console.log(action.payload)
            // console.log(state.cartItems)
            const product = action.payload;
            if (product.cantidad === 1) {
                state.cartItems = state.cartItems.filter(item => item.id !== product.id || item.rut !== product.rut);
                cogoToast.error("Eliminado del carro", { position: "bottom-left" });
            } else {
                state.cartItems = state.cartItems.map(item =>
                    item.id === product.id && item.rut === product.rut
                        ? { ...item, cantidad: item.cantidad - 1 }
                        : item
                );
                cogoToast.warn("Cantidad disminuida", { position: "bottom-left" });
            }


        },
        increaseQuantity(state, action) {
            // console.log("aumenta" )
            // console.log(action.payload)
       
            const product = action.payload;
            state.cartItems = state.cartItems.map(item =>
                item.id === product.id && item.rut === product.rut
                    ? { ...item, cantidad: item.cantidad + 1 }
                    : item
            );
            cogoToast.warn("Cantidad aumentada", { position: "bottom-left" });
            // if (product.cantidad === 1) {
            //     state.cartItems = state.cartItems.filter(item => item.id !== product.id || item.rut !== product.rut);
            //     cogoToast.error("Eliminado del carro", { position: "bottom-left" });
            // } else {
            //     state.cartItems = state.cartItems.map(item =>
            //         item.id === product.id && item.rut === product.rut
            //             ? { ...item, cantidad: item.cantidad + 1 }
            //             : item
            //     );
            //     cogoToast.warn("Cantidad aumentada", { position: "bottom-left" });
            // }


        },
        deleteAllFromCart(state) {
            //console.log("elimina todo")
            state.cartItems = []
        },
        updateCart(state, action) {
            state.cartItems = action.payload
        },
        updateCartElement(state, action) {
            // console.log("recibe")
            // console.log(action.payload)
            let rut = action.payload.rut;
            let id = action.payload.id;
            let index = action.payload.index;
            //guardo el action.payload en una variable y le quito la propiedad index
            let payload = action.payload;
            delete payload.index;
            // console.log("index")
            // console.log(index)
            // console.log("payload sin index")
            // console.log(payload)
            if (index >= 0) {
                //console.log("viene con index")
                state.cartItems[index] = payload;
                return
            }

            //modifica el elemento del arreglo que corresponde al producto y al rut del paciente
            if (action.payload.documento) {
                state.cartItems = state.cartItems.map(item => {
                    if (item.id === id && item.rut === rut) {
                        return {
                            ...item,
                            documento: action.payload.documento,
                            //precio_desc: action.payload.precio_desc,
                        }
                    }
                    return item;
                }
                )
            }
            // console.log("actualizado")
            // console.log(state.cartItems)

        }
    },
});

export const { addToCart, deleteFromCart, decreaseQuantity, increaseQuantity, deleteAllFromCart, updateCart, updateCartElement } = cartSlice.actions;
export default cartSlice.reducer;
