import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const bioequivalenteSlice = createSlice({
    name: "bioequivalente",
    initialState: {
        principioActivo: "",
        concentracion: ""
        
    },
    reducers: {
        limpiaPrincipioActivo(state, action){
            //console.log("se ejecuta limpia principio activo")
            //console.log(action.payload);
            state.principioActivo=""
            state.concentracion=""
        },

        setPrincipioActivoSeleccionado(state, action) {
            //console.log("se recibe set principio activo seleccionado")
            //console.log(action.payload)
            //se setea paciente seleccionado
            state.principioActivo = action.payload.principio_activo;
            state.concentracion = action.payload.concentracion;
            cogoToast.success("Filtrando por " + action.payload.principio_activo + "  " + action.payload.concentracion, { position: "bottom-left" })
        },
    
    },
});

export const { limpiaPrincipioActivo, setPrincipioActivoSeleccionado} = bioequivalenteSlice.actions;
export default bioequivalenteSlice.reducer;
