
import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');



const modalidadSlice = createSlice({
  name: "modalidad",
  initialState: {
    arrModalidad: [
      {
        id: 1,
        codigo: '02',
        nombre: 'Despacho a domicilio Santiago',
        tipo: 'despacho',
        costo: 3990,
        costo_retiro: 7980,
        direccion: 'Erasmo Escala 2220, Santiago, Región Metropolitana',
        horario: 'Lunes a Viernes de 8:00 a 19:30 y Sábado de 10:00 a 14:00 Hrs',
        nombre_suc: 'ERASMO ESCALA',
        telefono: '(+56) 22699 2288',
        presku: 'new_presku02',
        ciudad: "Santiago"
      },
      {
        id: 2,
        codigo: '10',
        nombre: 'Retiro en tienda Concepción',
        tipo: 'retiro',
        costo: 0,
        costo_retiro: 0,
        direccion: 'Angol 341, Concepción, Región del Biobío',
        horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
        nombre_suc: 'CONCEPCION',
        telefono: '(+56) 22699 2288',
        presku: 'new_presku10',
        ciudad: "Concepcion"
      },
      {
        id: 3,
        codigo: '10',
        nombre: 'Despacho a domicilio Concepción',
        tipo: 'despacho',
        costo: 3990,
        costo_retiro: 7980,
        direccion: 'Angol 341, Concepción, Región del Biobío',
        horario: 'Lunes a Viernes de 9:30 a 19:00 y Sábado de 10:00 a 14:00 Hrs',
        nombre_suc: 'CONCEPCION',
        telefono: '(+56) 22699 2288',
        presku: 'new_presku10',
        ciudad: "Concepcion"
      },
    ]
    ,
    modalidadSeleccionada: {
      id: 1,
      codigo: '02',
      nombre: 'Despacho a domicilio Santiago',
      tipo: 'despacho',
      costo: 3990,
      costo_retiro: 7980,
      direccion: 'Erasmo Escala 2220, Santiago, Región Metropolitana',
      horario: 'Lunes a Viernes de 8:00 a 19:30 y Sábado de 10:00 a 14:00 Hrs',
      nombre_suc: 'ERASMO ESCALA',
      telefono: '(+56) 22699 2288',
      presku: 'new_presku02',
      ciudad: "Santiago"
    }
  },
  reducers: {

    setModalidadSeleccionada(state, action) {
      //console.log("modalidad seleccionada")
      //console.log(action.payload)
      // //se setea modalidad seleccionada
      state.modalidadSeleccionada = action.payload;


      cogoToast.success("Modalidad " + action.payload.nombre + " seleccionada", { position: "bottom-left" })
    },



  },
});

export const { setModalidadSeleccionada } = modalidadSlice.actions;
export default modalidadSlice.reducer;
