import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
const MobileNavMenu = () => {
 
  const [arrCategorias, setArrCategorias] = useState([
    'ANTIINFECCIOSOS',
    'CARDIOMETABOLICOS',
    'DERMATOLÓGICOS',
    'DOLOR Y ANALGESICOS',
    'ENFERMEDADES ESPECIFICAS',
    'EPILEPSIA ANTICRISIS',
    'GASTROINTESTINALES',
    'HORMONAS',
    'HUESOS Y ARTICULACIONES',
    'INSUMOS',
    'OFTALMOLÓGICOS',
    'RESPIRATORIOS Y ALERGIAS',
    'SALUD FEMENINA',
    'SALUD MASCULINA',
    'SALUD MENTAL',
    'VITAMINAS - MINERALES - SUPLEMENTOS - HOMEPATICOS'
  ])

  const { user } = useSelector((state) => state.user);


  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      <ul>
        <li>
          <Link to={process.env.PUBLIC_URL + "/"}>{"INICIO"}</Link>
        </li>
      </ul>
      <ul>
        <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/"}>
            Mi cuenta
          </Link>
          {
                  !user || user.estado==="invitado" || user===null || user===undefined ?
                  <ul >
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/login"} className="sub-menu-mobile" >
                      {"-  Login"}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/registro"} className="sub-menu-mobile" >
                      {"-  Registro"}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/carrito"} className="sub-menu-mobile" >
                    {"-  Carro"}
                    </Link>
                  </li>

                  <li>
                    <Link to={process.env.PUBLIC_URL + "/favoritos"}  className="sub-menu-mobile" >
                    {"-  Lista de Favoritos"}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/comparacion"}  className="sub-menu-mobile" >
                    {"-  Comparación"}
                    </Link>
                  </li>
                  <li>
      
                    <Link to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"}  className="sub-menu-mobile" >
                    {"-  Seguimiento"}
                    </Link>
                  </li>
                </ul>
                :
                <ul >
                <li>
                  <Link to={process.env.PUBLIC_URL + "/mi-cuenta"} className="sub-menu-mobile" >
                    {"-  Mis Datos"}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/carrito"} className="sub-menu-mobile" >
                  {"-  Carro"}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}  className="sub-menu-mobile" >
                  {"-  Mis Recetas"}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/favoritos"}  className="sub-menu-mobile" >
                  {"-  Lista de Favoritos"}
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/comparacion"}  className="sub-menu-mobile" >
                  {"-  Comparación"}
                  </Link>
                </li>
                <li>
    
                  <Link to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"}  className="sub-menu-mobile" >
                  {"-  Seguimiento"}
                  </Link>
                </li>
              </ul>
}


        </li>
      </ul>
      <ul>
        <li className="menu-item-has-children">
          <Link >
            {"CATEGORÍAS"}
          </Link>
          <ul className="sub-menu">
            {
              arrCategorias.map((categoria, index) => {
                return (
                  <li key={index}>
                    <Link
                    className="text-capitalize"
                    to={`/medicamentos?categoria=${categoria}`} >
                      {categoria.toLowerCase()}
                    </Link>
                  </li>
                )
              })
            }

          </ul>
        </li>




      </ul>
    </nav>
  );
};

export default MobileNavMenu;
