import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Logo from "./Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const NavMenuNew = ({ menuWhiteClass, sidebarMenu }) => {


  const { user } = useSelector((state) => state.user);
  const [arrCategorias, setArrCategorias] = useState([
    'ANTIINFECCIOSOS',
    'CARDIOMETABOLICOS',
    'DERMATOLOGICOS',
    'DOLOR Y ANALGESICOS',
    'ENFERMEDADES ESPECIFICAS',
    'EPILEPSIA ANTICRISIS',
    'GASTROINTESTINALES',
    'HORMONAS',
    'HUESOS Y ARTICULACIONES',
    'INSUMOS',
    'OFTALMOLÓGICOS',
    'RESPIRATORIOS Y ALERGIAS',
    'SALUD FEMENINA',
    'SALUD MASCULINA',
    'SALUD MENTAL',
    'VITAMINAS - MINERALES - SUPLEMENTOS - HOMEPATICOS'
  ])

  useEffect(() => {
    // console.log("user menu")
    // console.log(user)
    return;
  }, [])
  return (
    <div
      className={clsx(sidebarMenu
        ? "sidebar-menu"
        : `main-menu`)}
    >
      <nav>
     

        <ul>
          <li>
            <Link  style={{ height: "100px", position: "relative", top: "-25px" }}>
              <div className="d-flex"><FontAwesomeIcon className="icon-menu" icon={faBars} 
            style={{alignSelf: "center" , padding: "10px", fontSize: "25px", width: "25px",  color: "#694b99", backgroundColor: "white", borderRadius: "30px" }}
            
            />&nbsp;
            {"  Menú"}</div>
            
              {/* <i className="pe-7s-menu icon-menu" />{"  Menú"} */}
            </Link>
            <ul className="mega-menu mega-menu-padding">
              <li>
                {
                  !user || user.estado==="invitado" || user===null || user===undefined ?
                  <ul>
                  <li className="mega-menu-title">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      Mi cuenta
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/login"} className="sub-menu-web" >
                      Login
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/registro"} className="sub-menu-web" >
                      Registro
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}  className="sub-menu-web" >
                      Mis Recetas
                    </Link>
                  </li> */}
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/favoritos"} className="sub-menu-web" >
                      Lista de Favoritos
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/comparacion"} className="sub-menu-web" >
                      Comparación
                    </Link>
                  </li>
                  <li>
                    <Link
                    to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"} 
                    className="sub-menu-web" >
                      Seguimiento
                    </Link>
                    {/* <Link to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"} className="sub-menu-web" >
                      Seguimiento
                    </Link> */}
                  </li>

                </ul>

                :

                <ul>
                <li className="mega-menu-title">
                  <Link to={process.env.PUBLIC_URL + "/"}>
                    Mi cuenta
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/mi-cuenta"} className="sub-menu-web" >
                    Mis Datos
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/carrito"} className="sub-menu-web" >
                    Carro
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}  className="sub-menu-web" >
                    Mis Recetas
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/favoritos"} className="sub-menu-web" >
                    Lista de Favoritos
                  </Link>
                </li>
                <li>
                  <Link to={process.env.PUBLIC_URL + "/comparacion"} className="sub-menu-web" >
                    Comparación
                  </Link>
                </li>
                <li>
                  <Link
                  to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"} 
                  className="sub-menu-web" >
                    Seguimiento
                  </Link>
                  {/* <Link to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"} className="sub-menu-web" >
                    Seguimiento
                  </Link> */}
                </li>

              </ul>
                }
                {/* <ul>
                  <li className="mega-menu-title">
                    <Link to={process.env.PUBLIC_URL + "/"}>
                      Mi cuenta
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/mi-cuenta"} className="sub-menu-web" >
                      Mis Datos
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/carrito"} className="sub-menu-web" >
                      Carro
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}  className="sub-menu-web" >
                      Mis Recetas
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/favoritos"} className="sub-menu-web" >
                      Lista de Favoritos
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/comparacion"} className="sub-menu-web" >
                      Comparación
                    </Link>
                  </li>
                  <li>
                    <Link
                    to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"} 
                    className="sub-menu-web" >
                      Seguimiento
                    </Link>
                    {/* <Link to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"} className="sub-menu-web" >
                      Seguimiento
                    </Link> 
                  </li>

                </ul> */}
              </li>
              
              <li >
                <ul>
                  <li className="mega-menu-title">
                    <Link>
                      Categorías
                    </Link>
                  </li>
                  {
                    arrCategorias.slice(0, 8).map((categoria, index) => {
                      return (
                        <li key={index}>
                          <Link  to={`/medicamentos?categoria=${categoria}`}  className="sub-menu-web text-capitalize" >
                            {categoria.toLowerCase()}
                          </Link>
                        </li>
                      )
                    })
                  }


                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-title">
                    <Link>
                      {"  "}
                    </Link>
                  </li>
                  {
                    arrCategorias.slice(8, 20).map((categoria, index) => {
                      return (
                        <li key={index}>
                          <Link  to={`/medicamentos?categoria=${categoria}`}  className="sub-menu-web text-capitalize" >
                            {categoria.toLowerCase()}
                          </Link>
                        </li>
                      )
                    })
                  }

                </ul>
              </li>
            </ul>

          </li>
          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              Medicamentos
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={process.env.PUBLIC_URL + "#"}>
                  Medicamentos Cenabast
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "#"}>
                  Medicamentos Importados
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/medicamentos"}>
                  Todos los Medicamentos
                </Link>
              </li>
            </ul>
          </li> */}

          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/seguimiento-de-pedidos"}>
              Seguimiento
            </Link>
          </li> */}


          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              Enlaces Regulatorios
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
           
            <ul className="submenu">
              <li>
                <Link to={"https://www.leychile.cl/Navegar?idNorma=1026879"} target="_blank">
                  DN° 3
                </Link>
              </li>
              <li>
                <Link to={"https://www.leychile.cl/Navegar?idNorma=13613"} target="_blank">
                  DS 466
                </Link>
              </li>
              <li>
                <Link to={"http://cituc.uc.cl/"} target="_blank">
                  Información Toxicológica
                </Link>
              </li>
              <li>
                <Link to={"https://www.minsal.cl/medicamentos_uso_racional/"} target="_blank">
                  Uso Racional de Medicamentos
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/#"}>
                 Cartas de Desabastecimiento
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "#"}>
                  Res. Comercio Electrónico Concepción
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "#"}>
                  Res. Comercio Electrónico Santiago
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/about"}>
                  Resol. Expendio Productos Refrigerados
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}>
              Mi cuenta
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}>
                  Mis Datos
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/carrito"}>
                  Carro
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}>
                  Mis Recetas
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/favoritos"}>
                  Lista de Favoritos
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/comparacion"}>
                  Comparación
                </Link>
              </li>
            </ul>
          </li> */}
          {/* <li>
            <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
              {t("blog")}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={process.env.PUBLIC_URL + "/blog-standard"}>
                  {t("blog_standard")}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/blog-no-sidebar"}>
                  {t("blog_no_sidebar")}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/blog-right-sidebar"}>
                  {t("blog_right_sidebar")}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/blog-details-standard"}>
                  {t("blog_details_standard")}
                </Link>
              </li>
            </ul>
          </li> */}

        </ul>
      </nav>
    </div>
  );
};

NavMenuNew.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
};

export default NavMenuNew;
