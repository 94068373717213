import cogoToast from "cogo-toast";
const { createSlice } = require('@reduxjs/toolkit');

const direccionesSlice = createSlice({
    name: "direcciones",
    initialState: {
        arrDirecciones: [],
    },
    reducers: {
        addDireccion(state, action) {
            //console.log("se recibe")
            //console.log(action.payload)
            state.arrDirecciones.push(action.payload)
            //console.log("arrDirecciones Actual");
            //console.log(state.arrDirecciones)
            //guarda el arreglo de direcciones en firebase
            cogoToast.success("Direccion añadida", { position: "bottom-left" });
        },
        deleteDireccion(state, action) {
            //console.log("deleteDireccion")
            //console.log(action.payload);
            state.arrDirecciones = state.arrDirecciones.filter((direccion) => direccion.alias !== action.payload.alias);
        },
        updateDirecciones(state, action) {
            //se recibe el arreglo completo y se actualiza el arreglo de direcciones
            //console.log("updateDireccion")
            //console.log(action.payload);
            state.arrDirecciones = action.payload;
            //console.log(state.arrDirecciones);
            // cogoToast.success("Direcciones actualizadas", { position: "bottom-left" });

        },
        //funcion para limpiar el arreglo de direcciones del state
        clearDirecciones(state, action) {
            //console.log("clearDirecciones")
            state.arrDirecciones = [];
        },
        updateDirecionesSinPredeterminada(state, action) {
            //recibe el index y se dejan todas las direcciones que no sean el index como no predeterminadas
            console.log("updateDirecionesSinPredeterminada")
            console.log(action.payload);
            console.log(state)
            
            console.log("index")
            if(action.payload.index === 0) {
                state.arrDirecciones= [action.payload.direccion]
            }
            else{
                state.arrDirecciones.forEach((direccion) => {
                    direccion.predeterminada = false;
                })
                state.arrDirecciones[action.payload.index]=action.payload.direccion;
            }
   
            
            
    }

    }});


export const { addDireccion, deleteDireccion, updateDirecciones, clearDirecciones, updateDirecionesSinPredeterminada } = direccionesSlice.actions;
export default direccionesSlice.reducer;
