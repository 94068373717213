// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

//importa getFunctions
import { getFunctions, httpsCallable } from "firebase/functions"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configurationF
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//importa y confugura firebase storage
import { getStorage, ref } from "firebase/storage"

import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";



const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASEURL,
  projectId: "farmstore-744f9",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_ENTERPRISE),
  isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});
const db = getFirestore(app)
export const auth = getAuth(app)
//const analytics = getAnalytics(app);

//exporta functions


export const functions = getFunctions();


//exporta storage
export const storage = getStorage(app)
export const storageRef = ref(storage, 'Humanos')
export const httpsCall = httpsCallable;
export default db;