const { createSlice } = require('@reduxjs/toolkit');

const registroSlice = createSlice({
    name: "registro",
    initialState: {
        usuarioRegistro: {
            email: "",
            nombre: "",
            apellidos: "",
            rut: "",
            fono: "",
            displayName: "",
            direcciones: []
        },

    },
    reducers: {
        setUsuarioRegistro(state, action) {
            //console.log("se ejecuta setUsuarioRegistro")
            //console.log(action.payload);
            state.usuarioRegistro = action.payload

        },
        limpiaUsuarioRegistro(state, action) {
            //console.log("se ejecuta limpia usuario registro")
            //console.log(action.payload);
            //state.user = {}
            state.usuarioRegistro = null;
        }


    },
});

export const { setUsuarioRegistro, limpiaUsuarioRegistro } = registroSlice.actions;
export default registroSlice.reducer;
