
import { setProducts, setProductsFull } from "../../store/slices/product-slice"

import { store } from "../../store/store";
import { useState, useEffect } from "react"
import { getDoc, doc } from "firebase/firestore"
import { useSelector } from "react-redux";

import db from '../../firebase/firebase-config'
//import { arrCategoryPap } from "../../data/maestro_categorias_pap";
import { contieneNumeros, extraeNumeros } from "../../helpers/validators";
import { useLocation } from "react-router-dom";
import { Loading } from "../../pages/other/Loading";
const ShopSearch = () => {

  //const [busqueda, setBusqueda] = useState("")
  const [medicamentos, setMedicamentos] = useState([])
  const [arrMedicamentosFull, setArrMedicamentosFull] = useState([])
  const { principioActivo } = useSelector((state) => state.bioequivalente);
  const { concentracion } = useSelector((state) => state.bioequivalente);
  const { modalidadSeleccionada } = useSelector((state) => state.modalidad)

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const obtenerDatos = async () => {


    //obtengo el arreglo de fotos
    const docRefFotos = doc(db, "publico", "fotos_medicamentos")
    const docSnapFotos = await getDoc(docRefFotos)
    let arrFotosFirebase = docSnapFotos.data().images_med


    //console.log()
    const docRef = doc(db, "publico", modalidadSeleccionada.presku)
    const docSnap = await getDoc(docRef)
    let arrPrevio = docSnap.data().sku

    for (const element of arrPrevio) {
      //console.log(element.kinf)
      try {
        let obj = arrFotosFirebase.find(e => e.kinf === element.kinf && e.kinf2.substr(-4)===element.kinf2.substr(-4))
        element.image = obj.image
      } catch (error) {
        //sin imagen
        element.image = ['/assets/img/sin_foto.png']
        //imagen actual
        //element.image=[element.img_url_0]
      }


    }
    //console.log("se asigna arreglo completo de medicamentos")
    arrPrevio.sort((a, b) => a.nombre_medicamento.toLowerCase() > b.nombre_medicamento.toLowerCase() ? 1 : -1,)


    //combino arrPrevio con arrCategoryPap cuando coincida el kinf y presentacion
    // arrPrevio.forEach((element, index) => {
    //   let obj = arrCategoryPap.find(e => e.kinf === element.kinf && e.pres === element.pres)
    //   if (obj) {
    //     arrPrevio[index].categorias = obj.categorias
    //     arrPrevio[index].pap_maestro = obj.programa
    //     arrPrevio[index].pap = obj.pap
    //   } else {
    //     arrPrevio[index].categorias = []
    //     arrPrevio[index].pap_maestro = false
    //     arrPrevio[index].pap = null
    //   }
    // })
//comentar para mostrar medicamentos sin foto
    arrPrevio = arrPrevio.filter(item => item.image[0] !== '/assets/img/sin_foto.png')

    if (principioActivo) {
      let filteredBioequivalente = arrPrevio.filter(
        medicamento =>
          medicamento.principio_activo == principioActivo &&
          medicamento.concentracion == concentracion &&
          (medicamento.bioequivalente == 'BE' ||
            medicamento.bioequivalente == 'REF'),
      );
      setMedicamentos(filteredBioequivalente)
      setArrMedicamentosFull(filteredBioequivalente)
      store.dispatch(setProducts(filteredBioequivalente));
    }
    else {
      setMedicamentos(arrPrevio)
      setArrMedicamentosFull(arrPrevio)
      store.dispatch(setProducts(arrPrevio));
    }




  }


  const searchFilter = (busqueda) => {
    //console.log('buscando por ' + busqueda);

    //separo busqueda en un arreglo
    let arregloBusqueda = busqueda.split(" ").filter(item => item != '')
    //console.log(arregloBusqueda)
    let nuevoArray = medicamentos;
    let newDataByNombre = []
    let newDataByPri = []
    let newDataByConcentracion = []
    arregloBusqueda.forEach(itemBusqueda => {
      if (!contieneNumeros(itemBusqueda)) {
        //no contiene numeros, asumo que es un nombre o principio activo
        newDataByNombre = nuevoArray.filter((item) => {
          const itemData = item.nombre_medicamento
            ? item.nombre_medicamento.toUpperCase()
            : ''.toUpperCase();
          const textData = itemBusqueda.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
        newDataByPri = nuevoArray.filter((item) => {
          const itemData = item.principio_activo
            ? item.principio_activo.toUpperCase()
            : ''.toUpperCase();

          const textData = itemBusqueda.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
        //creo arreglo con coincidencias por nombre y principio activo
        nuevoArray = newDataByNombre.concat(newDataByPri);
        //console.log("arreglo por nombres y principio activo")
        //console.log(nuevoArray)

        let hash = {};
        nuevoArray = nuevoArray.filter((obj) =>
          hash[obj.kinf2] ? false : (hash[obj.kinf2] = true),
        );
      } else {
        //contiene numeros, extraigo el texto y asumo que es una concentración

        newDataByConcentracion = nuevoArray.filter((item) => {
          const itemData = item.concentracion
            ? item.concentracion.toUpperCase()
            : ''.toUpperCase();
          const textData = itemBusqueda.toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
        nuevoArray = newDataByConcentracion;
        //console.log("arreglo por concentracion")
        //console.log(nuevoArray)

      }

    })



    nuevoArray = nuevoArray.filter(item=>!item.nombre_medicamento.includes("(IMP)"))


    store.dispatch(setProducts(nuevoArray));
    //setLoading(false)

  };

  const [loading, setLoading] = useState(true)
  const [textSearch, setTextSearch] = useState("")

  useEffect(() => {
    //console.log("useeffect")
    obtenerDatos().then(() => {
      let busqueda = params.get('busqueda')
      //console.log(busqueda)
      if (busqueda) {
        setTextSearch(busqueda)
      }
    })


  }, [principioActivo, modalidadSeleccionada])
  useEffect(() => {

    if (textSearch !== "") {
      setLoading(true)
      searchFilter(textSearch)
    }

    return;
  }, [])


  useEffect(() => {
    //console.log("se detecta cambio de arreglo medicamentos")
    searchFilter(textSearch)
    setInterval(() => setLoading(false), 3000)

    return;
  }, [textSearch, arrMedicamentosFull])

  return (
    <div className="sidebar-widget">
      {loading ?
        <Loading />
        : null}
      {/* <h4 className="pro-sidebar-title">Buscador </h4> */}
      <div className="pro-sidebar-search">
        <div className="pro-sidebar-search-form">
          <input type="text"

            value={textSearch} placeholder={"Nombre o principio activo..."} onChange={e => setTextSearch(e.target.value)} />


          <svg

            xmlns="http://www.w3.org/2000/svg" className="position-absolute search-icon" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ShopSearch;
