//crea un slice para las recetas, que contenga un arreglo de objetos recetas

import { createSlice } from "@reduxjs/toolkit";

const recetasSlice = createSlice({
    name: "recetas",
    initialState: {
        arrRecetas: [],
    },
    reducers: {
        setRecetas(state, action) {
            state.arrRecetas = action.payload;
        }
    }
});

export const { setRecetas } = recetasSlice.actions;
export default recetasSlice.reducer;
