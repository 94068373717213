import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addDireccion, updateDirecciones, updateDirecionesSinPredeterminada } from "../../store/slices/direcciones-slice";
import { useEffect, useState } from "react";
import PropTypes, { object } from "prop-types";
import cogoToast from "cogo-toast";

//ModalDirecciones recibe un objeto direccion y lo muestra en un modal
//se usa para mostrar la direccion seleccionada en el modal de direcciones


//google autocomplete
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { updateDireccionesFirebase, validaComuna } from "../../firebase/firebase-methods";
import { Loading } from "../other/Loading"

const ModalDirecciones = ({ showModalDireccion, handleClose, objDireccion }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const { arrDirecciones } = useSelector((state) => state.direcciones)
    //llama el state de usuario
    const { user } = useSelector((state) => state.user)



    const handleUpdateDirecciones = async () => {


        //console.log("se manda")
        //console.log(arrDirecciones)
        const resultado = await updateDireccionesFirebase(user.uid, arrDirecciones)
        //console.log(resultado)
        if (resultado.actualizado) {



            cogoToast.success("Direccion actualizada", { position: "bottom-left" });

            handleClose()

        } else {


            cogoToast.error("Error al actualizar las direcciones", { position: "bottom-left" });
            handleClose()
        }
        setLoading(false)


    }

    const [errorAlias, setErrorAlias] = useState(false);
    const [errorLongDireccion, setErrorLongDireccion] = useState(false);

    const handleGuardarDireccion = () => {

        if (long_direccion === "" || long_direccion === undefined) {
            setErrorLongDireccion(true)
            //cogoToast.error("Debe ingresar una direccion", { position: "bottom-left" });
            return;
        }
       
        setErrorLongDireccion(false)

        if (alias === "" || alias === undefined) {
            setErrorAlias(true)
            //cogoToast.error("Debe ingresar un alias", { position: "bottom-left" });
            return;
        }
        setErrorAlias(false)

        //setLoading(true)
        //se crea un objeto con los datos de la direccion modificada
        //console.log("handleGuardarDireccion")
        //console.log(arrDirecciones)
        const direccionModificada = {
            alias: alias,
            ciudad: ciudad,
            comuna: comuna,
            dir2: dir2 ? dir2 : "",
            direccion: direccion,
            latitude: latitude,
            long_direccion: long_direccion,
            longitude: longitude,
            referencia: referencia ? referencia : "",
            region: region,
            predeterminada: predeterminada ? true : false,
            //index: objDireccion.index
        }
        // console.log("direccion modificad");
        // console.log(direccionModificada)


        //se modifica el arreglo de direcciones
        let arrDireccionesMod = arrDirecciones ? [...arrDirecciones] : []
        //console.log("ob index")
        //console.log(objDireccion.index)

        if (objDireccion.index===undefined) {
            arrDireccionesMod[arrDirecciones.length] = direccionModificada
            
        }
        else {
            arrDireccionesMod[objDireccion.index] = direccionModificada
        }





        if (predeterminada) {
            //console.log("predeterminada")
            // //modificar todas las direcciones para que no sean predeterminadas sin foreach
            // arrDireccionesMod = arrDireccionesMod.map((direccion) => {
            //         direccion.predeterminada = false
            //        return direccion;
            //     })

            // console.log(arrDireccionesMod)
            dispatch(updateDirecionesSinPredeterminada({ direccion: direccionModificada, index:  objDireccion.index===undefined ? arrDirecciones.length : objDireccion.index }))
        }
        else {
            dispatch(updateDirecciones(arrDireccionesMod))

        }
        cogoToast.success("Datos actualizados", { position: "bottom-left" });
        handleClean()
    }

    //se crea un estado para guardar los datos de la direccion modificada
    const [alias, setAlias] = useState(objDireccion.alias)
    const [ciudad, setCiudad] = useState(objDireccion.ciudad)
    const [comuna, setComuna] = useState(objDireccion.comuna)
    const [dir2, setDir2] = useState(objDireccion.dir2)
    const [direccion, setDireccion] = useState(objDireccion.direccion)
    const [latitude, setLatitude] = useState(objDireccion.latitude)
    const [long_direccion, setLong_direccion] = useState(objDireccion.long_direccion)
    const [longitude, setLongitude] = useState(objDireccion.longitude)
    const [referencia, setReferencia] = useState(objDireccion.referencia)
    const [region, setRegion] = useState(objDireccion.region)
    const [predeterminada, setPredeterminada] = useState(objDireccion.predeterminada)



    //autocomplete
    const [googleAutocompleteValue, setGoogleAutocompleteValue] = useState(null);

    const handleClean = () => {
        //console.log("handleClean")
        setGoogleAutocompleteValue(null)
        setDireccion("")
        setComuna("")
        setCiudad("")
        setRegion("")
        setLatitude("")
        setLongitude("")
        setLong_direccion("")
        setReferencia("")
        setDir2("")
        setPredeterminada(false)
        handleClose()
    }

    const handleEliminarDireccion = (index) => {
        //console.log("handleEliminarDireccion")
        //console.log(index)
        //muestra un mensaje de confirmacion con si o no para eliminar el objeto del arreglo
        const { hide } = cogoToast.info(
            <div className="entries-edit-delete text-center p-2">
                <button className="boton-direcciones edit m-2 col-4" onClick={() => {
                    //console.log(index)
                    //console.log(direccion);
                    hide()
                }} >No</button>
                <button
                    className="boton-direcciones delete m-2 col-4"
                    onClick={() => {
                        //console.log(index);
                        //borra la direccion del arreglo de direcciones con el indice
                        //pregunta si es el ultimo elemento del arreglo, si es asi, no debe permitir borrar, si no es el ultimo, si debe permitir borrar

                        if (arrDirecciones.length > 1) {
                            //elimina la direccion del arreglo de direcciones
                            let arrayDirecciones = [...arrDirecciones]
                            arrayDirecciones.splice(index, 1)
                            //actualiza el arreglo de direcciones
                            handleUpdateDirecciones(arrayDirecciones)
                            hide()
                            setTimeout(() => {
                                cogoToast.success("Dirección eliminada", { position: "bottom-left" });

                            }, 100);
                        } else {

                            setTimeout(() => {
                                cogoToast.error("Debes tener al menos una dirección", { position: "bottom-left" });

                            }, 100);
                            hide()
                        }


                    }}
                >Si, borrar</button>
            </div>,
            {
                position: "top-center",
                heading: "¿Estás seguro que deseas eliminar esta dirección?",
                hideAfter: 0,
            }
        );

    }


    const handleGoogleAutocomplete = async (e) => {
        //console.log("handleGoogleAutocomplete")
        //console.log(e)
        //handleClean()
        let objDireccion = {
            comuna: "",
            region: ""
        }
       //console.log(e.value)

        setLong_direccion(e.value.description)
        await geocodeByPlaceId(e.value.place_id)
            .then(async results => {
              //console.log(results)
                //console.log(results[0].address_components)
                //busca en el arreglo de address_components el objeto que tenga el tipo route y asigna el valor a la variable direccion mas el numero
                let direccion = results[0].address_components.find((obj) => obj.types[0] === "route").long_name + " " + results[0].address_components.find((obj) => obj.types[0] === "street_number").long_name
                //console.log(direccion)
                setDireccion(direccion)
                //agrega el campo comuna, ciudad y region desde address_components
                let comuna = results[0].address_components.find((obj) => obj.types[0] === "administrative_area_level_3").long_name
                //console.log(comuna)
                objDireccion.comuna = comuna
                setComuna(comuna)
                let ciudad = results[0].address_components.find((obj) => obj.types[0] === "administrative_area_level_2").long_name
                //console.log(ciudad)
                setCiudad(ciudad)
                let region = results[0].address_components.find((obj) => obj.types[0] === "administrative_area_level_1").long_name
                //console.log(region)
                objDireccion.region = region
                setRegion(region)
              //console.log("region")
              //console.log(region)
                //agrega el campo latitud y longitud desde geometry
                let latitude = results[0].geometry.location.lat()
                //console.log(latitude)
                setLatitude(latitude)
                let longitude = results[0].geometry.location.lng()
                //console.log(longitude)
                setLongitude(longitude)

            })
            .catch(error => {
                console.error(error)
                cogoToast.error("Error en la dirección, asegurate que contenga un nombre de calle y número válido", { position: "top-center" });
                setGoogleAutocompleteValue(null)
                setDireccion("")
                setComuna("")
                setCiudad("")
                setRegion("")
                setLatitude("")
                setLongitude("")
                setLong_direccion("")
                setPredeterminada(false)
            })
            .finally(async () => {
                //console.log("finally")
                //console.log("comuna" + comuna)
                //console.log("region" + region)


                const result = await validaComuna(objDireccion)

                //console.log("comunaValida")
                //console.log(result)
                try {
                    if (result.error) {
                        cogoToast.error("Error al validar dirección, por favor intente mas tarde", { position: "top-center" });

                    }
                } catch (error) {
                    //console.log(error)
                }


                if (result.comunaValida) {
                    cogoToast.success("Dirección válida", { position: "top-center" });
                    setGoogleAutocompleteValue(e)
                } else {
                    cogoToast.error("Lo sentimos, por el momento no tenemos cobertura para tu dirección.", { position: "top-center", hideAfter: 10 });
                    setGoogleAutocompleteValue(null)
                    setDireccion("")
                    setComuna("")
                    setCiudad("")
                    setRegion("")
                    setLatitude("")
                    setLongitude("")
                    setLong_direccion("")
                    setPredeterminada(false)
                }
            }


            )



    }




    // useEffect(() => {
    //     handleClean()
    // }, [])

    return (
        <Modal show={showModalDireccion} onHide={handleClean}
            //dialogClassName="bg-light"
            //agrega la clase bootstrap para que se vea con fondo gris transparente
            contentClassName="bg-light"
            dialogClassName="p-0 my-2"
        >
            <Modal.Header closeButton>
                <Modal.Title>Editar direccion</Modal.Title>
            </Modal.Header>
            <Modal.Body

            >
                {loading ? <Loading /> : null}

                {/* muestra una tabla de dos columnas con bootstrap para mostrar los datos del objeto direcciones en un input*/}
                <table className="col-12 text-center">
                    <tbody>
                        <tr className="col-12">
                            <td className="col-12 text-center pb-3">
                                <p className="fw-bold">Ingresa tu direccion completa</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="col-12 text-">
                    <tbody>
                        <tr>
                            <td className="col-12"
                            style={errorLongDireccion ? {borderColor: "red", borderWidth: "2px"} : null}
                            >
                                <GooglePlacesAutocomplete
                                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                    //agrega los props necesarios para que muestre calle, numero, comuna y region
                                    selectProps={{
                                        googleAutocompleteValue,
                                        onChange: handleGoogleAutocomplete,
                                        placeholder: "Ingresa tu direccion",
                                        noOptionsMessage: () => "No se encontraron resultados",
                                        loadingMessage: () => "Buscando...",
                                    }}
                                    apiOptions={{
                                        language: "es", region: "cl"
                                    }}
                                    autocompletionRequest={{
                                        componentRestrictions: {
                                            //agrega restricciones para que solo busque en chile y que devuelva la direccion completa
                                            country: ["cl"],

                                        },
                                    }}
                                    debounce={500}
                                    minLengthAutocomplete={5}
                                    className="form-control"
                                    
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="col-12">
                    <tbody>
                        <tr className="col-12 text-center">

                            <p className="fw-bold py-3">Confirma los datos de la dirección</p>

                        </tr>
                    </tbody>
                </table>
                <table className="col-12">


                    <tbody>

                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Direccion</p>
                            </td>
                            <td>
                                <input
                                    type="text"
                                    readOnly
                                    disabled
                                    className="text-secondary"
                                    value={direccion ? direccion : objDireccion.direccion}
                                //onChange={(e) => setDireccion(e.target.value)} 
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Comuna</p>
                            </td>
                            <td className="col-6">
                                <input type="text"
                                    readOnly
                                    disabled
                                    className="text-secondary"
                                    value={comuna ? comuna : objDireccion.comuna}

                                />


                            </td>
                        </tr>
                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Ciudad</p>
                            </td>
                            <td className="col-6">
                                <input type="text"
                                    readOnly
                                    disabled
                                    className="text-secondary"

                                    value={ciudad ? ciudad : objDireccion.ciudad}


                                />

                            </td>
                        </tr>
                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Region</p>
                            </td>
                            <td className="col-6">
                                <input type="text"
                                    readOnly
                                    disabled
                                    value={region ? region : objDireccion.region}
                                    className="text-secondary"


                                />

                            </td>
                        </tr>
                    </tbody>
                </table>
                <table className="col-12">
                    <tbody>
                        <tr className="col-12 text-center">

                            <p className="fw-bold py-3">Agrega datos de referencia</p>

                        </tr>
                    </tbody>
                </table>
                <table className="col-12">

                    <tbody>
                        <tr>

                            {/*agrega un parrafo centrado */}


                            <td className="col-6">
                                <p className="fw-bold">Alias</p>
                            </td>
                            <td className="col-6">
                                <input type="text"
                                    //agrega clase boostrap para que el fonde del imput sea blanco
                                    className="bg-white"
                                    contentEditable={true}
                                    defaultValue={alias ? alias : objDireccion.alias}
                                    onChange={(e) => setAlias(e.target.value)} 
                                    style={errorAlias ? {borderColor: "red", borderWidth: "2px"} : null}
/>
                            </td>
                        </tr>
                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Dpto / Complemento</p>
                            </td>
                            <td className="col-6">
                                <input type="text"
                                    className="bg-white"
                                    contentEditable={true}
                                    defaultValue={dir2 ? dir2 : objDireccion.dir2}
                                    onChange={(e) => setDir2(e.target.value)}


                                />


                            </td>
                        </tr>
                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Referencia</p>
                            </td>
                            <td className="col-6">
                                <input
                                    type="text"
                                    maxLength={30}
                                    className="bg-white"
                                    contentEditable={true}
                                    defaultValue={referencia ? referencia : objDireccion.referencia}
                                    onChange={(e) => setReferencia(e.target.value)} />

                            </td>
                        </tr>

                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Dirección predeterminada</p>
                            </td>
                            <td className="col-6">
                                <input
                                    type="checkbox"
                                    className="bg-white"
                                    value={predeterminada}
                                    checked={predeterminada}
                                    //value={predeterminada ? predeterminada : objDireccion.predeterminada}
                                    onChange={() => setPredeterminada(!predeterminada)} />

                            </td>
                        </tr>
                        {/* <tr>
                            <td className="col-6">
                                <p className="fw-bold">Latitud</p>
                            </td>
                            <td className="col-6">
                                <input type="text"

                                    //contentEditable={true}
                                    value={latitude}
                                //defaultValue={objDireccion.latitude}
                                // onChange={(e) => setLatitude(e.target.value)} 
                                />

                            </td>

                        </tr>
                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Longitud</p>
                            </td>
                            <td className="col-6">
                                <input type="text"

                                    //contentEditable={true}
                                    value={longitude}
                                //defaultValue={objDireccion.longitude}
                                // onChange={(e) => setLongitude(e.target.value)} 
                                />

                            </td>
                        </tr>
                        <tr>
                            <td className="col-6">
                                <p className="fw-bold">Longitud direccion</p>
                            </td>
                            <td className="col-6">
                                <input type="text"

                                    //contentEditable={true}
                                    value={long_direccion}
                                //defaultValue={objDireccion.long_direccion}
                                // onChange={(e) => setLong_direccion(e.target.value)} 

                                />

                            </td>
                        </tr> */}
                        {/*agrega un boton que muestre el objeto modificado*/}

                    </tbody>
                </table>
                <table className="text-center col-12">
                    <tbody >


                        <tr>
                            <td className="col-6 text-center pt-4 pb-2 ">
                                <button onClick={() => handleEliminarDireccion(objDireccion.index)}
                                    className="boton-direcciones"
                                >Eliminar direccion</button>
                            </td>
                            <td className="col-6 text-center pt-4 pb-2">
                                {/*boton que guarde la direccion modificada */}
                                <button onClick={() => handleGuardarDireccion()}
                                    className="boton-direcciones edit"


                                >Guardar cambios</button>

                            </td>

                        </tr>


                    </tbody>
                </table>

            </Modal.Body>

        </Modal>
    )
}

ModalDirecciones.propTypes = {

    showModalidad: PropTypes.bool,

    objDireccion: PropTypes.object,

}

export default ModalDirecciones;