import { useState, useEffect } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getMedicamentos } from "../../../firebase/firebase-methods";
import { setProducts, setProductsFull } from "../../../store/slices/product-slice";
import ShopSearch from "../../product/ShopSearch";
const AutoCompleteMed = () => {

  const navigate = useNavigate();
  const { productsFull } = useSelector((state) => state.product);

  useEffect(() => {
    const cargaMedicamentos = async () => {
      if (productsFull.length === 0) {
        //console.log("sin meddicamentos")
        const response = await getMedicamentos()
        dispatch(setProducts(response))
        dispatch(setProductsFull(response))
      }
    }

cargaMedicamentos()



    return;
  }, [])

  const dispatch = useDispatch();
  const pathname = useLocation()
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    // console.log("on search")
    // console.log(string)
    // console.log(textString)
    setTextString(string)
    if (string === textString) {
      //console.log("enter presionado")
      //console.log(string)
      navigate(process.env.PUBLIC_URL + "/medicamentos?busqueda=" + string)
    }
  }

  const [textString, setTextString] = useState("")

  const handleOnHover = (result) => {
    // the item hovered
    //console.log("hover")
    //console.log(result)
  }

  const handleOnSelect = (item) => {
    // the item selected
    //console.log(item)
    navigate(`/product/${item.kinf2}`);

  }

  const handleOnFocus = () => {
    //console.log('Focused')
  }

  const formatResult = (item) => {
    return (
      <div className="row" key={item.id} style={{ height: "70px" }}>
        <div className="col-9 align-self-center text-align-start text-wrap text-capitalize">
          <p style={{ fontSize: "16px", fontWeight: 500, color: "#545482", padding: "0px !important",  lineHeight: "4px" }}>{item.nombre_medicamento.toLowerCase().substring(0, 22) + (item.nombre_medicamento.length > 22 ? "..." : "")}</p>
          <p style={{ fontSize: "14px", fontWeight: 400, color: "#3e6090", padding: "0px !important", lineHeight: "2px" }}>{item.principio_activo.toLowerCase().substring(0, 18) + " " + item.concentracion.toLowerCase().substring(0, 18)}</p>
          <p style={{ fontSize: "12px", fontWeight: 220, padding: "0px !important", lineHeight: "2px" }}>{item.contenido.toLowerCase().substring(0, 22)}</p>
        </div>
        <div className="col-3 align-self-center">
          <img src={item.image[0]} style={{ height: 50, width: 50, paddingRight: "-10px" }} alt="medicamento" />
        </div>
      </div>

    )
  }


  return (
    <div>
      {
        pathname.pathname !== "/medicamentos" ?
          productsFull.length > 0 ?

            <ReactSearchAutocomplete
              items={productsFull.map(item => ({ ...item, id: item.kinf2 }))}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              onFocus={handleOnFocus}
              //autoFocus
              showNoResultsText="Sin resultados"
              //style={{ margin: "0px" }}
              formatResult={formatResult}
              fuseOptions={{ keys: ["nombre_medicamento", "principio_activo"], minMatchCharLength: 5 }}
              //styling={{ zIndex: 1000 }}
              resultStringKeyName="nombre_medicamento"
              className="buscador-superior"
              showIcon={true}

              placeholder="Encuentra tu medicamento"
              styling={{

                zIndex: 10,

                border: "1px solid white",
                borderRadius: "22px",
                backgroundColor: "white",
                boxShadow: "none",
                // hoverBackgroundColor: "lightgreen",
                // color: "darkgreen",
                // fontSize: "12px",
                // fontFamily: "Courier",
                iconColor: "#545482",
                // lineColor: "lightgreen",
                //placeholderColor: "darkgreen",
                clearIconMargin: "3px 8px 0 0",
                height: "32px"

              }}

            />


            :
            
            null
            :

            <ShopSearch/> 
      }
    </div>





    // <form className="pro-sidebar-search-form">
    //     <input className="buscador-superior" type="text" placeholder="Encuentra tu medicamento o principio activo..." onChange={e => console.log(e.target.value)} />
    //   </form>
  )

}

export default AutoCompleteMed;