import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import clsx from "clsx";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";
import NavMenuNew from "../../components/header/NavMenuNew";
import AutoCompleteMed from "../../components/header/sub-components/AutoCompleteMed";
import { useLocation } from "react-router-dom";

const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
const pathname = useLocation()
  useEffect(() => {
    //console.log("ubicacion header")
    //console.log(pathname.pathname)
    const header = document.querySelector(".sticky-bar");
    setHeaderTop(header.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header className={clsx("header-area clearfix", headerBgClass, headerPositionClass)}
      style={{ backgroundColor: "#ffffff" }}
    >
      {/* <div
        className={clsx(
          "header-top-area", 
          headerPaddingClass, 
          borderStyle === "fluid-border" && "border-none" 
        )}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
         
          <HeaderTop borderStyle={borderStyle} />
        </div>
      </div> */}

      <div
        className={clsx(
          headerPaddingClass,
          "sticky-bar header-res-padding clearfix fondo-gradiente",
          scroll > headerTop && "stick"
        )}
        style={{ backgroundColor: "#545482" }}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          {/* <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4">
             
              <Logo imageUrl="https://www.ligaepilepsia.cl/wp-content/uploads/2021/11/logo-blanco.svg" logoClass="logo" />
            </div>
            <div className="col-xl-10 col-lg-10 d-none d-lg-block">
            
              <NavMenu />
             
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-8">            
              <IconGroup />
            </div>
          </div> */}
          <div className="row">
            <div className={"align-self-center col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12 d-md-block d-lg-block d-xl-block d-xxl-block d-none order-first"}>


              <Logo imageUrl="https://www.ligaepilepsia.cl/wp-content/uploads/2021/11/logo-blanco.svg" logoClass="logo" />

            </div>
            <div className="align-self-center col-xxl-2 col-xl-2 col-lg-2 col-2 d-xxl-block d-xl-block d-lg-block d-none order-2" >

             
                <NavMenuNew />
              

            </div>
            <div className="align-self-center col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12  order-xxl-3 order-xl-3 order-lg-3 order-md-3 order-last">


              <AutoCompleteMed />


            </div>
            <div className="align-self-center col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-12 col-12 order-xxl-last order-xl-last order-lg-last order-md-last order-3">


              <IconGroup />

            </div>
          </div>
        </div>
        {/* mobile menu */}
        <MobileMenu />
      </div>
    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string
};

export default HeaderOne;
