export const arrGrupoBioequivalentes = [
    {kinf2: '009870042', kinf: '00987', grupo_bio: 'AC. ACETILSALICILICO 100 MG'},
    {kinf2: '016210050', kinf: '01621', grupo_bio: 'AC. ACETILSALICILICO 100 MG'},
    {kinf2: '009860020', kinf: '00986', grupo_bio: 'AC. ACETILSALICILICO 500 MG'},
    {kinf2: '015470030', kinf: '01547', grupo_bio: 'ACENOCUMAROL 4 MG'},
    {kinf2: '008390025', kinf: '00839', grupo_bio: 'ACICLOVIR 200 MG'},
    {kinf2: '008900035', kinf: '00890', grupo_bio: 'ACICLOVIR 400 MG'},
    {kinf2: '900430050', kinf: '90043', grupo_bio: 'ACIDO URSODEOXICOLICO 500 MG'},
    {kinf2: '000870025', kinf: '00087', grupo_bio: 'ACIDO VALPROICO 250 MG'},
    {kinf2: '016690001', kinf: '01669', grupo_bio: 'ACIDO ZOLENDRONICO 4MG/5ML'},
    {kinf2: '015960002', kinf: '01596', grupo_bio: 'ADALIMUMAB 40MG/0.8'},
    {kinf2: '009520020', kinf: '00952', grupo_bio: 'ALOPURINOL 100 MG'},
    {kinf2: '009530020', kinf: '00953', grupo_bio: 'ALOPURINOL 300 MG'},
    {kinf2: '000140030', kinf: '00014', grupo_bio: 'ALPRAZOLAM 0,5 MG'},
    {kinf2: '009760020', kinf: '00976', grupo_bio: 'AMIODARONA 200 MG'},
    {kinf2: '000180024', kinf: '00018', grupo_bio: 'AMITRIPTILINA 25 MG'},
    {kinf2: '016130030', kinf: '01613', grupo_bio: 'AMLODIPINO 10 MG'},
    {kinf2: '016120030', kinf: '01612', grupo_bio: 'AMLODIPINO 5 MG'},
    {kinf2: '009780020', kinf: '00978', grupo_bio: 'AMOXICILINA + AC. CLAVULANICO 75 MG'},
    {kinf2: '013580028', kinf: '01358', grupo_bio: 'ARIPIPRAZOL 10 MG'},
    {kinf2: '014630028', kinf: '01463', grupo_bio: 'ARIPIPRAZOL 10 MG'},
    {kinf2: '006670030', kinf: '00667', grupo_bio: 'ARIPIPRAZOL 10 MG'},
    {kinf2: '003670030', kinf: '00367', grupo_bio: 'ARIPIPRAZOL 10 MG'},
    {kinf2: '003740030', kinf: '00374', grupo_bio: 'ARIPIPRAZOL 10 MG'},
    {kinf2: '013590028', kinf: '01359', grupo_bio: 'ARIPIPRAZOL 15 MG'},
    {kinf2: '006660030', kinf: '00666', grupo_bio: 'ARIPIPRAZOL 15 MG'},
    {kinf2: '003680030', kinf: '00368', grupo_bio: 'ARIPIPRAZOL 15 MG'},
    {kinf2: '003750030', kinf: '00375', grupo_bio: 'ARIPIPRAZOL 15 MG'},
    {kinf2: '016610028', kinf: '01661', grupo_bio: 'ARIPIPRAZOL 5 MG'},
    {kinf2: '014620028', kinf: '01462', grupo_bio: 'ARIPIPRAZOL 5 MG'},
    {kinf2: '006400030', kinf: '00640', grupo_bio: 'ARIPIPRAZOL 5 MG'},
    {kinf2: '009800020', kinf: '00980', grupo_bio: 'ATENOLOL 100 MG'},
    {kinf2: '009790020', kinf: '00979', grupo_bio: 'ATENOLOL 50 MG'},
    {kinf2: '003270014', kinf: '00327', grupo_bio: 'ATOMOXETINA 60 MG'},
    {kinf2: '009480030', kinf: '00948', grupo_bio: 'ATORVASTATINA 10 MG'},
    {kinf2: '008410030', kinf: '00841', grupo_bio: 'ATORVASTATINA 20 MG'},
    {kinf2: '014420100', kinf: '01442', grupo_bio: 'AZATIOPRINA 50 MG'},
    {kinf2: '900060100', kinf: '90006', grupo_bio: 'AZATIOPRINA 50 MG'},
    {kinf2: '008420006', kinf: '00842', grupo_bio: 'AZITROMICINA 500 MG'},
    {kinf2: '016670030', kinf: '01667', grupo_bio: 'BETAHISTINA 16 MG'},
    {kinf2: '013060030', kinf: '01306', grupo_bio: 'BETAHISTINA 16 MG'},
    {kinf2: '016680030', kinf: '01668', grupo_bio: 'BETAHISTINA 24 MG'},
    {kinf2: '013080030', kinf: '01308', grupo_bio: 'BETAHISTINA 24 MG'},
    {kinf2: '013070030', kinf: '01307', grupo_bio: 'BETAHISTINA 8 MG'},
    {kinf2: '008260001', kinf: '00826', grupo_bio: 'BETAMETASONA '},
    {kinf2: '017500030', kinf: '01750', grupo_bio: 'BISOPROLOL 1.25 MG'},
    {kinf2: '017510030', kinf: '01751', grupo_bio: 'BISOPROLOL 2.5 MG'},
    {kinf2: '012600030', kinf: '01260', grupo_bio: 'BISOPROLOL 2.5 MG'},
    {kinf2: '017520030', kinf: '01752', grupo_bio: 'BISOPROLOL 5 MG'},
    {kinf2: '012610030', kinf: '01261', grupo_bio: 'BISOPROLOL 5 MG'},
    {kinf2: '017050030', kinf: '01705', grupo_bio: 'BUPROPION 150 MG'},
    {kinf2: '007070030', kinf: '00707', grupo_bio: 'BUPROPION 150 MG'},
    {kinf2: '005890030', kinf: '00589', grupo_bio: 'BUPROPION 150 MG'},
    {kinf2: '016700060', kinf: '01670', grupo_bio: 'BUPROPION 150 MG'},
    {kinf2: '004620030', kinf: '00462', grupo_bio: 'BUPROPION 150 MG'},
    {kinf2: '017060030', kinf: '01706', grupo_bio: 'BUPROPION 300 MG'},
    {kinf2: '005900030', kinf: '00590', grupo_bio: 'BUPROPION 300 MG'},
    {kinf2: '004630030', kinf: '00463', grupo_bio: 'BUPROPION 300 MG'},
    {kinf2: '016540001', kinf: '01654', grupo_bio: 'CANNABIDIOL  100MG/ML'},
    {kinf2: '009540030', kinf: '00954', grupo_bio: 'CAPTOPRIL 25 MG'},
    {kinf2: '005320020', kinf: '00532', grupo_bio: 'CARBAMAZEPINA 200 MG'},
    {kinf2: '002510060', kinf: '00251', grupo_bio: 'CARBAMAZEPINA 200 MG'},
    {kinf2: '002530060', kinf: '00253', grupo_bio: 'CARBAMAZEPINA 200 MG LP'},
    {kinf2: '000450020', kinf: '00045', grupo_bio: 'CARBAMAZEPINA 200 MG '},
    {kinf2: '002540060', kinf: '00254', grupo_bio: 'CARBAMAZEPINA 400 MG'},
    {kinf2: '007210050', kinf: '00721', grupo_bio: 'CARBONATO DE LITIO 300 MG'},
    {kinf2: '007200030', kinf: '00720', grupo_bio: 'CARBONATO DE LITIO 450 MG'},
    {kinf2: '015560030', kinf: '01556', grupo_bio: 'CARVEDILOL 25 MG'},
    {kinf2: '008910008', kinf: '00891', grupo_bio: 'CEFADROXILO 500 MG'},
    {kinf2: '017360014', kinf: '01736', grupo_bio: 'CEFUROXIMA 500 MG'},
    {kinf2: '010880030', kinf: '01088', grupo_bio: 'CELECOXIB 200 MG'},
    {kinf2: '016950030', kinf: '01695', grupo_bio: 'CELECOXIB 200 MG'},
    {kinf2: '010520030', kinf: '01052', grupo_bio: 'CELECOXIB 200 MG'},
    {kinf2: '009930020', kinf: '00993', grupo_bio: 'CICLOBENZAPRINA 10 MG'},
    {kinf2: '008430006', kinf: '00843', grupo_bio: 'CIPROFLOXACINO 500 MG'},
    {kinf2: '000570030', kinf: '00057', grupo_bio: 'CITALOPRAM 20 MG'},
    {kinf2: '000590028', kinf: '00059', grupo_bio: 'CITALOPRAM 20 MG'},
    {kinf2: '014330010', kinf: '01433', grupo_bio: 'CITICOLINA '},
    {kinf2: '008440014', kinf: '00844', grupo_bio: 'CLARITROMICINA 500 MG'},
    {kinf2: '011280020', kinf: '01128', grupo_bio: 'CLOBAZAM 10 MG'},
    {kinf2: '001290020', kinf: '00129', grupo_bio: 'CLOBAZAM 10 MG'},
    {kinf2: '000200020', kinf: '00020', grupo_bio: 'CLOMIPRAMINA 25 MG'},
    {kinf2: '000400030', kinf: '00040', grupo_bio: 'CLOMIPRAMINA 25 MG'},
    {kinf2: '006790030', kinf: '00679', grupo_bio: 'CLONAZEPAM 0,5 MG'},
    {kinf2: '000640030', kinf: '00064', grupo_bio: 'CLONAZEPAM 0,5 MG COMP. DISP.'},
    {kinf2: '001660030', kinf: '00166', grupo_bio: 'CLONAZEPAM 0,5 MG'},
    {kinf2: '002030030', kinf: '00203', grupo_bio: 'CLONAZEPAM 0,5 MG'},
    {kinf2: '002840030', kinf: '00284', grupo_bio: 'CLONAZEPAM 0,5 MG'},
    {kinf2: '000650030', kinf: '00065', grupo_bio: 'CLONAZEPAM 1 MG COMP. DISP.'},
    {kinf2: '002850030', kinf: '00285', grupo_bio: 'CLONAZEPAM 1 MG'},
    {kinf2: '006800030', kinf: '00680', grupo_bio: 'CLONAZEPAM 2 MG'},
    {kinf2: '000660030', kinf: '00066', grupo_bio: 'CLONAZEPAM 2 MG COMP. DISP.'},
    {kinf2: '001670030', kinf: '00167', grupo_bio: 'CLONAZEPAM 2 MG'},
    {kinf2: '002050030', kinf: '00205', grupo_bio: 'CLONAZEPAM 2 MG'},
    {kinf2: '002860030', kinf: '00286', grupo_bio: 'CLONAZEPAM 2 MG'},
    {kinf2: '900790028', kinf: '90079', grupo_bio: 'CLOPIDOGREL 75 MG'},
    {kinf2: '016900028', kinf: '01690', grupo_bio: 'CLOPIDOGREL 75 MG'},
    {kinf2: '007240030', kinf: '00724', grupo_bio: 'CLOPIDOGREL 75 MG'},
    {kinf2: '004160030', kinf: '00416', grupo_bio: 'CLORDIAZEPOXIDO-CLIDINIO BROMU '},
    {kinf2: '009680020', kinf: '00968', grupo_bio: 'CLORFENAMINA 4 MG'},
    {kinf2: '014900028', kinf: '01490', grupo_bio: 'CLORMADINONA + ETINILESTRADIOL '},
    {kinf2: '001540020', kinf: '00154', grupo_bio: 'CLORPROMAZINA 100 MG'},
    {kinf2: '008210030', kinf: '00821', grupo_bio: 'CLOTIAZEPAM 10 MG'},
    {kinf2: '007640030', kinf: '00764', grupo_bio: 'CLOTIAZEPAM 10 MG COMP. DISP.'},
    {kinf2: '016110030', kinf: '01611', grupo_bio: 'CLOTIAZEPAM 5 MG'},
    {kinf2: '007630030', kinf: '00763', grupo_bio: 'CLOTIAZEPAM 5 MG COMP. DISP.'},
    {kinf2: '009550012', kinf: '00955', grupo_bio: 'CLOXACILINA 500 MG'},
    {kinf2: '007800020', kinf: '00780', grupo_bio: 'CLOZAPINA 25 MG'},
    {kinf2: '009980040', kinf: '00998', grupo_bio: 'COLCHICINA 5 MG'},
    {kinf2: '014510060', kinf: '01451', grupo_bio: 'DABIGATRAN 110 MG'},
    {kinf2: '013770060', kinf: '01377', grupo_bio: 'DABIGATRAN 150 MG'},
    {kinf2: '012550030', kinf: '01255', grupo_bio: 'DEFLAZACORT 30 MG'},
    {kinf2: '012540040', kinf: '01254', grupo_bio: 'DEFLAZACORT 6 MG'},
    {kinf2: '012480001', kinf: '01248', grupo_bio: 'DENOSUMAB 60 MG'},
    {kinf2: '013270001', kinf: '01327', grupo_bio: 'DESLORATADINA 2.5MG/5ML'},
    {kinf2: '013260030', kinf: '01326', grupo_bio: 'DESLORATADINA 5 MG'},
    {kinf2: '017960030', kinf: '01796', grupo_bio: 'DESVENLAFAXINA 100 MG'},
    {kinf2: '011320030', kinf: '01132', grupo_bio: 'DESVENLAFAXINA 100 MG'},
    {kinf2: '017950030', kinf: '01795', grupo_bio: 'DESVENLAFAXINA 50 MG'},
    {kinf2: '011310030', kinf: '01131', grupo_bio: 'DESVENLAFAXINA 50 MG'},
    {kinf2: '000950020', kinf: '00095', grupo_bio: 'DIAZEPAM 10 MG'},
    {kinf2: '010030005', kinf: '01003', grupo_bio: 'DICLOFENACO 75 MG'},
    {kinf2: '010370030', kinf: '01037', grupo_bio: 'DIGOXINA 0,25 MG'},
    {kinf2: '002790020', kinf: '00279', grupo_bio: 'DIVALPROATO 125 MG'},
    {kinf2: '002830050', kinf: '00283', grupo_bio: 'DIVALPROATO 125 MG LIB. MOD.'},
    {kinf2: '010820050', kinf: '01082', grupo_bio: 'DIVALPROATO 250 MG'},
    {kinf2: '016960100', kinf: '01696', grupo_bio: 'DIVALPROATO 250 MG LP'},
    {kinf2: '002800020', kinf: '00280', grupo_bio: 'DIVALPROATO 250 MG'},
    {kinf2: '003120050', kinf: '00312', grupo_bio: 'DIVALPROATO 250 MG LP'},
    {kinf2: '010830050', kinf: '01083', grupo_bio: 'DIVALPROATO 500 MG'},
    {kinf2: '002810020', kinf: '00281', grupo_bio: 'DIVALPROATO 500 MG'},
    {kinf2: '002820050', kinf: '00282', grupo_bio: 'DIVALPROATO 500 MG LP'},
    {kinf2: '009690020', kinf: '00969', grupo_bio: 'DOMPERIDONA 10 MG'},
    {kinf2: '018040028', kinf: '01804', grupo_bio: 'DONEPECILO 10 MG COMP. DISP.'},
    {kinf2: '004240030', kinf: '00424', grupo_bio: 'DONEPECILO 10 MG'},
    {kinf2: '009390030', kinf: '00939', grupo_bio: 'DONEPECILO 10 MG COMP. DISP.'},
    {kinf2: '018030028', kinf: '01803', grupo_bio: 'DONEPECILO 5 MG'},
    {kinf2: '009380030', kinf: '00938', grupo_bio: 'DONEPECILO 5 MG'},
    {kinf2: '010320010', kinf: '01032', grupo_bio: 'DOXICICLINA 100 MG'},
    {kinf2: '009050010', kinf: '00905', grupo_bio: 'DOXICICLINA 100 MG'},
    {kinf2: '011080028', kinf: '01108', grupo_bio: 'DULOXETINA 30 MG'},
    {kinf2: '006990030', kinf: '00699', grupo_bio: 'DULOXETINA 30 MG'},
    {kinf2: '011090028', kinf: '01109', grupo_bio: 'DULOXETINA 60 MG'},
    {kinf2: '003170028', kinf: '00317', grupo_bio: 'DULOXETINA 60 MG'},
    {kinf2: '007000030', kinf: '00700', grupo_bio: 'DULOXETINA 60 MG'},
    {kinf2: '016920030', kinf: '01692', grupo_bio: 'DUTASTERIDA 0.5 MG'},
    {kinf2: '016930030', kinf: '01693', grupo_bio: 'DUTASTERIDE+TAMSULOSINA 0.5MG/0.4MG'},
    {kinf2: '012410030', kinf: '01241', grupo_bio: 'DUTASTERIDE+TAMSULOSINA 0.5/0.4 MG'},
    {kinf2: '010040020', kinf: '01004', grupo_bio: 'ENALAPRIL (MALEATO) 10 MG'},
    {kinf2: '010050020', kinf: '01005', grupo_bio: 'ENALAPRIL (MALEATO) 20 MG'},
    {kinf2: '003310030', kinf: '00331', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '008080030', kinf: '00808', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '007730028', kinf: '00773', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '001360040', kinf: '00136', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '005250028', kinf: '00525', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '015970056', kinf: '01597', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '004250030', kinf: '00425', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '007080030', kinf: '00708', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '004320030', kinf: '00432', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '004170030', kinf: '00417', grupo_bio: 'ESCITALOPRAM 10 MG'},
    {kinf2: '017530030', kinf: '01753', grupo_bio: 'ESCITALOPRAM 15 MG'},
    {kinf2: '007060028', kinf: '00706', grupo_bio: 'ESCITALOPRAM 15 MG'},
    {kinf2: '004570030', kinf: '00457', grupo_bio: 'ESCITALOPRAM 20 MG'},
    {kinf2: '007740028', kinf: '00774', grupo_bio: 'ESCITALOPRAM 20 MG'},
    {kinf2: '005260028', kinf: '00526', grupo_bio: 'ESCITALOPRAM 20 MG'},
    {kinf2: '005240030', kinf: '00524', grupo_bio: 'ESCITALOPRAM 20 MG'},
    {kinf2: '016760028', kinf: '01676', grupo_bio: 'ESOMEPRAZOL 10 MG'},
    {kinf2: '010500028', kinf: '01050', grupo_bio: 'ESOMEPRAZOL 20 MG'},
    {kinf2: '010550028', kinf: '01055', grupo_bio: 'ESOMEPRAZOL 40 MG'},
    {kinf2: '009590020', kinf: '00959', grupo_bio: 'ESPIRONOLACTONA 25 MG'},
    {kinf2: '004960030', kinf: '00496', grupo_bio: 'ESZOPICLONA 2 MG'},
    {kinf2: '004970030', kinf: '00497', grupo_bio: 'ESZOPICLONA 3 MG'},
    {kinf2: '005580030', kinf: '00558', grupo_bio: 'ESZOPICLONA 3 MG'},
    {kinf2: '017100004', kinf: '01710', grupo_bio: 'ETANERCEPT 50MG/1ML'},
    {kinf2: '016940007', kinf: '01694', grupo_bio: 'ETORICOXIB 120 MG'},
    {kinf2: '012510007', kinf: '01251', grupo_bio: 'ETORICOXIB 120 MG'},
    {kinf2: '012520014', kinf: '01252', grupo_bio: 'ETORICOXIB 60 MG'},
    {kinf2: '012530014', kinf: '01253', grupo_bio: 'ETORICOXIB 90 MG'},
    {kinf2: '010260020', kinf: '01026', grupo_bio: 'FAMOTIDINA 20 MG'},
    {kinf2: '010270010', kinf: '01027', grupo_bio: 'FAMOTIDINA 40 MG'},
    {kinf2: '001180030', kinf: '00118', grupo_bio: 'FENITOINA 100 MG'},
    {kinf2: '015100030', kinf: '01510', grupo_bio: 'FEXOFENADINA 120 MG'},
    {kinf2: '015110030', kinf: '01511', grupo_bio: 'FEXOFENADINA 180 MG'},
    {kinf2: '012350020', kinf: '01235', grupo_bio: 'FLAVOXATO 200 MG'},
    {kinf2: '009820002', kinf: '00982', grupo_bio: 'FLUCONAZOL 150 MG'},
    {kinf2: '001020060', kinf: '00102', grupo_bio: 'FLUOXETINA 20 MG'},
    {kinf2: '009370020', kinf: '00937', grupo_bio: 'FLUOXETINA 20 MG'},
    {kinf2: '001970028', kinf: '00197', grupo_bio: 'FLUOXETINA 20 MG COMP. DISP.'},
    {kinf2: '002400060', kinf: '00240', grupo_bio: 'FLUOXETINA 20 MG'},
    {kinf2: '001550030', kinf: '00155', grupo_bio: 'FLUVOXAMINA 100 MG'},
    {kinf2: '009700012', kinf: '00970', grupo_bio: 'FUROSEMIDA 40 MG'},
    {kinf2: '003940030', kinf: '00394', grupo_bio: 'GABAPENTINA 300 MG.'},
    {kinf2: '004300030', kinf: '00430', grupo_bio: 'GABAPENTINA 300 MG.'},
    {kinf2: '004310030', kinf: '00431', grupo_bio: 'GABAPENTINA 400 MG.'},
    {kinf2: '007880028', kinf: '00788', grupo_bio: 'GALANTAMINA 16 MG'},
    {kinf2: '007870028', kinf: '00787', grupo_bio: 'GALANTAMINA 8 MG'},
    {kinf2: '008460030', kinf: '00846', grupo_bio: 'GEMFIBROZILO 300 MG'},
    {kinf2: '008470060', kinf: '00847', grupo_bio: 'GLIBENCLAMIDA 5 MG'},
    {kinf2: '012380060', kinf: '01238', grupo_bio: 'GLUCOSAMINA+CONDROITINA 400/500 MG'},
    {kinf2: '012370030', kinf: '01237', grupo_bio: 'GLUCOSAMINA+CONDROITINA 500/400 MG'},
    {kinf2: '001300020', kinf: '00130', grupo_bio: 'HALOPERIDOL 1 MG'},
    {kinf2: '015400030', kinf: '01540', grupo_bio: 'HALOPERIDOL 1 MG'},
    {kinf2: '900780020', kinf: '90078', grupo_bio: 'HALOPERIDOL 5 MG'},
    {kinf2: '015410020', kinf: '01541', grupo_bio: 'HALOPERIDOL 5 MG'},
    {kinf2: '008480020', kinf: '00848', grupo_bio: 'HIDRALAZINA 50 MG'},
    {kinf2: '008480030', kinf: '00848', grupo_bio: 'HIDRALAZINA 50 MG'},
    {kinf2: '010380020', kinf: '01038', grupo_bio: 'HIDROCLOROTIAZIDA 50 MG'},
    {kinf2: '012660030', kinf: '01266', grupo_bio: 'HIDROXICLOROQUINA SULFATO 200 MG'},
    {kinf2: '013110010', kinf: '01311', grupo_bio: 'IBUPROFENO 200 MG'},
    {kinf2: '010120020', kinf: '01012', grupo_bio: 'IBUPROFENO 200 MG'},
    {kinf2: '013640010', kinf: '01364', grupo_bio: 'IBUPROFENO 400 MG'},
    {kinf2: '008490020', kinf: '00849', grupo_bio: 'IBUPROFENO 400 MG'},
    {kinf2: '013650010', kinf: '01365', grupo_bio: 'IBUPROFENO 600 MG'},
    {kinf2: '008500020', kinf: '00850', grupo_bio: 'IBUPROFENO 600 MG'},
    {kinf2: '001320040', kinf: '00132', grupo_bio: 'IMIPRAMINA 25 MG'},
    {kinf2: '018050001', kinf: '01805', grupo_bio: 'INSULINA 300U/ML'},
    {kinf2: '012670030', kinf: '01267', grupo_bio: 'ISOTRETINOINA 10 MG'},
    {kinf2: '015820030', kinf: '01582', grupo_bio: 'ISOTRETINOINA 10 MG'},
    {kinf2: '012680030', kinf: '01268', grupo_bio: 'ISOTRETINOINA 20 MG'},
    {kinf2: '015840030', kinf: '01584', grupo_bio: 'ISOTRETINOINA 20 MG'},
    {kinf2: '009510010', kinf: '00951', grupo_bio: 'KETOPROFENO 100MG/2ML'},
    {kinf2: '009510005', kinf: '00951', grupo_bio: 'KETOPROFENO 100MG/2ML'},
    {kinf2: '900570020', kinf: '90057', grupo_bio: 'KETOPROFENO 50 MG'},
    {kinf2: '016380030', kinf: '01638', grupo_bio: 'LACOSAMIDA 100 MG'},
    {kinf2: '006360028', kinf: '00636', grupo_bio: 'LACOSAMIDA 100 MG'},
    {kinf2: '016390030', kinf: '01639', grupo_bio: 'LACOSAMIDA 200 MG'},
    {kinf2: '006380028', kinf: '00638', grupo_bio: 'LACOSAMIDA 200 MG'},
    {kinf2: '016370030', kinf: '01637', grupo_bio: 'LACOSAMIDA 50 MG'},
    {kinf2: '006350014', kinf: '00635', grupo_bio: 'LACOSAMIDA 50 MG'},
    {kinf2: '003420028', kinf: '00342', grupo_bio: 'LAMOTRIGINA 100 MG'},
    {kinf2: '001430030', kinf: '00143', grupo_bio: 'LAMOTRIGINA 100 MG'},
    {kinf2: '001460030', kinf: '00146', grupo_bio: 'LAMOTRIGINA 100 MG'},
    {kinf2: '001490030', kinf: '00149', grupo_bio: 'LAMOTRIGINA 100 MG DISP'},
    {kinf2: '009290030', kinf: '00929', grupo_bio: 'LAMOTRIGINA 100 MG DISP'},
    {kinf2: '003100030', kinf: '00310', grupo_bio: 'LAMOTRIGINA 100 MG'},
    {kinf2: '002670030', kinf: '00267', grupo_bio: 'LAMOTRIGINA 100 MG'},
    {kinf2: '006730030', kinf: '00673', grupo_bio: 'LAMOTRIGINA 100 MG'},
    {kinf2: '004380030', kinf: '00438', grupo_bio: 'LAMOTRIGINA 200  MG DISP.'},
    {kinf2: '001500030', kinf: '00150', grupo_bio: 'LAMOTRIGINA 200  MG DISP.'},
    {kinf2: '003110030', kinf: '00311', grupo_bio: 'LAMOTRIGINA 200  MG DISP.'},
    {kinf2: '011300030', kinf: '01130', grupo_bio: 'LAMOTRIGINA 200 MG'},
    {kinf2: '001440030', kinf: '00144', grupo_bio: 'LAMOTRIGINA 25 MG'},
    {kinf2: '001470030', kinf: '00147', grupo_bio: 'LAMOTRIGINA 25 MG'},
    {kinf2: '001510030', kinf: '00151', grupo_bio: 'LAMOTRIGINA 25 MG DISP.'},
    {kinf2: '003080030', kinf: '00308', grupo_bio: 'LAMOTRIGINA 25 MG DISP.'},
    {kinf2: '002680030', kinf: '00268', grupo_bio: 'LAMOTRIGINA 25 MG'},
    {kinf2: '001520030', kinf: '00152', grupo_bio: 'LAMOTRIGINA 5 MG  DISP.'},
    {kinf2: '900760030', kinf: '90076', grupo_bio: 'LAMOTRIGINA 50 MG'},
    {kinf2: '001450030', kinf: '00145', grupo_bio: 'LAMOTRIGINA 50 MG'},
    {kinf2: '001480030', kinf: '00148', grupo_bio: 'LAMOTRIGINA 50 MG'},
    {kinf2: '001530030', kinf: '00153', grupo_bio: 'LAMOTRIGINA 50 MG DISP'},
    {kinf2: '003090030', kinf: '00309', grupo_bio: 'LAMOTRIGINA 50 MG DISP'},
    {kinf2: '002690030', kinf: '00269', grupo_bio: 'LAMOTRIGINA 50 MG'},
    {kinf2: '006720030', kinf: '00672', grupo_bio: 'LAMOTRIGINA 50 MG'},
    {kinf2: '018000001', kinf: '01800', grupo_bio: 'LEVETIRACETAM 100 MG/ ML'},
    {kinf2: '003970030', kinf: '00397', grupo_bio: 'LEVETIRACETAM 1000 MG'},
    {kinf2: '005140030', kinf: '00514', grupo_bio: 'LEVETIRACETAM 1000 MG'},
    {kinf2: '001410030', kinf: '00141', grupo_bio: 'LEVETIRACETAM 1000 MG'},
    {kinf2: '005530030', kinf: '00553', grupo_bio: 'LEVETIRACETAM 1000 MG'},
    {kinf2: '017990030', kinf: '01799', grupo_bio: 'LEVETIRACETAM 1000 MG'},
    {kinf2: '007910030', kinf: '00791', grupo_bio: 'LEVETIRACETAM 1000 MG'},
    {kinf2: '003980030', kinf: '00398', grupo_bio: 'LEVETIRACETAM 500 MG'},
    {kinf2: '005130060', kinf: '00513', grupo_bio: 'LEVETIRACETAM 500 MG'},
    {kinf2: '001420060', kinf: '00142', grupo_bio: 'LEVETIRACETAM 500 MG'},
    {kinf2: '005520030', kinf: '00552', grupo_bio: 'LEVETIRACETAM 500 MG'},
    {kinf2: '017980030', kinf: '01798', grupo_bio: 'LEVETIRACETAM 500 MG'},
    {kinf2: '007900030', kinf: '00790', grupo_bio: 'LEVETIRACETAM 500 MG'},
    {kinf2: '012220060', kinf: '01222', grupo_bio: 'LEVOCETIRIZINA 5 MG'},
    {kinf2: '012220030', kinf: '01222', grupo_bio: 'LEVOCETIRIZINA 5 MG'},
    {kinf2: '005180030', kinf: '00518', grupo_bio: 'LEVODOPA + CARBIDOPA 100 MG'},
    {kinf2: '005190030', kinf: '00519', grupo_bio: 'LEVODOPA + CARBIDOPA 150 MG'},
    {kinf2: '014140030', kinf: '01414', grupo_bio: 'LEVODOPA + CARBIDOPA 200 MG'},
    {kinf2: '008520030', kinf: '00852', grupo_bio: 'LEVODOPA + CARBIDOPA 250/25 MG'},
    {kinf2: '016630030', kinf: '01663', grupo_bio: 'LEVODOPA+BENSERAZIDA 100 MG/25MG'},
    {kinf2: '016640030', kinf: '01664', grupo_bio: 'LEVODOPA+BENSERAZIDA 100MG/25MG DISP'},
    {kinf2: '016620030', kinf: '01662', grupo_bio: 'LEVODOPA+BENSERAZIDA 200MG/50MG'},
    {kinf2: '008530007', kinf: '00853', grupo_bio: 'LEVOFLOXACINO 500 MG'},
    {kinf2: '015790028', kinf: '01579', grupo_bio: 'LEVONORGESTREL 0.03 MG'},
    {kinf2: '009010002', kinf: '00901', grupo_bio: 'LEVONORGESTREL 75 MG'},
    {kinf2: '013400084', kinf: '01340', grupo_bio: 'LEVOTIROXINA SODICA 100 MCG'},
    {kinf2: '010580100', kinf: '01058', grupo_bio: 'LEVOTIROXINA SODICA 100 MCG'},
    {kinf2: '013510050', kinf: '01351', grupo_bio: 'LEVOTIROXINA SODICA 112 MCG'},
    {kinf2: '010590050', kinf: '01059', grupo_bio: 'LEVOTIROXINA SODICA 125 MCG'},
    {kinf2: '013520050', kinf: '01352', grupo_bio: 'LEVOTIROXINA SODICA 137 MCG'},
    {kinf2: '013530050', kinf: '01353', grupo_bio: 'LEVOTIROXINA SODICA 150 MCG'},
    {kinf2: '013540050', kinf: '01354', grupo_bio: 'LEVOTIROXINA SODICA 175 MCG'},
    {kinf2: '013550050', kinf: '01355', grupo_bio: 'LEVOTIROXINA SODICA 200 MCG'},
    {kinf2: '010560050', kinf: '01056', grupo_bio: 'LEVOTIROXINA SODICA 25 MCG'},
    {kinf2: '900200100', kinf: '90020', grupo_bio: 'LEVOTIROXINA SODICA 50 MCG'},
    {kinf2: '009020050', kinf: '00902', grupo_bio: 'LEVOTIROXINA SODICA 50 MCG'},
    {kinf2: '013410056', kinf: '01341', grupo_bio: 'LEVOTIROXINA SODICA 75 MCG'},
    {kinf2: '010570050', kinf: '01057', grupo_bio: 'LEVOTIROXINA SODICA 75 MCG'},
    {kinf2: '013500050', kinf: '01350', grupo_bio: 'LEVOTIROXINA SODICA 88 MCG'},
    {kinf2: '017580030', kinf: '01758', grupo_bio: 'LISDEXANFETAMINA 20 MG'},
    {kinf2: '017590030', kinf: '01759', grupo_bio: 'LISDEXANFETAMINA 30 MG'},
    {kinf2: '017600030', kinf: '01760', grupo_bio: 'LISDEXANFETAMINA 50 MG'},
    {kinf2: '017610030', kinf: '01761', grupo_bio: 'LISDEXANFETAMINA 70 MG'},
    {kinf2: '008540006', kinf: '00854', grupo_bio: 'LOPERAMIDA 2 MG'},
    {kinf2: '009710030', kinf: '00971', grupo_bio: 'LORATADINA 10 MG'},
    {kinf2: '009420030', kinf: '00942', grupo_bio: 'LORAZEPAM 2 MG'},
    {kinf2: '012710030', kinf: '01271', grupo_bio: 'LOSARTAN 100 MG'},
    {kinf2: '012720030', kinf: '01272', grupo_bio: 'LOSARTAN 50 MG'},
    {kinf2: '008550030', kinf: '00855', grupo_bio: 'LOSARTAN 50 MG'},
    {kinf2: '014410020', kinf: '01441', grupo_bio: 'MEDROXIPROGESTERONA 5 MG'},
    {kinf2: '015580010', kinf: '01558', grupo_bio: 'MELOXICAM 15 MG'},
    {kinf2: '005270056', kinf: '00527', grupo_bio: 'MEMANTINA 10 MG'},
    {kinf2: '003150060', kinf: '00315', grupo_bio: 'MEMANTINA 10 MG'},
    {kinf2: '007890056', kinf: '00789', grupo_bio: 'MEMANTINA 10 MG'},
    {kinf2: '003770060', kinf: '00377', grupo_bio: 'MEMANTINA 10 MG.'},
    {kinf2: '005540028', kinf: '00554', grupo_bio: 'MEMANTINA 20 MG'},
    {kinf2: '006950030', kinf: '00695', grupo_bio: 'MEMANTINA 20 MG'},
    {kinf2: '006430060', kinf: '00643', grupo_bio: 'MEMANTINA 20 MG'},
    {kinf2: '017560030', kinf: '01756', grupo_bio: 'MEMANTINA+DONEPECILO 14/10 MG'},
    {kinf2: '017570030', kinf: '01757', grupo_bio: 'MEMANTINA+DONEPECILO 28/10 MG'},
    {kinf2: '900730030', kinf: '90073', grupo_bio: 'MESALAZINA 1.5 GR'},
    {kinf2: '016160030', kinf: '01616', grupo_bio: 'METFORMINA 1000 MG'},
    {kinf2: '014590030', kinf: '01459', grupo_bio: 'METFORMINA 1000 MG'},
    {kinf2: '013330030', kinf: '01333', grupo_bio: 'METFORMINA 1000 MG'},
    {kinf2: '016140030', kinf: '01614', grupo_bio: 'METFORMINA 500 MG'},
    {kinf2: '013340030', kinf: '01334', grupo_bio: 'METFORMINA 500 MG'},
    {kinf2: '016150030', kinf: '01615', grupo_bio: 'METFORMINA 750 MG'},
    {kinf2: '014580030', kinf: '01458', grupo_bio: 'METFORMINA 750 MG'},
    {kinf2: '013350030', kinf: '01335', grupo_bio: 'METFORMINA 750 MG'},
    {kinf2: '009720030', kinf: '00972', grupo_bio: 'METFORMINA 850 MG'},
    {kinf2: '008580020', kinf: '00858', grupo_bio: 'METILDOPA 250 MG'},
    {kinf2: '000250030', kinf: '00025', grupo_bio: 'METILFENIDATO 10 MG LA'},
    {kinf2: '001580030', kinf: '00158', grupo_bio: 'METILFENIDATO 10 MG'},
    {kinf2: '002130030', kinf: '00213', grupo_bio: 'METILFENIDATO 10 MG'},
    {kinf2: '006590030', kinf: '00659', grupo_bio: 'METILFENIDATO 10 MG LA'},
    {kinf2: '006620030', kinf: '00662', grupo_bio: 'METILFENIDATO 10 MG'},
    {kinf2: '000720030', kinf: '00072', grupo_bio: 'METILFENIDATO 18 MG'},
    {kinf2: '013660030', kinf: '01366', grupo_bio: 'METILFENIDATO 18 MG'},
    {kinf2: '016820030', kinf: '01682', grupo_bio: 'METILFENIDATO 18 MG'},
    {kinf2: '000260030', kinf: '00026', grupo_bio: 'METILFENIDATO 20 MG'},
    {kinf2: '002140030', kinf: '00214', grupo_bio: 'METILFENIDATO 20 MG'},
    {kinf2: '006630030', kinf: '00663', grupo_bio: 'METILFENIDATO 20 MG'},
    {kinf2: '004340030', kinf: '00434', grupo_bio: 'METILFENIDATO 27 MG'},
    {kinf2: '013670030', kinf: '01367', grupo_bio: 'METILFENIDATO 27 MG'},
    {kinf2: '016830030', kinf: '01683', grupo_bio: 'METILFENIDATO 27 MG'},
    {kinf2: '002150030', kinf: '00215', grupo_bio: 'METILFENIDATO 30 MG'},
    {kinf2: '000730030', kinf: '00073', grupo_bio: 'METILFENIDATO 36 MG'},
    {kinf2: '013680030', kinf: '01368', grupo_bio: 'METILFENIDATO 36 MG'},
    {kinf2: '016840030', kinf: '01684', grupo_bio: 'METILFENIDATO 36 MG'},
    {kinf2: '002160030', kinf: '00216', grupo_bio: 'METILFENIDATO 40 MG'},
    {kinf2: '003510030', kinf: '00351', grupo_bio: 'METILFENIDATO 54 MG'},
    {kinf2: '016850030', kinf: '01685', grupo_bio: 'METILFENIDATO 54 MG'},
    {kinf2: '009410014', kinf: '00941', grupo_bio: 'METILPREDNISOLONA 16 MG'},
    {kinf2: '009400020', kinf: '00940', grupo_bio: 'METILPREDNISOLONA 4 MG'},
    {kinf2: '009730024', kinf: '00973', grupo_bio: 'METOCLOPRAMIDA 10 MG'},
    {kinf2: '900560100', kinf: '90056', grupo_bio: 'METOTREXATO 2,5 MG'},
    {kinf2: '014440100', kinf: '01444', grupo_bio: 'METOTREXATO 2,5 MG'},
    {kinf2: '900300020', kinf: '90030', grupo_bio: 'METRONIDAZOL 500 MG'},
    {kinf2: '010220010', kinf: '01022', grupo_bio: 'METRONIDAZOL 500 MG'},
    {kinf2: '017720002', kinf: '01772', grupo_bio: 'MIDAZOLAM 2.5MG/0.5ML'},
    {kinf2: '017730002', kinf: '01773', grupo_bio: 'MIDAZOLAM 5 MG/ML'},
    {kinf2: '017740002', kinf: '01774', grupo_bio: 'MIDAZOLAM 7.5 MG/1.5ML '},
    {kinf2: '000540030', kinf: '00054', grupo_bio: 'MIRTAZAPINA 15 MG'},
    {kinf2: '000550030', kinf: '00055', grupo_bio: 'MIRTAZAPINA 30 MG'},
    {kinf2: '001010030', kinf: '00101', grupo_bio: 'MIRTAZAPINA 30 MG'},
    {kinf2: '017470030', kinf: '01747', grupo_bio: 'MIRTAZAPINA 30 MG BUCODISP.'},
    {kinf2: '013750030', kinf: '01375', grupo_bio: 'MONTELUKAST 10 MG'},
    {kinf2: '012860001', kinf: '01286', grupo_bio: 'NAFAZOLINA 0.12MG/ML'},
    {kinf2: '015080010', kinf: '01508', grupo_bio: 'NAPROXENO 550 MG'},
    {kinf2: '015090010', kinf: '01509', grupo_bio: 'NAPROXENO 550 MG'},
    {kinf2: '009210014', kinf: '00921', grupo_bio: 'NARATRIPTAN 2,5 MG'},
    {kinf2: '008030010', kinf: '00803', grupo_bio: 'NARATRIPTAN 2,5 MG'},
    {kinf2: '013290030', kinf: '01329', grupo_bio: 'NEBIVOLOL 5 MG'},
    {kinf2: '016910028', kinf: '01691', grupo_bio: 'NEBIVOLOL 5 MG'},
    {kinf2: '008640010', kinf: '00864', grupo_bio: 'NITROFURANTOINA 100 MG'},
    {kinf2: '009030030', kinf: '00903', grupo_bio: 'NITROFURANTOINA 50 MG'},
    {kinf2: '005100030', kinf: '00510', grupo_bio: 'OLANZAPINA 10 MG'},
    {kinf2: '008190028', kinf: '00819', grupo_bio: 'OLANZAPINA 10 MG'},
    {kinf2: '006310028', kinf: '00631', grupo_bio: 'OLANZAPINA 10 MG'},
    {kinf2: '017150030', kinf: '01715', grupo_bio: 'OLANZAPINA 10 MG DISP.'},
    {kinf2: '005080030', kinf: '00508', grupo_bio: 'OLANZAPINA 10 MG'},
    {kinf2: '002990030', kinf: '00299', grupo_bio: 'OLANZAPINA 10 MG'},
    {kinf2: '003930028', kinf: '00393', grupo_bio: 'OLANZAPINA 10 MG DISP.'},
    {kinf2: '008200028', kinf: '00820', grupo_bio: 'OLANZAPINA 5 MG'},
    {kinf2: '007180028', kinf: '00718', grupo_bio: 'OLANZAPINA 5 MG'},
    {kinf2: '017140030', kinf: '01714', grupo_bio: 'OLANZAPINA 5 MG DISP.'},
    {kinf2: '005070030', kinf: '00507', grupo_bio: 'OLANZAPINA 5 MG'},
    {kinf2: '003010014', kinf: '00301', grupo_bio: 'OLANZAPINA 5 MG'},
    {kinf2: '003920014', kinf: '00392', grupo_bio: 'OLANZAPINA 5 MG DISP.'},
    {kinf2: '012420028', kinf: '01242', grupo_bio: 'OLMESARTAN 20 MG'},
    {kinf2: '012430028', kinf: '01243', grupo_bio: 'OLMESARTAN 40 MG'},
    {kinf2: '015120001', kinf: '01512', grupo_bio: 'OLOPATADINA 0.2%'},
    {kinf2: '008660030', kinf: '00866', grupo_bio: 'OMEPRAZOL 20 MG'},
    {kinf2: '017120002', kinf: '01712', grupo_bio: 'ONDANSETRON 4 MG'},
    {kinf2: '017130002', kinf: '01713', grupo_bio: 'ONDANSETRON 8 MG'},
    {kinf2: '002730060', kinf: '00273', grupo_bio: 'OXCARBAZEPINA 300 MG'},
    {kinf2: '002740060', kinf: '00274', grupo_bio: 'OXCARBAZEPINA 600 MG'},
    {kinf2: '013370018', kinf: '01337', grupo_bio: 'PARACETAMOL 1000 MG'},
    {kinf2: '016250020', kinf: '01625', grupo_bio: 'PARACETAMOL 1000 MG'},
    {kinf2: '013390024', kinf: '01339', grupo_bio: 'PARACETAMOL 500 MG'},
    {kinf2: '008670016', kinf: '00867', grupo_bio: 'PARACETAMOL 500 MG'},
    {kinf2: '007560030', kinf: '00756', grupo_bio: 'PAROXETINA 12,5 MG'},
    {kinf2: '013570030', kinf: '01357', grupo_bio: 'PAROXETINA 20 MG'},
    {kinf2: '000270030', kinf: '00027', grupo_bio: 'PAROXETINA 20 MG'},
    {kinf2: '000420030', kinf: '00042', grupo_bio: 'PAROXETINA 20 MG'},
    {kinf2: '003570030', kinf: '00357', grupo_bio: 'PAROXETINA 20 MG'},
    {kinf2: '015530030', kinf: '01553', grupo_bio: 'PAROXETINA 20 MG'},
    {kinf2: '001860030', kinf: '00186', grupo_bio: 'PAROXETINA 20 MG'},
    {kinf2: '002310030', kinf: '00231', grupo_bio: 'PAROXETINA 20 MG'},
    {kinf2: '002720030', kinf: '00272', grupo_bio: 'PAROXETINA 20 MG'},
    {kinf2: '007570030', kinf: '00757', grupo_bio: 'PAROXETINA 25 MG LIB. PROL.'},
    {kinf2: '006260100', kinf: '00626', grupo_bio: 'PIRIDOSTIGMINA 60 MG'},
    {kinf2: '013230020', kinf: '01323', grupo_bio: 'PREDNISONA 20 MG'},
    {kinf2: '009750020', kinf: '00975', grupo_bio: 'PREDNISONA 5 MG'},
    {kinf2: '017040030', kinf: '01704', grupo_bio: 'PREGABALINA 150 MG'},
    {kinf2: '003140028', kinf: '00314', grupo_bio: 'PREGABALINA 150 MG'},
    {kinf2: '004910030', kinf: '00491', grupo_bio: 'PREGABALINA 150 MG'},
    {kinf2: '007750030', kinf: '00775', grupo_bio: 'PREGABALINA 150 MG'},
    {kinf2: '008220040', kinf: '00822', grupo_bio: 'PREGABALINA 150 MG'},
    {kinf2: '006850014', kinf: '00685', grupo_bio: 'PREGABALINA 25 MG'},
    {kinf2: '011230040', kinf: '01123', grupo_bio: 'PREGABALINA 50 MG'},
    {kinf2: '017030030', kinf: '01703', grupo_bio: 'PREGABALINA 75 MG'},
    {kinf2: '003070028', kinf: '00307', grupo_bio: 'PREGABALINA 75 MG'},
    {kinf2: '004900030', kinf: '00490', grupo_bio: 'PREGABALINA 75 MG'},
    {kinf2: '007170030', kinf: '00717', grupo_bio: 'PREGABALINA 75 MG'},
    {kinf2: '008230040', kinf: '00823', grupo_bio: 'PREGABALINA 75 MG'},
    {kinf2: '009630020', kinf: '00963', grupo_bio: 'PROPRANOLOL 10 MG'},
    {kinf2: '009640020', kinf: '00964', grupo_bio: 'PROPRANOLOL 40 MG'},
    {kinf2: '004840030', kinf: '00484', grupo_bio: 'QUETIAPINA 100 MG'},
    {kinf2: '014070030', kinf: '01407', grupo_bio: 'QUETIAPINA 100 MG'},
    {kinf2: '001990030', kinf: '00199', grupo_bio: 'QUETIAPINA 100 MG'},
    {kinf2: '004590030', kinf: '00459', grupo_bio: 'QUETIAPINA 100 MG'},
    {kinf2: '006200030', kinf: '00620', grupo_bio: 'QUETIAPINA 150 MG LIB. PROL.'},
    {kinf2: '004850030', kinf: '00485', grupo_bio: 'QUETIAPINA 200 MG'},
    {kinf2: '002000030', kinf: '00200', grupo_bio: 'QUETIAPINA 200 MG'},
    {kinf2: '016510030', kinf: '01651', grupo_bio: 'QUETIAPINA 200 MG LIB. PROL.'},
    {kinf2: '005440030', kinf: '00544', grupo_bio: 'QUETIAPINA 200 MG LIB. PROL.'},
    {kinf2: '004600030', kinf: '00460', grupo_bio: 'QUETIAPINA 200 MG'},
    {kinf2: '004830030', kinf: '00483', grupo_bio: 'QUETIAPINA 25 MG'},
    {kinf2: '004540060', kinf: '00454', grupo_bio: 'QUETIAPINA 25 MG'},
    {kinf2: '002010030', kinf: '00201', grupo_bio: 'QUETIAPINA 25 MG'},
    {kinf2: '004580030', kinf: '00458', grupo_bio: 'QUETIAPINA 25 MG'},
    {kinf2: '004120030', kinf: '00412', grupo_bio: 'QUETIAPINA 300 MG'},
    {kinf2: '005450030', kinf: '00545', grupo_bio: 'QUETIAPINA 300 MG LIB. PROL.'},
    {kinf2: '004610030', kinf: '00461', grupo_bio: 'QUETIAPINA 300 MG'},
    {kinf2: '005860030', kinf: '00586', grupo_bio: 'QUETIAPINA 400 MG'},
    {kinf2: '016490030', kinf: '01649', grupo_bio: 'QUETIAPINA 50 MG'},
    {kinf2: '005430030', kinf: '00543', grupo_bio: 'QUETIAPINA 50 MG'},
    {kinf2: '016500030', kinf: '01650', grupo_bio: 'QUETIAPINA 150 MG LIB. PROL.'},
    {kinf2: '013050012', kinf: '01305', grupo_bio: 'RIFAXIMINA 200 MG'},
    {kinf2: '000800030', kinf: '00080', grupo_bio: 'RISPERIDONA 1 MG'},
    {kinf2: '001270030', kinf: '00127', grupo_bio: 'RISPERIDONA 1 MG'},
    {kinf2: '011050030', kinf: '01105', grupo_bio: 'RISPERIDONA 1 MG'},
    {kinf2: '002070020', kinf: '00207', grupo_bio: 'RISPERIDONA 1 MG'},
    {kinf2: '002410060', kinf: '00241', grupo_bio: 'RISPERIDONA 1 MG'},
    {kinf2: '003520001', kinf: '00352', grupo_bio: 'RISPERIDONA 1 MG/ ML'},
    {kinf2: '002440001', kinf: '00244', grupo_bio: 'RISPERIDONA 1 MG/ ML  '},
    {kinf2: '011070001', kinf: '01107', grupo_bio: 'RISPERIDONA 1MG/ML'},
    {kinf2: '006330020', kinf: '00633', grupo_bio: 'RISPERIDONA 2 MG'},
    {kinf2: '000810030', kinf: '00081', grupo_bio: 'RISPERIDONA 3 MG'},
    {kinf2: '017550030', kinf: '01755', grupo_bio: 'RISPERIDONA 3 MG BUCODISP.'},
    {kinf2: '001280030', kinf: '00128', grupo_bio: 'RISPERIDONA 3 MG'},
    {kinf2: '006340020', kinf: '00634', grupo_bio: 'RISPERIDONA 3 MG'},
    {kinf2: '017540030', kinf: '01754', grupo_bio: 'RISPERIDONA 1 MG BUCODISP.'},
    {kinf2: '016730030', kinf: '01673', grupo_bio: 'ROSUVASTATINA 10 MG'},
    {kinf2: '013460030', kinf: '01346', grupo_bio: 'ROSUVASTATINA 10 MG'},
    {kinf2: '012790030', kinf: '01279', grupo_bio: 'ROSUVASTATINA 10 MG'},
    {kinf2: '016740030', kinf: '01674', grupo_bio: 'ROSUVASTATINA 20 MG'},
    {kinf2: '013470030', kinf: '01347', grupo_bio: 'ROSUVASTATINA 20 MG'},
    {kinf2: '012800030', kinf: '01280', grupo_bio: 'ROSUVASTATINA 20 MG'},
    {kinf2: '016750030', kinf: '01675', grupo_bio: 'ROSUVASTATINA 40 MG'},
    {kinf2: '016720030', kinf: '01672', grupo_bio: 'ROSUVASTATINA 5 MG'},
    {kinf2: '010300001', kinf: '01030', grupo_bio: 'SALBUTAMOL '},
    {kinf2: '001080030', kinf: '00108', grupo_bio: 'SERTRALINA 100 MG'},
    {kinf2: '002340030', kinf: '00234', grupo_bio: 'SERTRALINA 100 MG'},
    {kinf2: '003200030', kinf: '00320', grupo_bio: 'SERTRALINA 100 MG'},
    {kinf2: '015640030', kinf: '01564', grupo_bio: 'SERTRALINA 50 MG'},
    {kinf2: '001090060', kinf: '00109', grupo_bio: 'SERTRALINA 50 MG'},
    {kinf2: '002350030', kinf: '00235', grupo_bio: 'SERTRALINA 50 MG'},
    {kinf2: '003210030', kinf: '00321', grupo_bio: 'SERTRALINA 50 MG'},
    {kinf2: '006890030', kinf: '00689', grupo_bio: 'SERTRALINA 50 MG'},
    {kinf2: '002480030', kinf: '00248', grupo_bio: 'SULPIRIDA 50 MG'},
    {kinf2: '900630100', kinf: '90063', grupo_bio: 'TACROLIMUS '},
    {kinf2: '013120030', kinf: '01312', grupo_bio: 'TAMOXIFENO 20 MG'},
    {kinf2: '012400030', kinf: '01240', grupo_bio: 'TAMSULOSINA 0.4 MG'},
    {kinf2: '013960010', kinf: '01396', grupo_bio: 'TAPENTADOL 100 MG'},
    {kinf2: '013950010', kinf: '01395', grupo_bio: 'TAPENTADOL 50 MG'},
    {kinf2: '013940010', kinf: '01394', grupo_bio: 'TAPENTADOL 50 MG'},
    {kinf2: '008320001', kinf: '00832', grupo_bio: 'TEOFILINA '},
    {kinf2: '008710028', kinf: '00871', grupo_bio: 'TERBINAFINA 250 MG'},
    {kinf2: '010330008', kinf: '01033', grupo_bio: 'TETRACICLINA 500 MG'},
    {kinf2: '017310030', kinf: '01731', grupo_bio: 'TICAGRELOR 90 MG'},
    {kinf2: '010340001', kinf: '01034', grupo_bio: 'TIMOLOL 0.5%'},
    {kinf2: '008330001', kinf: '00833', grupo_bio: 'TIMOLOL '},
    {kinf2: '008340001', kinf: '00834', grupo_bio: 'TOBRAMICINA '},
    {kinf2: '008350001', kinf: '00835', grupo_bio: 'TOBRAMICINA '},
    {kinf2: '007760060', kinf: '00776', grupo_bio: 'TOPIRAMATO 100 MG'},
    {kinf2: '002610028', kinf: '00261', grupo_bio: 'TOPIRAMATO 100 MG'},
    {kinf2: '014690028', kinf: '01469', grupo_bio: 'TOPIRAMATO 100 MG'},
    {kinf2: '002640030', kinf: '00264', grupo_bio: 'TOPIRAMATO 100 MG'},
    {kinf2: '004150028', kinf: '00415', grupo_bio: 'TOPIRAMATO 100 MG'},
    {kinf2: '007780060', kinf: '00778', grupo_bio: 'TOPIRAMATO 25 MG'},
    {kinf2: '002620028', kinf: '00262', grupo_bio: 'TOPIRAMATO 25 MG'},
    {kinf2: '014670028', kinf: '01467', grupo_bio: 'TOPIRAMATO 25 MG'},
    {kinf2: '002650030', kinf: '00265', grupo_bio: 'TOPIRAMATO 25 MG'},
    {kinf2: '004130028', kinf: '00413', grupo_bio: 'TOPIRAMATO 25 MG'},
    {kinf2: '007770060', kinf: '00777', grupo_bio: 'TOPIRAMATO 50 MG'},
    {kinf2: '002630028', kinf: '00263', grupo_bio: 'TOPIRAMATO 50 MG'},
    {kinf2: '014680028', kinf: '01468', grupo_bio: 'TOPIRAMATO 50 MG'},
    {kinf2: '002660030', kinf: '00266', grupo_bio: 'TOPIRAMATO 50 MG'},
    {kinf2: '004140028', kinf: '00414', grupo_bio: 'TOPIRAMATO 50 MG'},
    {kinf2: '013990010', kinf: '01399', grupo_bio: 'TRAMADOL 100 MG'},
    {kinf2: '008730001', kinf: '00873', grupo_bio: 'TRAMADOL 100MG/ML'},
    {kinf2: '014020001', kinf: '01402', grupo_bio: 'TRAMADOL 100MG/ML'},
    {kinf2: '014000010', kinf: '01400', grupo_bio: 'TRAMADOL 150 MG'},
    {kinf2: '014010010', kinf: '01401', grupo_bio: 'TRAMADOL 200 MG'},
    {kinf2: '013980020', kinf: '01398', grupo_bio: 'TRAMADOL 50 MG'},
    {kinf2: '016240030', kinf: '01624', grupo_bio: 'TRAMADOL+PARACETAMOL 325/37,5'},
    {kinf2: '002750020', kinf: '00275', grupo_bio: 'TRAZODONA 100 MG'},
    {kinf2: '003850030', kinf: '00385', grupo_bio: 'TRAZODONA 100 MG'},
    {kinf2: '002770020', kinf: '00277', grupo_bio: 'TRAZODONA 150 MG LIB. PROL.'},
    {kinf2: '002760020', kinf: '00276', grupo_bio: 'TRAZODONA 25 MG'},
    {kinf2: '015060020', kinf: '01506', grupo_bio: 'TRIMEBUTINO 100 MG'},
    {kinf2: '015050030', kinf: '01505', grupo_bio: 'TRIMEBUTINO 200 MG'},
    {kinf2: '000920030', kinf: '00092', grupo_bio: 'VENLAFAXINA 150 MG LIB. PROL.'},
    {kinf2: '007430030', kinf: '00743', grupo_bio: 'VENLAFAXINA 150 MG LIB. PROL.'},
    {kinf2: '001050030', kinf: '00105', grupo_bio: 'VENLAFAXINA 150 MG LIB. PROL.'},
    {kinf2: '003890030', kinf: '00389', grupo_bio: 'VENLAFAXINA 150 MG LIB. PROL.'},
    {kinf2: '002450030', kinf: '00245', grupo_bio: 'VENLAFAXINA 150 MG LIB. PROL.'},
    {kinf2: '009360030', kinf: '00936', grupo_bio: 'VENLAFAXINA 150 MG LIB. PROL.'},
    {kinf2: '015540030', kinf: '01554', grupo_bio: 'VENLAFAXINA 225 MG LIB. OSM.'},
    {kinf2: '000930030', kinf: '00093', grupo_bio: 'VENLAFAXINA 37,5 MG LIB. PROL.'},
    {kinf2: '007410030', kinf: '00741', grupo_bio: 'VENLAFAXINA 37,5 MG LIB. PROL.'},
    {kinf2: '001060030', kinf: '00106', grupo_bio: 'VENLAFAXINA 37,5 MG LIB. PROL.'},
    {kinf2: '002460030', kinf: '00246', grupo_bio: 'VENLAFAXINA 37,5 MG LIB. PROL.'},
    {kinf2: '017490030', kinf: '01749', grupo_bio: 'VENLAFAXINA 37,5 MG LIB. PROL.'},
    {kinf2: '000940030', kinf: '00094', grupo_bio: 'VENLAFAXINA 75 MG LIB. PROL.'},
    {kinf2: '007420030', kinf: '00742', grupo_bio: 'VENLAFAXINA 75 MG LIB. PROL.'},
    {kinf2: '001070030', kinf: '00107', grupo_bio: 'VENLAFAXINA 75 MG LIB. PROL.'},
    {kinf2: '002470030', kinf: '00247', grupo_bio: 'VENLAFAXINA 75 MG LIB. PROL.'},
    {kinf2: '009350030', kinf: '00935', grupo_bio: 'VENLAFAXINA 75 MG LIB. PROL.'},
    {kinf2: '015600030', kinf: '01560', grupo_bio: 'VENLAFAXINA 75 MG LIB. OSM.'},
    {kinf2: '014380020', kinf: '01438', grupo_bio: 'VERAPAMILO 120 MG'},
    {kinf2: '014370020', kinf: '01437', grupo_bio: 'VERAPAMILO 80 MG'},
    {kinf2: '016010056', kinf: '01601', grupo_bio: 'VILDAGLIPTINA+METFORMINA 50/1000 MG'},
    {kinf2: '015990056', kinf: '01599', grupo_bio: 'VILDAGLIPTINA+METFORMINA 50/500 MG'},
    {kinf2: '016000056', kinf: '01600', grupo_bio: 'VILDAGLIPTINA+METFORMINA 50/850 MG'},
    {kinf2: '002390030', kinf: '00239', grupo_bio: 'ZOLPIDEM 10 MG'},
    {kinf2: '014610030', kinf: '01461', grupo_bio: 'ZOLPIDEM 10 MG'},
    {kinf2: '007470030', kinf: '00747', grupo_bio: 'ZOLPIDEM 10 MG'},
    {kinf2: '003830030', kinf: '00383', grupo_bio: 'ZOLPIDEM 10 MG'},
    {kinf2: '017080001', kinf: '01708', grupo_bio: 'ZOLPIDEM 10 MG/ML'},
    {kinf2: '003040030', kinf: '00304', grupo_bio: 'ZOPICLONA 7,5 MG'},
    {kinf2: '007940030', kinf: '00794', grupo_bio: 'ZOPICLONA 7,5 MG'},
    

]