import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
const FooterOne = ({
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu
}) => {
  const { user } = useSelector((state) => state.user);
  return (
    <div>
      <footer className={clsx("footer-area fondo-gradiente", extraFooterClass, spaceLeftClass, spaceRightClass)}>
        <div className={`${containerClass ? containerClass : "container"}`}>
          <div className="row">
            {/* <div
            className={`${
              sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
            }`}
          > */}
            <div className="col-3">
              {/* footer copyright */}
              <FooterCopyright
                footerLogo="https://www.ligaepilepsia.cl/wp-content/uploads/2021/11/logo-blanco.svg"
                spaceBottomClass="mb-30"
              />
            </div>
            <div
              className={`${sideMenu ? "col-xl-3 col-sm-3" : "col-lg-3 col-sm-3"
                }`}
            >
              <div
                className={`${sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-30"
                  }`}
              >
                <div className="footer-title">
                  <h3>Liga Chilena contra la Epilepsia</h3>
                </div>
                <div className="footer-list">
                  <ul>
                    <li>
                      <Link
                        target={"_blank"}
                        to={"https://www.ligaepilepsia.cl/farmacias/"}
                      >Ubicación y Horarios
                      </Link>
                    </li>
                    <li>
                      <Link
                        target={"_blank"}
                        to={"https://www.ligaepilepsia.cl/mision/"}
                      >Misión
                      </Link>
                    </li>
                    <li>
                      <Link
                        target={"_blank"}

                        to={"https://www.ligaepilepsia.cl/nuestra-historia/"}
                      >Nuestra Historia
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/importaciones"}
                      >Importaciones
                      </Link>
                    </li>

                  </ul>
                </div>
                <div className="footer-title mt-3">
                  <h3>Direcciones Farmacia Online</h3>
                </div>
                <div className="footer-list">
                  <ul>
                    <li>
                      <Link
                        target={"_blank"}
                        to={"https://www.ligaepilepsia.cl/farmacias/"}
                      >Erasmo Escala 2220, Santiago.
                      </Link>
                    </li>
                    <li>
                      <Link
                        target={"_blank"}
                        to={"https://www.ligaepilepsia.cl/farmacias/"}
                      >Angol 341, Concepción.
                      </Link>
                    </li>

                  </ul>
                </div>


              </div>
            </div>
            <div
              className={`${sideMenu ? "col-xl-3 col-sm-3" : "col-lg-3 col-sm-3"
                }`}
            >
              <div className="footer-widget mb-30 ml-30">
                <div className="footer-list pb-4">
                  <ul>
                    <li>
                      <Link to={"/terminos-y-condiciones"}>
                        Términos y Condiciones
                      </Link>
                    </li>
                    <li>
                      <Link to={"/sugerencias-y-reclamos"}>
                        Sugerencias y reclamos
                      </Link>
                    </li>
                  </ul>

                </div>
                {/* <div className="footer-title">
                  <h3>Enlaces Regulatorios</h3>
                </div> */}
                <div className="footer-list">
                  <Accordion bsPrefix="">
                    <Accordion.Item eventKey="0" bsPrefix="nada">
                      <Accordion.Header className="pb-3" bsPrefix="nada">Enlaces Regulatorios</Accordion.Header>
                      <Accordion.Body bsPrefix="nada" className="pb-2">
                        <ul>

                          <li>
                            <Link to={"https://www.leychile.cl/Navegar?idNorma=1026879"}>
                              DN° 3
                            </Link>
                          </li>
                          <li>
                            <Link to={"https://www.leychile.cl/Navegar?idNorma=13613"}>
                              DS 466
                            </Link>
                          </li>
                          <li>
                            <Link to={"http://cituc.uc.cl/"}>
                              Información toxicológica
                            </Link>
                          </li>
                          <li>
                            <Link to={"https://www.minsal.cl/medicamentos_uso_racional/"}>
                              Uso racional de medicamentos
                            </Link>
                          </li>

                          <li>
                            <Link to={"/cartas-de-desabastecimiento"}>
                              Cartas de desabastecimiento
                            </Link>
                          </li>
                          <li>
                            <a href={"/assets/archivos/Res.-Comercio-Electrónico-Santiago.pdf"}>
                              Resolución comercio electrónico Santiago
                            </a>
                          </li>
                          <li>
                            <a href={"/assets/archivos/Res.-Comercio-Electrónico-Concepción.pdf"}>
                              Resolución comercio electrónico Concepción
                            </a>
                          </li>
                          <li>
                            <a href={"/assets/archivos/Resol.-Expendio-Productos-Refrigerados.pdf"}>
                              Resolución expendio de productos refrigerados
                            </a>
                          </li>


                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </div>
              </div>
            </div>

            <div
              className={`${sideMenu ? "col-xl-3 col-sm-3" : "col-lg-3 col-sm-3"
                }`}
            >
              <div
                className={`${sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-30"
                  }`}
              >
                <div className="footer-title">
                  <h3>Tienda</h3>
                </div>
                <div className="footer-list">

                  {
                    !user || user.estado === "invitado" || user === null || user === undefined ?


                      <ul>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/login"}>Login</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/registro"}>Registro</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/recetas"}>Recetas</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/carrito"}>Carro</Link>
                        </li>
                      </ul>

                      :

                      <ul>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/mi-cuenta"}>Mi Cuenta</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/recetas"}>Recetas</Link>
                        </li>
                        <li>
                          <Link to={process.env.PUBLIC_URL + "/carrito"}>Carro</Link>
                        </li>
                      </ul>

                  }
                  {/* <ul>
                  <li>
                    <a
                      href="//www.facebook.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="//www.twitter.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="//www.instagram.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                  <li>
                    <a
                      href="//www.youtube.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Youtube
                    </a>
                  </li>
                </ul> */}
                </div>
                <div className="footer-title mt-4">
                  <div className="footer-list">
                    <ul>
                      <li>
                        <strong>
                          <Link
                            target={"_blank"}
                            to={"tel:6003001515"}
                          >Contact Center: 600 300 1515
                          </Link>
                        </strong>

                      </li>
                      <li>
                        <strong>
                          <Link
                            target={"_blank"}
                            to={"mailto:ventaonline"}
                          >ventaonline@liche.cl
                          </Link>
                        </strong>

                      </li>
                    </ul>

                  </div>

                </div>
                <div className="footer-title social">
                  <h3>Siguenos en redes sociales</h3>
                  <a target="_blank" rel="noreferrer" href="https://www.facebook.com/LigaChilenacontralaEpilepsiaOficial" className="icono"> <FontAwesomeIcon icon={faFacebook} className="icono" /></a>
                  <a target="_blank" rel="noreferrer" href="https://twitter.com/LigaEpilepsia" className="icono"><FontAwesomeIcon icon={faXTwitter} className="icono" /></a>
                  <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ligaepilepsia/" className="icono"><FontAwesomeIcon icon={faInstagram} className="icono" /></a>
                  <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UC4F78IJRTuh3kcRtgzjGUDg" className="icono"><FontAwesomeIcon icon={faYoutube} className="icono" /></a>
                  <a target="_blank" rel="noreferrer" href="https://www.tiktok.com/@ligaepilepsia" className="icono"><FontAwesomeIcon icon={faTiktok} className="icono" /></a>

                </div>
              </div>
            </div>
            <div
              className={`${sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
                }`}
            >
              {/* footer newsletter */}
              {/* <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            /> */}
            </div>
          </div>

        </div>
      </footer>


    </div>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default FooterOne;
