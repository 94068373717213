import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const pacienteSlice = createSlice({
    name: "paciente",
    initialState: {
        arrPacientes: [],
        pacienteSeleccionado: {}
    },
    reducers: {
        addPaciente(state, action) {
            //console.log("se recibe")
            //console.log(action.payload)
            state.arrPacientes.push(action.payload)
            //console.log("arrpacientes Actual");
            //console.log(state.arrPacientes)
            cogoToast.success("Paciente añadido", { position: "bottom-left" });
        },
        deletePaciente(state, action) {
            //console.log("deletepaciente")
            //console.log(action.payload);

   

                state.arrPacientes = state.arrPacientes.filter((paciente) => paciente.rut !== action.payload.rut);

                //cogoToast.warn("Paciente eliminado", { position: "bottom-left" });
                if (state.pacienteSeleccionado.rut === action.payload.rut) {
                    //console.log("se intenta eliminar el mismo paciente seleccionado");
                    //se setea paciente seleccionado
                    state.pacienteSeleccionado = state.arrPacientes[0];
                    //cogoToast.success("Paciente " + state.arrPacientes[0].nombre + " " + state.arrPacientes[0].apellidos + " seleccionado", { position: "bottom-left" })
                }
                if(state.arrPacientes.length === 1){
                    state.pacienteSeleccionado = state.arrPacientes[0];
                }
        },
        setPacienteSeleccionado(state, action) {
            //console.log("se recibe set paciente seleccionado")
            //console.log(action.payload)
            //se setea paciente seleccionado
            state.pacienteSeleccionado = action.payload;
            cogoToast.success("Paciente " + action.payload.nombre + " " + action.payload.apellidos + " seleccionado", { position: "bottom-left" })
        },
        updatePacientes(state, action) {
            //se recibe el arreglo completo y se actualiza el arreglo de pacientes
            //console.log("updatepaciente")
            //console.log(action.payload);
            state.arrPacientes = action.payload;
            //console.log(state.arrPacientes);
            if(state.arrPacientes.length === 1){
                state.pacienteSeleccionado = state.arrPacientes[0];
            }
            //cogoToast.success("Pacientes actualizados", { position: "bottom-left" });
        }
    


    },
});

export const { addPaciente, deletePaciente, setPacienteSeleccionado, updatePacientes } = pacienteSlice.actions;
export default pacienteSlice.reducer;
