const { createSlice } = require('@reduxjs/toolkit');

const productSlice = createSlice({
    name: 'product',
    initialState: {
        products: [],
        productsFull: []
    },
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },
        setProductsFull(state, action) {
            state.productsFull = action.payload;
        },
    },
});

export const { setProducts, setProductsFull } = productSlice.actions;

export default productSlice.reducer;
