import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const compareSlice = createSlice({
    name: "compare",
    initialState: {
        compareItems: []
    },
    reducers: {
        addToCompare(state, action) {

            let arrLengthBefore = state.compareItems.length
            //console.log("arrBefore" + arrLengthBefore);
            state.compareItems.push(action.payload);

            state.compareItems = state.compareItems.filter((item, index, array) => {
                return array.findIndex(t => t.kinf2 === item.kinf2) === index;
            })

            let arrLengthAfter = state.compareItems.length
            //console.log("arrAfter" + arrLengthAfter)
            if (arrLengthAfter > arrLengthBefore) {
                cogoToast.success("Añadido para comparar", { position: "bottom-left" });
            }
            else {
                cogoToast.warn("En comparación", { position: "bottom-left" });
            }

        },
        deleteFromCompare(state, action) {
            state.compareItems = state.compareItems.filter(item => item.kinf2 !== action.payload);
            cogoToast.error("Eliminado de la comparación", { position: "bottom-left" });
        },
        replaceCompareList(state, action) {
            state.compareItems = action.payload
        }
    },
});

export const { addToCompare, deleteFromCompare, replaceCompareList } = compareSlice.actions;
export default compareSlice.reducer;
