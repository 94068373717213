import { Modal, Spinner } from "react-bootstrap";

export const Loading = () => {
    return (
    <Modal show={true} backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        keyboard={false}
        size={"sm"}
        className="text-center"
       contentClassName="modal-loading"
    >
        {/* <Modal.Header closeButton>
            <Modal.Title>Cargando...</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="modal-dialog-centered text-center align-self-center"
        
        >
            <div style={{backgroundColor: "#FFF", padding: "15px", borderRadius: "20px"}}>
                <center>
              <Spinner  role="status" variant="secondary"></Spinner>
            <div className="w-3"></div>
            <span>Cargando información...</span>  
            </center>
            </div>
            
            
        </Modal.Body>
    </Modal>)
}