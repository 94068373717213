import React, { useEffect } from "react";
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from "./App";
import { store } from "./store/store";
import PersistProvider from "./store/providers/persist-provider";
import { setProducts, setProductsFull } from "./store/slices/product-slice"
//import products from "./data/products.json";
//import fotos from "./data/fotos.json"
import 'animate.css';
import 'swiper/swiper-bundle.min.css';
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./assets/scss/style.scss";
//import "./i18n";
import { useSelector } from "react-redux";
import { getDoc, doc } from "firebase/firestore"

import db from './firebase/firebase-config'

import { getMedicamentos } from "./firebase/firebase-methods";

import ReactGA from 'react-ga';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
//import { getDownloadURL, getStorage, ref } from "firebase/storage";





const container = document.getElementById('root');
const root = createRoot(container);


try {
  // Inicializa Google Analytics con tu ID de seguimiento
  ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
} catch (error) {
  console.log(error)
}

try {
  // Registra una página vista para la página inicial
  ReactGA.pageview(window.location.pathname + window.location.search);
} catch (error) {
  console.log(error)
}


//LogRocket.init('n7gkk6/venta-online-20');
// after calling LogRocket.init()
//setupLogRocketReact(LogRocket);
root.render(
  <Provider store={store}>
    <PersistProvider>
      <App />
    </PersistProvider>
  </Provider>
);



