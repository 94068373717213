
import {auth } from "./firebase-config";
import {setPersistence,
    GoogleAuthProvider, signInWithPopup, onAuthStateChanged, getRedirectResult,
    updatePassword, sendPasswordResetEmail, EmailAuthProvider, linkWithCredential,
    signInWithRedirect, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithEmailAndPassword, browserSessionPersistence
} from "firebase/auth";
import cogoToast from "cogo-toast";
import { getUsuario, registerUsuario } from "./firebase-methods";
import { store } from "../store/store"
import { setUsuario, limpiaUsuario } from "../store/slices/user-slice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateDirecciones, clearDirecciones } from "../store/slices/direcciones-slice";


//funcion que devuelve los metodos de inicio de sesion del usuario actual
export const getSignInMethods = async () => {
    try {
        const user = auth.currentUser;
        const signInMethods = await fetchSignInMethodsForEmail(auth, user.email)
        return signInMethods;
    } catch (error) {
        //console.log(error)
        return error;
    }
}



//funcion que envia correo de recuperacion de contraseña
export const sendPasswordReset = async (email) => {
    //console.log("enviando correo de recuperacion de contraseña")
    let result = { success: false, message: "" }
    try {
        await sendPasswordResetEmail(auth, email)
            .then(() => {
                result = { success: true, message: "Se ha enviado un correo de recuperación de contraseña a " + email + ". Revise su bandeja de entrada." }
            })
    } catch (error) {
        //console.log(error)
        result = { success: false, message: "Ha ocurrido un error al enviar el correo de recuperación de contraseña a " + email + ". Intente nuevamente." }

    }

    return result;
}

//funcion que cambia la contraseña
export const changePassword = async (email, newPassword, oldPassword) => {
    //console.log("cambiando contraseña")
    let result = { success: false, message: "" }
    try {
        if (oldPassword === "") {
            //console.log("sin metodo password")



            //añade el metodo password al usuario
            const credential = EmailAuthProvider.credential(email, newPassword);
            await linkWithCredential(auth.currentUser, credential);

            result = { success: true, message: "Se ha cambiado la contraseña de " + email + " correctamente." }
        } else {
            //console.log("con metodo password")
            const userCredential = await signInWithEmailAndPassword(auth, email, oldPassword)

            await updatePassword(userCredential.user, newPassword)
            result = { success: true, message: "Se ha cambiado la contraseña de " + email + " correctamente." }
        }

    } catch (error) {
        //console.log(error)
        result = {
            success: false,
            message: "Ha ocurrido un error al cambiar la contraseña de " + email + ". Intente nuevamente.",
            error: error
        }
    }

    return result;
}

export const getRedirect = async () => {

try {
   
        
        const result = await getRedirectResult(auth)
        console.log("get redirect")
        console.log(result)

        //CAMBIA LA FORMA EN QUE HACES EL AWAIT ACA Y EN EL LOGIN
        if (result.user) {
            console.log("result obtenido")
            let usuario = await getUsuario(result.user.uid)
            if(!usuario || usuario===undefined){
                console.log("usuario undefined 1")
                usuario = {
                    error: "Usuario no registrado"
                }
                //controlar error aca
            }
              
            
            console.log("usuario "+ usuario)
            return usuario;
        }


} catch (error) {
    console.log("error al obtener redirect")
    console.log(error)
}

//     try {
//   let auth = getAuth(app)
//         await getRedirectResult(auth).then(async (result) => {
//             //console.log("get redirect")
//             //console.log(result)
//             if (result.user) {
//                 const usuario = await getUsuario(result.user.uid)
//                 return usuario;

//             }
//         })

//     } catch (error) {
//         //console.log(error)
//         return error;
//     }
    // try {
        
    //     const result = await getRedirectResult(auth)
    //     //console.log("get redirect")
    //     //console.log(result)

    //     //CAMBIA LA FORMA EN QUE HACES EL AWAIT ACA Y EN EL LOGIN
    //     if (result.user) {
    //         //console.log("result obtenido")
    //         const usuario = await getUsuario(result.user.uid)
    //         //console.log("usuario "+ usuario)
    //         return usuario;
    //     }
    // } catch (error) {
    //     //console.log("error redirect")
    //     //console.log(error)
    //     return error;
    // }
}

export const getRegisterRedirect = async () => {
    let response = { success: false, user: {}, error: "" }
    try {
        const result = await getRedirectResult(auth)
        //console.log("get redirect")
        //console.log(result)
        if (result) {
            response = { success: true, user: result.user, error: "" }
        }

    } catch (error) {
        response = { success: false, user: {}, error: error }
        //console.log(error)

    }
    return response;
}


export const existeMail = async (email) => {
    try {
        const signInMethods = await fetchSignInMethodsForEmail(auth, email)
        if (signInMethods.length === 0) {
            return { exist: false }
        } else {
            return { exist: true, methods: signInMethods }
        }
    } catch (error) {
        return { error: error }
    }
}

export const createUserEmailAndPassword = async (email, password, objUsuario) => {
    console.log("crear usuario con email " + email);
    //revisamos si email existe
    const existe = await existeMail(email)
    if (existe.error) return {
        code: "server-error",
        error: "Ha ocurrido un error de servidor, por favor intente nuevamente mas tarde"
    }

    if (existe.exist) {
        for (let index = 0; index < existe.methods.length; index++) {
            if (existe.methods[index] === 'password') {
                existe.methods[index] = "Usuario y contraseña"
            }
            if (existe.methods[index] === 'facebook.com') {
                existe.methods[index] = "Login con Facebook"
            }
            if (existe.methods[index] === 'google.com') {
                existe.methods[index] = "Login con Google"
            }

        }
        let metodos = existe.methods.join(", ")
        return {
            error: "Correo electrónico " + email + " ya registrado con los siguientes métodos: " + metodos + ". Intente iniciar sesión con el método indicado, si presenta problemas, compre como invitado y comuníquese con nosotros.",
            code: "email-exists",
        }
    }
    else {
        //correo no registrado, se procede a registrar en firebase
        console.log("no registrado, pasa");
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password)
            //console.log("usuario creado con id " + userCredential.user.uid)

            objUsuario.uid = userCredential.user.uid

            const user = await registerUsuario(objUsuario)

            if (user.ingresado) {
                return { ingresado: true, message: "Usuario registrado correctamente", uid: userCredential.user.uid }
            }

        } catch (error) {
            return { ingresado: false, error: error }

        }
    }

}

export const loginEmailAndPassword = async (email, password) => {
    //console.log("login email and password");
    let response = {}
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password)
        const userData = await getUsuario(userCredential.user.uid)
        store.dispatch(setUsuario(userData))
        if (userData.direcciones) {
            store.dispatch(updateDirecciones(userData.direcciones))
        } else {
            store.dispatch(clearDirecciones())
        }
        //console.log("usuario logueado con id " + userCredential.user.uid)
        response = { logged: true, message: "Sesión iniciada correctamente" }
    } catch (error) {
        //console.log("error login");
        //console.log(error.code);
        if (error.code === 'auth/wrong-password') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/too-many-requests') {
            response = { logged: false, message: "Demasiados intentos de inicio de sesión, intente nuevamente en un momento" }
        }
        if (error.code === 'auth/user-not-found') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/invalid-email') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/user-disabled') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/network-request-failed') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/operation-not-allowed') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/internal-error') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/invalid-credential') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/invalid-verification-code') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/invalid-verification-id') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }
        if (error.code === 'auth/missing-verification-code') {
            response = { logged: false, message: "No se ha podido iniciar sesión, Email y contraseña incorrecto" }
        }

    }
    return response;
}


//funcion para cerrar sesion
export const cerrarSesion = async () => {

    await auth.signOut().then(() => {
        //console.log("sesion cerrada");
        cogoToast.success("Sesión cerrada correctamente")
    })
        .catch((error) => {
            //console.log(error);
        });
}


//funcion para iniciar sesion con google redirect (popup)
export const loginWithGoogleRedirect = () => {
    const state = store.getState();
    //console.log(state);

    const provider = new GoogleAuthProvider();
    try {
        signInWithRedirect(auth, provider)

        //         //console.log(result);
        //         const user = await getUsuario(result.user.uid)
        //         //console.log(user);
        //         store.dispatch(setUsuario(user))
        // return;

    } catch (error) {
        //console.log(error);
    }
}

export const loginWithGooglePopup = async () => {
    const provider = new GoogleAuthProvider();
    try {
        signInWithPopup(auth, provider)
    } catch (error) {
       console.log(error) 
    }
}


export const registerWithGoogleRedirect = async () => {
// console.log("recibo usuario")
// console.log(objUsuario)

    const provider = new GoogleAuthProvider();
    try {

     
            
        // const result = await signInWithRedirect(auth, provider)
        // console.log("result register with google redirect");
        // console.log(result)

        // if(result.success){
        //     //gmail registrado
        //     let objUser = {...objUsuario, uid: result.user.uid}
        //     const user = await registerUsuario(objUser)
        //     console.log(user)
        //     // if (user.ingresado) {
        //     //     return { ingresado: true, message: "Usuario registrado correctamente", uid: userCredential.user.uid }
        //     // }
        // }
        // obj.uid = result.user.uid
        // const user = await registerUsuario(obj)
        // console.log("user register with google redirect");
        // console.log(user)
        // const userData = await getUsuario(user.uid)
        // console.log("user data register with google redirect");
        // console.log(userData)
        // store.dispatch(setUsuario(userData))

        await signInWithRedirect(auth, provider)
        //store.dispatch(setUsuario(user))
        return;

       

    } catch (error) {
        //console.log(error);
    }
}


//funcion para iniciar sesion con google popup
export const loginWithGooglePopupOld = async () => {
    const state = store.getState();
    //console.log(state);

    const provider = new GoogleAuthProvider();
    try {
        const result = await signInWithPopup(auth, provider)
        //console.log(result.user.uid);
        const user = await getUsuario(result.user.uid)
        return user;
        return;
    } catch (error) {
        //console.log(error);
    }

}

